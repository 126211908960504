.vessel-dashboard {
  max-width: var(--max-table-width);
}

.vessel-dashboard .actions.right {
  display: flex;
  justify-content: flex-end;
}
.vessel-dashboard .actions.right > div {
  align-self: center;
  padding-left: 4px;
}
.vessel-dashboard .actions.right .link {
  text-decoration: underline;
  cursor: pointer;
  padding: 8px;
}
.vessel-settings-fat {
  display: block;
}
.vessel-settings-thin {
  display: none;
}
.platform-hybrid .vessel-settings-fat {
  display: none;
}
.platform-hybrid .vessel-settings-thin {
  display: block;
}

.vessel-dashboard-top {
  margin-bottom: 20px;
  margin-top: 10px;
  min-height: 200px;
}

/* Tasks Completed */
.vessel-dashboard .tasks-completed {
  background-color: var(--yellow-orange);
  padding: 15px 25px;
  width: 160px;
  color: var(--bg-header);
  font-size: 14px;
  margin-right: 25px;
}

.vessel-dashboard .tasks-completed div:first-child {
  font-weight: bold;
  color: var(--ion-color-secondary);
  font-size: 24px;
  padding-bottom: 10px;
}

/* Engine Hours */
.vessel-dashboard .engine-hours {
  background-color: var(--ion-color-light);
  color: var(--ion-color-dark);
  flex: 1;
  /* margin-left: 25px; */
  padding: 15px 25px;
  font-weight: 500;
  position: relative;
}

.vessel-dashboard .engine-hours .edit-engines-btn {
  position: absolute;
  right: 23px;
  bottom: 20px;
}

.vessel-dashboard .engine-hours h2 {
  margin: 0;
}

.vessel-dashboard .engine-hours .last-updated {
  font-style: italic;
  font-size: 14px;
  padding-top: 7px;
}

.vessel-dashboard .engine-hours .last-updated span {
  text-transform: uppercase;
  white-space: nowrap;
  color: var(--ion-color-secondary);
}

.vessel-dashboard .engine-hours .engine-items {
  flex-wrap: wrap;
  margin-top: 15px;
}

.vessel-dashboard .engine-hours .engine-items > div {
  /* width: 150px; */
  flex: 1 0 150px;
  margin-bottom: 15px;
  padding-right: 15px;
}

.vessel-dashboard .engine-hours .engine-items > div > div:nth-of-type(1) {
  font-size: 24px;
  font-weight: bold;
  color: var(--ion-color-secondary);
}

.vessel-dashboard .engine-hours .engine-items > div > div:nth-of-type(2) {
  text-transform: uppercase;
  font-size: 11px;
  margin-top: -2px;
}


.dashboard-table {
  width: 100%;
  margin-top: 20px;
  margin-bottom: 12px;
  cursor: pointer;
  padding: 12px 0px 0px 0px;
  border-radius: 5px;
  transition: left var(--responsive-transition-easing) var(--responsive-transition-time);
}
.dashboard-table h2 {
  margin: 0px 0px 0px 0px;
}
.vessel-dashboard .title {
  margin: 26px 0px 0px 0px;
}

.dashboard-table .section-head {
  padding: 4px 0px 10px 2px;
}
.vessel-dashboard h2 {
  font-size: 26px;
}
.dashboard-table .section-head h2 {
  font-size: 22px;
  margin-bottom: -4px;
}
.dashboard-table .section-head .right > div {
  display: inline-block;
}
.dashboard-table .section-head .right > div:first-of-type {
  padding: 3px 0px;
  font-weight: 600;
  position: relative;
  top: -5px;
}
.dashboard-table .section-head .right > div:nth-of-type(2) {
  padding: 0px 2px 0px 4px;
  position: relative;
  top: 0px;
}

.dashboard-table .row {
  margin: 8px 0px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  height: 44px;
  font-size: 13px;
  cursor: pointer;
  background-color: var(--bg-dashboard-row-color);
  border-radius: 5px;
  padding: 12px 16px;
  box-shadow: 0 2px 8px rgb(0 0 0 / 16%);
}
.dashboard-table .row.fault {
  background-color: var(--ion-color-danger);
}
.fault .sea-status.icon.critical { /* Critical */
  color: white;
}
.dashboard-table .row > div {
  flex: 1 1 0;
  align-self: center;
  padding-left: 10px;
  overflow-x: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.dashboard-table .row > div:first-of-type {
  padding-left: 0px;
}
.dashboard-table .row ion-icon {
  position: relative;
  left: 5px;
  top: 1px;
}
.dashboard-table .summary {
  box-shadow: none;
  background-color: transparent;
  color: white;
  font-weight: 700;
  letter-spacing: unset;
}
.dashboard-table .summary.row {
  cursor: default;
  padding: 0px 16px 0px 0px;
  height: 30px;
  position: relative;
}
.dashboard-table .summary.row > div {
  display: block !important;
  text-transform: uppercase;
  overflow-x: visible;
  text-align: right;
  font-weight: 400 !important;
  padding-top: 1px;
}
.dashboard-table .summary.row > div:nth-of-type(1) { /* Total Label */
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow-x: hidden;
  text-align: right;
  flex: 1 1 0 !important;
}
.dashboard-table .summary.row > div:nth-of-type(2) { /* Total */
  font-size: 24px;
  font-weight: 700 !important;
  padding-top: 0px;
  text-align: center;
  flex: 0 1 120px !important;
}
.dashboard-table .row .critical { /* Critical */
  flex: 0 1 40px;
  text-align: center;
}
.dashboard-table .row .status { /* Status */
  flex: 0 1 120px;
  text-align: center;
  text-transform: uppercase;
}
.dashboard-table h3 {
  margin-top: 2px;
}
@media screen and (max-width: 450px) {
  .dashboard-table {
    margin-top: 24px;
    padding: 6px 0px 4px 0px;
  }
  .dashboard-table .section-head h2 {
    font-size: 18px;
  }
  .vessel-dashboard .title {
    margin: 22px 0px 0px 0px;
  }
  .vessel-dashboard h2 {
    font-size: 22px;
  }
  .dashboard-table .summary.row > div:nth-of-type(2) {
    flex: 0 1 50px !important;
  }
}





/* Safety columns */
.dashboard-table.safety .row > div:nth-of-type(1) { /* Item/Drill/Name */
  flex: 4 1 0;
  font-weight: 600;
}
.dashboard-table.safety .row > div:nth-of-type(2) { /* Task */
  flex: 5 1 0;
}

@media screen and (min-width: 851px) and (max-width: 950px) {
  .show-menu .dashboard-table.safety .row > div:nth-of-type(2) {
    display: none;
  }
}
@media screen and (max-width: 590px) {
  .dashboard-table.safety .row > div:nth-of-type(2) {
    display: none;
  }
}


/* Maintenance columns */
.dashboard-table.maintenance .row > div:nth-of-type(1) { /* System */
  flex: 2 1 0;
  font-weight: 600;
}
.dashboard-table.maintenance .row > div:nth-of-type(2) { /* Task */
  flex: 3 1 0;
}
.dashboard-table.maintenance .row > div:nth-of-type(3) { /* Due */
  flex: 0 1 100px;
}


@media screen and (min-width: 851px) and (max-width: 1050px) {
  .show-menu .dashboard-table.maintenance.schedule .row > div:nth-of-type(2) { /* Task */
    display: none;
  }
}





/* Documents columns */
.dashboard-table.documents .row > div:nth-of-type(1) { /* Name */
  flex: 1 1 0;
  font-weight: 600;
}

/* Crew columns */
.dashboard-table.crew .row > div:nth-of-type(1) { /* Crew + Title */
  display: none;
}
.dashboard-table.crew .row > div:nth-of-type(2) { /* Crew */
  flex: 2 1 0;
  font-weight: 600;
}
.dashboard-table.crew .row > div:nth-of-type(3) { /* Title */
  flex: 3 1 0;
}
@media screen and (max-width: 575px) {
  .dashboard-table.crew .row > div:nth-of-type(1) {
    display: block;
  }
  .dashboard-table.crew .row > div:nth-of-type(1) > span {
    font-weight: 700;
  }
  .dashboard-table.crew .row > div:nth-of-type(2),
  .dashboard-table.crew .row > div:nth-of-type(3) {
    display: none;
  }
}

@media screen and (max-width: 750px) {
  .vessel-settings-fat {
    display: none;
  }
  .vessel-settings-thin {
    display: block;
  }
}

@media screen and (max-width: 550px) {
  .dashboard-table.safety .row .status,
  .dashboard-table.maintenance.schedule .row > div:nth-of-type(5),
  .dashboard-table.maintenance.jobs-list .row > div:nth-of-type(4),
  .dashboard-table.vessel-certificates .row > div:nth-of-type(2),
  .dashboard-table.vessel-documents .row > div:nth-of-type(2),
  .dashboard-table.safety-meetings .row > div:nth-of-type(2),
  .dashboard-table.crew.certificates .row > div:nth-of-type(4),
  .dashboard-table.crew.training .row > div:nth-of-type(4) {
    flex: 0 1 95px;
    padding-top: 1px;
  }
  .dashboard-table .summary.row > div:nth-of-type(2) {
    flex: 0 1 95px !important;
  }
  .dashboard-table .row .critical {
    flex: 0 1 30px;
  }
  .dashboard-table .sea-status {
    display: inline-block;
    min-width: 80px;
    letter-spacing: 0.2px;
    font-weight: 500;
    line-height: 17px;
    padding: 0px 8px;
  }

  .dashboard-table .sea-status.icon {
    min-width: 20px;
    width: 20px;
    font-size: 18px !important;
    padding: 0;
  }
  .dashboard-table .row .critical ion-icon  {
    font-size: 18px !important;
    left: 0;
    top: 4px;
    margin-bottom: 0 !important;
  }
}

@media screen and (max-width: 585px) {
  .vessel-dashboard-top {
    min-height: unset;
    flex-direction: column;
  }
  .vessel-dashboard .tasks-completed {
    width: unset;
    margin-right: 0px;
    margin-bottom: 16px;
    display: flex;
    align-items: center;
    padding: 16px 16px;
  }
  .vessel-dashboard .tasks-completed > div:nth-of-type(1) {
    /* flex: 0 0 100px; */
    padding-bottom: 0px;
  }
  .vessel-dashboard .tasks-completed > div:nth-of-type(2) {
    flex: 1 1;
    padding: 0px 8px 0px 12px !important;
  }
  .vessel-dashboard .tasks-completed > div:nth-of-type(2) > br {
    display: none;
  }
  .vessel-dashboard .tasks-completed > div:nth-of-type(3) {
    flex: 0 0 140px;
    margin: 0px !important;
  }

  .dashboard-table.maintenance.schedule .row > div:nth-of-type(3) {
    display: none;
  }

  .vessel-dashboard .engine-hours {
    padding: 16px 16px;
  }
  .vessel-dashboard .vessel-dashboard-top .last-updated {
    padding-top: 0px;
  }
  .vessel-dashboard .vessel-dashboard-top .edit-engines-btn {
    right: 15px;
    bottom: 15px;
  }
  .vessel-dashboard .engine-hours .engine-items {
    height: 90px;
    overflow: hidden;
  }
  .vessel-dashboard .engine-hours .engine-items::after {
    content: "";
    position: absolute;
    bottom: 15px;
    width: calc(100% - 50px);
    height: 40px;
    background: linear-gradient(
      to bottom,
      rgba(var(--ion-color-light-rgb), 0) 0%,
      rgba(var(--ion-color-light-rgb), 1) 100%
    );
  }
}

/* var(--ion-color-light-rgb) */
