.health-safety-meetings {
    max-width: var(--max-table-width);
}

.health-safety-meetings .sea-row > div:nth-of-type(1) { /* Date */
    flex: 0 1 85px;
}
.health-safety-meetings.hide-vessels .sea-row > div:nth-of-type(2) { /* Vessels */
    display: none;
}
.health-safety-meetings  .sea-row > div:nth-of-type(6) { /* File */
    flex: 0 1 60px;
}
.health-safety-meetings.page-head .status {
    margin: 12px 0px 12px 0px;
    width: 100px;
}

@media screen and (max-width: 900px) {
    .health-safety-meetings.page-head {
        flex-direction: column;
    }
    .health-safety-meetings.page-head .actions {
        justify-content: flex-start;
    }
    .health-safety-meetings.page-head .status {
        margin: 0px 0px 12px 2px;
    }
}

@media screen and (max-width: 800px) {
    .health-safety-meetings .sea-row > div:nth-of-type(5) { /* Jobs Created */ 
        display: none;
    }
}
@media screen and (max-width: 910px) {
    .health-safety-meetings.show-vessels .sea-row > div:nth-of-type(5) { /* Jobs Created */ 
        display: none;
    }
}

@media screen and (max-width: 1060px) {
    .show-menu.desktop-view .health-safety-meetings .sea-row > div:nth-of-type(5) { /* Jobs Created */ 
        display: none;
    }
}
@media screen and (max-width: 1170px) {
    .show-menu.desktop-view .health-safety-meetings.show-vessels .sea-row > div:nth-of-type(5) { /* Jobs Created */ 
        display: none;
    }
}

@media screen and (max-width: 550px) {
    .health-safety-meetings .sea-row.sea-card > div::before {
        width: 69px;
    }
    .health-safety-meetings .sea-row > div:nth-of-type(1)::before {
        content: 'Date';
    }
    .health-safety-meetings .sea-row > div:nth-of-type(2)::before {
        content: 'Vessels';
    }
    .health-safety-meetings .sea-row > div:nth-of-type(3)::before {
        content: 'Notes';
    }
    .health-safety-meetings .sea-row > div:nth-of-type(4)::before {
        content: 'Present';
    }
    .health-safety-meetings .sea-row > div:nth-of-type(5) {
        display: none; /* Hide Jobs Created */
    }
    .health-safety-meetings .sea-row > div:nth-of-type(6) {
        display: none; /* Hide Images */
    }
}
