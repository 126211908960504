.app-activity .item-row {
    display: flex;
    align-items: center;
    margin-bottom: 4px;
    max-width: 800px;
}

.app-activity .item-time {
    flex: 0 1 120px;
    font-family: monospace;
    text-align: right;
    padding-right: 10px;
}

.app-activity .item-value {
    padding: 4px;
    border-radius: 4px;
    flex: 1 1;
}
