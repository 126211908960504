.debug-view .sea-tabs-group {
    margin-top: 0px;
    margin-bottom: 0px;
}

.debug-view .top-head .sea-tabs-group {
    white-space: nowrap;
}

.debug-view .debug-view-scroll {
    height: calc(100vh - var(--safe-inset-top) - 64px) !important;
}

.debug-view .card {
    padding: 8px;
    background-color: rgb(245, 245, 245);
    border-radius: 8px;
    margin-bottom: 4px;
    color: var(--text-on-white);
    display: flex;
}
