.custom-form-container {
    display: flex;
    flex-wrap: wrap;
}

.sea-modal.builder .top-bar {
    background-color: #e8e8e8;
    height: 60px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
    position: relative;
    z-index: 1;
    /* border-width: 0px 0px 2px 0px;
    border-style: solid;
    border-color: #ccc; */
}
.sea-modal.builder .top-bar .title {
    padding: 5px 8px 0px 20px;
}
.sea-modal.builder .top-bar .title h2 {
    color: var(--text-on-white);
    opacity: 0.75;
    padding-top: 3px;
}
.sea-modal.builder .sea-modal-x {
    padding: 0px 0px 0px 0px;
    width: 60px;
    height: 60px;
}
.sea-modal.builder .sea-modal-x .sea-icon {
    margin-top: 11px !important;
    margin-right: 10px;
}
.sea-modal.builder .builder-icon {
    opacity: 0.55;
    padding: 7px 16px 0px 0px;
}
.sea-modal.builder .builder-name {
    font-size: 100%;
    padding: 6px 0px 0px 0px;
    font-weight: 700;
    margin-left: -10px;
    opacity: 0.8;
    line-height: 1.1em;
}
.sea-modal.builder .builder-box {
    background-color: rgba(0, 0, 0, 0.08);
    border-radius: 10px;
    display: flex;
    padding: 2px 8px 4px 8px;
    margin: 5px 20px 0px 0px;
}

.sea-modal.builder::part(content), .sea-modal.builder .modal-shadow {
    --width: 100%;
    background-color: #e8e8e8;
    --max-width: 100%;
}
.sea-modal.builder .main-panel {
    background-color: #fff;
    width: calc(100% - 380px);
    max-width: 900px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}
.sea-modal.builder .editor-panel {
    position: fixed;
    overflow-y: auto;
    width: 380px;
    right: 0px;
    height: calc(100% - 60px);
    top: 60px;
    /*
    border-width: 0px 0px 0px 1px;
    border-style: solid;
    border-color: #ccc;
    */
}

@media only screen and (min-width: 1280px) {
    .sea-modal.builder .editor-panel {
        width: calc(100% - 900px);
    }
}

