.maintenance-schedule-history .sea-row-history > div:nth-of-type(2) { /* Engine hours */
    display: none;
}
.maintenance-schedule-history.has-engine .sea-row-history > div:nth-of-type(2) { /* Engine hours */
    display: block;
    flex: 0 1 80px;
    text-align: right;
}
.maintenance-schedule-history .sea-row-history > div:nth-of-type(3) { /* By */
    flex: 0 1 170px;
}
.maintenance-schedule-history .sea-row-history > div:nth-of-type(4), /* Spare Parts */
.maintenance-schedule-history .sea-row-history > div:nth-of-type(6) { /* Notes */
    flex: 1 1;
    text-align: left;
}
.maintenance-schedule-history .sea-row-history > div:nth-of-type(5) { /* Actual Time */
    flex: 0 1 110px;
    text-align: left;
}

.maintenance-schedule-history .sea-row-history > div:nth-of-type(4) { /* Spare Parts */
    max-width: 200px
}
.maintenance-schedule-history .sea-row-history > div:nth-of-type(7) { /* Image/Doc */
    flex: 0 1 56px;
}

.platform-mobile .columns.maintenance-schedule .sea-tabs-group {
    margin-top: 5px;
}
@media screen and (max-width: 630px) {
    .platform-mobile .columns.maintenance-schedule {
        flex-direction: column;
    }
    .platform-mobile .columns.maintenance-schedule .complete-button {
        display: none;
    }
    .platform-mobile .columns.maintenance-schedule.tab-history .complete-button {
        display: block;
        text-align: right;
    }
}


@media screen and (max-width: 550px) {
    .maintenance-schedule-history {
        --label-width: 82px;
    }
    .maintenance-schedule-history.has-engine {
        --label-width: 110px;
    }
    .maintenance-schedule-history .sea-card.sea-row-history > div::before {
        width: var(--label-width);
    }
    .maintenance-schedule-history .sea-card.sea-row-history > div::before {
        width: var(--label-width);
    }
    .maintenance-schedule-history .sea-row-history > .date, /* Date */
    .maintenance-schedule-history .sea-row-history > .engine-hours, /* Engine hours */
    .maintenance-schedule-history .sea-row-history > .by { /* By */
        padding-right: 44px;
    }
    .maintenance-schedule-history .sea-row-history > .date::before { /* Date */
        content: 'Date';
    }
    .maintenance-schedule-history.has-engine .sea-row-history > .engine-hours { /* Engine hours */
        text-align: left;
    }
    .maintenance-schedule-history .sea-row-history > .engine-hours::before { /* Engine hours */
        content: 'Engine Hours';
    }
    .maintenance-schedule-history .sea-row-history > .by::before { /* By */
        content: 'By';
    }
    .maintenance-schedule-history .sea-row-history > .parts-used, /* Spare Parts */
    .maintenance-schedule-history .sea-row-history > .notes {  /* Notes */
        padding-left: var(--label-width);
    }

    .maintenance-schedule-history .sea-row-history > .act-time::before {
       content: 'Actual Time';
    }

    .maintenance-schedule-history .sea-row-history > .parts-used::before { /* Spare Parts */
        content: 'Parts Used';
        position: absolute;
        left: 0px;
    }

    .maintenance-schedule-history .sea-row-history > .notes::before {  /* Notes */
        content: 'Notes';
        position: absolute;
        left: 0px;
    }
    .maintenance-schedule-history .sea-row-history > .image { /* Image/Doc */
        height: 0px;
        padding: 0px;
    }
    .maintenance-schedule-history .sea-row-history > .image > .sea-file-image { /* Image/Doc */
        position: absolute;
        top: 10px;
        right: 8px;
    }
}
