.custom-form-container {
  --cf-element-padding-horizontal: 6px;
}

.element {
  padding: 10px var(--cf-element-padding-horizontal) 8px var(--cf-element-padding-horizontal);
  color: var(--text-on-white);
}

.element.editing {
  cursor: pointer;
  position: relative;
}
.element.editing.view-only {
  cursor: unset;
}
.element.editing.files.view-only {
  cursor: default;
}

.element.checkbox {
  padding-bottom: 4px;
}
.element.checkbox.view-only ion-checkbox {
  pointer-events: none;
}
.element.text {
  align-self: center;
}
.dividing-line {
  background-color: var(--text-on-grey);
  height: 1px;
  width: 100%;
}

.element .overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  z-index: 10;
  transition: background-color 0.2s;
  background-color: rgba(0,0,255,0.0);
  border-radius: 3px;
}
.element .overlay.selected {
  /* background-color: rgba(0,122,255,0.15); */
  outline: 2px solid var(--ion-color-secondary);
  outline-offset: -2px;
}
/* .element .overlay.selected.special {
  background-color: rgba(122,122,122,0.15);
} */
.element .overlay.view-only {
  cursor: default;
}
.element.files.view-only .overlay.view-only {
  display: none;
}

.element h2 {
  color: var(--text-on-white);
  margin-top: 5px;
}

.element h2.cf-large {
  font-size: 30px;
}
.element h2.cf-medium {
  font-size: 24px;
}
.element h2.cf-small {
  font-size: 18px;
}
.element h2.cf-tiny {
  font-size: 14px;
}

.element .cf-large {
  font-size: 14px;
}
.element .cf-medium {
  font-size: 12px;
}
.element .cf-small {
  font-size: 11px;
}
.element .cf-tiny {
  font-size: 10px;
}

.element.view-only .sea-input.view-only-input {
  padding: 19px 17px 17px 17px;
  height: auto;
  min-height: var(--input-height);
}

.preview-only .element.view-only .sea-input {
  color: transparent !important;
}



.element .sea-label {
  overflow: visible;
  /*cursor: default;*/
}

@media screen and (max-width: 650px) {
  .custom-form-container {
    --cf-element-padding-horizontal: 4px;
  }
}
@media screen and (max-width: 600px) {
  .custom-form-container .w75,
  .custom-form-container .w66,
  .custom-form-container .w50,
  .custom-form-container .w33 {
    width: 100% !important;
  }
  .custom-form-container .w25 {
    width: 50% !important;
  }
}
@media screen and (max-width: 440px) {
  .signature-cell .sea-sig-container {
    margin-top: 10px;
  }
  .signature-cell .view-only .sea-sig-container {
    margin-top: -20px;
  }
}
@media screen and (max-width: 320px) {
  .custom-form-container .w25 {
    width: 100% !important;
  }
}

@media screen and (max-width: 745px) {
  .custom-form-container .w50.signature-cell { /* Screen too small for dual signatures */
    width: 100% !important;
  }
}
