.maintenance-schedule {
    max-width: var(--max-table-width);
}

/* Column settings */
.maintenance-schedule .sea-row > div:nth-of-type(1) { /* Equipment */
    flex: 1 1;
}

.maintenance-schedule .sea-row > div:nth-of-type(2) { /* Maintenance Task */
    flex: 1 1;
}

.maintenance-schedule .sea-row > div:nth-of-type(3) { /* Maintenance Tags */
    flex: 1 1;
}

.maintenance-schedule .sea-row > div:nth-of-type(4) { /* interval */
    flex: 0 1 100px;
}

.maintenance-schedule .sea-row > div:nth-of-type(5) { /* next due */
    flex: 0 1 90px;
}

.maintenance-schedule .sea-row .estimated-time { /* Estimated time */
    flex: 0 1 100px;
}

.maintenance-schedule .sea-row .status { /* status */
    flex: 0 1 150px;
}

.maintenance-schedule .sea-row.headings .status {
    text-align: center;
}

.maintenance-schedule .sea-row .critical { /* Critical */
    flex: 0 1 80px;
    text-align: center;
}

@media screen and (max-width: 1400px) {
    .show-menu.desktop-view .maintenance-schedule .sea-row > div:nth-of-type(3) { /* Maintenance Tags */
        display: none;
    }
}


@media screen and (max-width: 1200px) {
    .maintenance-schedule .sea-row > div:nth-of-type(3) { /* Maintenance Tags */
        display: none;
    }
}


@media screen and (max-width: 1080px) {
    .show-menu.desktop-view .maintenance-schedule .sea-row > div:nth-of-type(4) { /* Interval */
        display: none;
    }
    .show-menu.desktop-view .maintenance-schedule .sea-row .estimated-time { /* Estimated time */
        display: none;
    }
}

@media screen and (max-width: 900px) {
    .maintenance-schedule .sea-row .estimated-time { /* Estimated time */
        display: none;
    }
}

@media screen and (max-width: 910px) {
    .show-menu.desktop-view .maintenance-schedule .sea-row > div:nth-of-type(5) { /* Next Due */
        display: none;
    }
}

@media screen and (max-width: 870px) {
    .maintenance-schedule.page-head {
        flex-direction: column;
    }

    .maintenance-schedule.page-head .actions {
        justify-content: flex-start;
    }
}


@media screen and (max-width: 550px) {
    .maintenance-schedule .sea-row.sea-card > div::before {
        width: 45px;
    }

    .maintenance-schedule .sea-row > div:nth-of-type(4) {
        display: none; /* Hide Interval */
    }

    .maintenance-schedule .sea-row > div:nth-of-type(5) {
        display: none; /* Hide Next Due */
    }

    .maintenance-schedule .sea-row > div:nth-of-type(6) {
        padding: 0px;
    }
}
