.sea-header {
  background-color: var(--bg-header);
}
.sea-header .bar {
  height: calc(var(--header-bar-height) + var(--safe-inset-top));
  padding-top: var(--safe-inset-top);
  max-width: var(--max-width);
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--text-on-grey);
}
.sea-header .logo {
  width: 150px;
  margin: 2px 0px 0px 17px;
}
.sea-header .items {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.sea-header .item {
  line-height: 1.3em;
  padding: 0px 12px 0px 12px;
  height: var(--header-bar-height);
  cursor: pointer;
  display: flex;
}
.sea-header .name, .sea-header .name > a {
  font-size: 13px;
  font-weight: 600;
  text-decoration: none;
  color: var(--text-on-grey);
}
.sea-header .item > div {
  align-self: center;
}
.sea-header .item.name > div:first-of-type {
  padding: 4px 8px 0px 0px;
}
.sea-header .status {
  color: var(--ion-color-danger);
  line-height: 1.2em;
}
.hamburger {
  height: var(--header-bar-height);
  padding: 7px 24px 0px 16px;
  cursor: pointer;
  display: none;
}
.mobile-only {
  display: none;
}

@media screen and (max-width: 850px) {
  .hamburger {
    display: block;
  }
  .not-mobile {
    display: none !important;
  }
  .mobile-only {
    display: block;
  }
  .sea-header .item.name > div:first-of-type {
    padding-right: 0px;
  }
  .sea-header .logo {
    display: none !important;
    /* position: absolute;
    width: 120px;
    top: 9px;
    left: 48px;
    opacity: 1; */
  }
}

@media screen and (max-width: 500px) {
  .sea-header .logo {
    display: none !important;
  }
}
