.safety-checks .sea-row-history > div:nth-of-type(1) { /* img */
    flex: 0 1 56px;
}
.safety-checks .sea-row-history > div:nth-of-type(2) { /* Name */
    flex: 1.5 1 0;
}
.safety-checks .sea-row-history > div:nth-of-type(3) { /* Times Completed */
    flex: 0 1 120px;
}
.safety-checks .sea-row-history > div:nth-of-type(4) { /* Interval */
    flex: 0 1 120px;
}
.safety-checks .sea-row-history > div:nth-of-type(5) { /* Vessel */
    flex: 1 1 0;
}
.safety-checks.hide-vessel .sea-row-history > div:nth-of-type(5) { /* Vessel */
    display: none;
}
.safety-checks .sea-row-history > div:nth-of-type(6) { /* Critical */
    flex: 0 1 82px;
    text-align: center;
}


@media screen and (max-width: 660px) {
    .safety-checks .sea-row-history > div:nth-of-type(7) { /* Critical */
        display: none;
    }
}

@media screen and (max-width: 550px) {
    .safety-checks .sea-row-history > div:nth-of-type(3)::before {
        content: 'Completed';
    }
    .safety-checks .sea-row-history > div:nth-of-type(4)::before {
        content: 'Interval';
    }
    .safety-checks .sea-row-history > div:nth-of-type(5)::before {
        content: 'Vessel';
    }
}
