.spare-parts-list {
    max-width: var(--max-table-width);
}

.spare-parts-list .sea-row > div:nth-of-type(1) { /* img */
    flex: 0 1 56px;
}
.spare-parts-list .sea-row .item { /* Item */
    flex: 1.3 1;
}
.spare-parts-list .sea-row .quantity, /* orderQuantity */
.spare-parts-list .sea-row .orderQuantity { /* quantity */
    flex: 0 1 90px;
    text-align: center;
}
.spare-parts-list .sea-row .quantity.error { /* quantity */
    color: var(--ion-color-danger);
    font-weight: bold;
}
.spare-parts-list .sea-row .equipmentList { /* equipmentList */
    flex: 1 1;
}
.spare-parts-list .sea-row .location { /* location */
    flex: 0 1 200px;
}
.spare-parts-list .sea-row .partNum { /* part # */
    flex: 0 1 120px;
}
.spare-parts-list .sea-row > div:nth-of-type(7) { /* critical # */
    flex: 0 1 80px;
    text-align: center;
}


@media screen and (max-width: 525px) {
    .spare-parts-list.page-head {
        flex-direction: column;
    }
    .spare-parts-list.page-head .actions {
        justify-content: flex-start;
    }
}

@media screen and (max-width: 1075px) {
    .spare-parts-list .sea-row .equipmentList { /* equipmentList */
        flex: 1 1;
    }
    .spare-parts-list .sea-row .location { /* location */
        flex: 1 1;
    }
}
@media screen and (max-width: 920px) {
    .spare-parts-list .sea-row .partNum { /* Part # */
        display: none;
    }
}
@media screen and (max-width: 760px) {
    .spare-parts-list .sea-row .equipmentList { /* Equipment */
        display: none;
    }
}

@media screen and (max-width: 1200px) {
    .show-menu.desktop-view .spare-parts-list .sea-row .equipmentList { /* equipmentList */
        flex: 1 1;
    }
    .show-menu.desktop-view .spare-parts-list .sea-row .location { /* location */
        flex: 1 1;
    }
}
@media screen and (max-width: 1080px) {
    .show-menu.desktop-view .spare-parts-list .sea-row .partNum { /* Part # */
        display: none;
    }
}
@media screen and (max-width: 950px) {
    .show-menu.desktop-view .spare-parts-list .sea-row .equipmentList { /* Equipment */
        display: none;
    }
}

@media screen and (max-width: 550px) {
    .spare-parts-list .sea-row.sea-card > div::before {
        width: 74px;
    }
    .spare-parts-list .sea-row .quantity::before {
        content: 'Quantity';
    }
    .spare-parts-list .sea-row .orderQuantity::before {
        content: 'To Order';
    }
    .spare-parts-list .sea-row .equipmentList {
        display: none; /* Hide Equipment */
    }
    .spare-parts-list .sea-row .location::before {
        content: 'Location';
    }
    .spare-parts-list .sea-row .partNum {
        display: none; /* Hide Part # */
    }
    .spare-parts-list .sea-row .unitPrice {
        display: none; /* Hide Part # */
    }
    .spare-parts-list .sea-row .quantity, /* orderQuantity */
    .spare-parts-list .sea-row .orderQuantity { /* quantity */
        text-align: unset;
    }
}
