.input-list-row {
  margin-bottom: 10px;
  display: flex;
}

.input-list-row > div {
  align-self: center;
}

.input-list-row .trash {
  width: 44px;
  height: 44px;
  color: var(--ion-color-primary);
  cursor: pointer;
  text-align: center;
  padding-top: 12px;
  transform: scale(1.2);
}

.trash.disabled {
  cursor: default;
  opacity: 0.5;
}

.sea-add-new-button {
  margin-top: -2px;
  display: flex;
}

.links-search-bar {
  padding-left: 12px;
  padding-right: 12px;
}

.links-title-container {
  padding-top: var(--safe-inset-top);
  background-color: var(--ion-color-light);
}

.links-title {
  padding-left: 12px;
  padding-right: 12px;
}

.links-title.sea-select-multi-title {
  max-width: unset;
}

.platform-hybrid .sea-select-multi-links {
  padding-top: 12px;
  overflow-x: auto; /* Allow horizontal scrolling */
  -webkit-overflow-scrolling: touch; /* Enable smooth scrolling on iOS */
  max-width: 100%; /* Ensure it doesn't exceed the screen width */
  white-space: nowrap; /* Prevent line breaks */
}

.no-data-container {
  padding-left: 12px;
}

.sea-add-new-button .text {
  color: var(--ion-color-primary);
  padding: 8px 8px 9px 8px;
  align-self: center;
  cursor: pointer;
  font-size: 13px;
  font-weight: 500;
}

.sea-tab-content-container {
  display: flex;
  flex-direction: column;
  flex: 1;
  flex-grow: 1;
  overflow-y: auto;
  max-height: calc(100% - 66px);
}

.sea-tab-content {
  overflow-y: auto;
  max-height: calc(100vh - 100px);
}

.select-button {
  margin: 6px 0px 6px 0px;
  flex: 1;
  display: flex;
  flex-direction: row;
  white-space: normal; 
  height: auto;
}

.select-button::part(native) {
  width: 100%;
  background-color: var(--ion-color-light);
  border-width: 1px;
  border-style: solid;
  border-color: var(--input-border-color);
  border-radius: var(--input-border-radius);
  height: var(--input-height);
  padding: 10px 10px 10px 18px;
  color: var(--bg-header) !important;
  font-family: var(--sea-font-family);
  font-size: 12px;
  font-weight: 500;
  transition: border-color 0.1s, background-color 0.1s;
  text-align: left;
}


.sea-tab-content-container .category-heading {
  background-color: var(--text-on-grey);
  margin-top: 12px;
  text-align: left;
  color: var(--text-on-white);
}

.select-button > p {
  display: block;
  flex: 1;
}

.sea-link-value {
  display: inline-flex;
  flex: 1;
  margin-right: 10px; /* Adjust the spacing as needed */
  text-decoration: underline;
}

.sea-link-value:last-child {
  margin-right: 0; /* Remove the margin from the last item */
}

.select-button .arrow {
  left: -10px;
  top: -2px;
  transform: scale(1.2);
  opacity: 0.4;
}


.select-button.disabled {
  cursor: default;
}

.select-button.disabled::part(native) {
  background-color: var(--ion-color-light);
  border-color: var(--input-border-color);
  cursor: default;
  --border-style: none;
}
.select-icon.icon {
  position: relative;
  top: -2px;
  width: 12px;
  height: 18px;
  left: -10px;
  transform: scale(1.2);
  opacity: 0.4;
}

.select-icon-inner {
  left: 5px;
  top: 50%;
  margin-top: -2px;
  position: absolute;
  width: 0;
  height: 0;
  border-top: 5px solid;
  border-right: 5px solid transparent;
  border-left: 5px solid transparent;
  color: currentColor;
  pointer-events: none;
}

.sea-link {
  color: var(--ion-color-primary);
  text-decoration: underline;
  cursor: pointer;
}

.sea-link.input {
  display: -webkit-box;
  -webkit-line-clamp: 2; /* Limit to 2 lines */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.sea-link-value {
  margin-right: 10px; /* Adjust the spacing as needed */
  padding: 4px 0px;
  text-decoration: underline;
}

.sea-link-value:last-child {
  margin-right: 0; /* Remove the margin from the last item */
}

.platform-desktop .sea-link {
  margin: 4px 0px;
}

.sea-link.disabled {
  cursor: default;
  text-decoration: none;
  color: var(--ion-color-medium);
}

.sea-tab-content.external-links {
  display: flex;
  flex: 1;
  padding: 16px;
  padding-bottom: 32px;
  background-color: white;
}

.done-button-container {
  height: 60px;
  padding: 0px 16px 0px 16px;
  display: flex;
  align-items: center;
  justify-content: right;
  background-color: var(--ion-color-light);
}

.centered-modal.level-2::part(content) {
  z-index: 22;
}
.centered-modal.level-2::part(backdrop) {
  z-index: 21;
  --backdrop-opacity: var(--ion-backdrop-opacity, 0.4);
}
.centered-modal.level-3::part(content) {
  z-index: 32;
}
.centered-modal.level-3::part(backdrop) {
  z-index: 31;
  --backdrop-opacity: var(--ion-backdrop-opacity, 0.4);
}
.centered-modal.level-4::part(content) {
  z-index: 42;
}
.centered-modal.level-4::part(backdrop) {
  z-index: 41;
  --backdrop-opacity: var(--ion-backdrop-opacity, 0.4);
}