.crew-archived .sea-row-history > div:nth-of-type(1) { /* Date Archived */
    flex: 0 1 90px;
}
.crew-archived .sea-row-history > div:nth-of-type(2) { /* Name */
    flex: 1 1 0;
}
.crew-archived .sea-row-history > div:nth-of-type(3) { /* View Profile */
    flex: 0 1 80px;
}
.crew-archived .sea-row-history > div:nth-of-type(4) { /* Restore */
    flex: 0 1 35px;
    padding: 8px 8px;
    cursor: pointer;
}
.crew-archived .sea-row-history > div:nth-of-type(5) { /* Trash */
    flex: 0 1 35px;
    padding: 8px 8px;
    cursor: pointer;
}

@media screen and (max-width: 550px) {
    .crew-archived .sea-row-history.headings-history {
        display: flex;
    }
    .crew-archived .sea-card.sea-row-history {
        flex-wrap: nowrap;
    }
    .crew-archived .sea-card.sea-row-history > div {
        min-width: unset;
    }
}

@media screen and (max-width: 380px) {
    .crew-archived .sea-row-history > div:nth-of-type(1) {
        display: none;
    }
}
