:root {
    --debug-dark: #121624;
    --debug-bg: #313c62;
    --debug-text: #9ba6cd;
}

.tree-view-output {
    background-color: var(--debug-bg);
    color: var(--debug-text);
    overflow: hidden;
    margin-top: 4px;
}

.tree-view-output button {
    background-color: var(--debug-text);
    color: var(--debug-dark);
    padding: 4px 8px;
    margin: 2px;
    border-radius: 4px;
}
