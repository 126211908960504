.standard-operating-procedures {
  max-width: var(--max-table-width);
}

/* Column settings */
.standard-operating-procedures .sea-row > div:nth-of-type(1) { /* img */
  flex: 0 1 56px;
}
.standard-operating-procedures .sea-row > div:nth-of-type(2) { /* Title */
  flex: 1.3 1;
}
.standard-operating-procedures .sea-row > div:nth-of-type(3) { /* issue date */
  flex: 0 1 100px;
}


@media screen and (max-width: 705px) {
  .standard-operating-procedures.page-head {
      flex-direction: column;
  }
  .standard-operating-procedures.page-head .actions {
      justify-content: flex-start;
  }
}

@media screen and (max-width: 480px) {
  .standard-operating-procedures .sea-row > div:nth-of-type(3) { /* Issue Date */ 
      display: none;
  }
}

@media screen and (max-width: 740px) {
  .show-menu.desktop-view .standard-operating-procedures .sea-row > div:nth-of-type(3) { /* Issue Date */ 
      display: none;
  }
}

@media screen and (max-width: 550px) {
  .standard-operating-procedures .sea-row.sea-card > div::before {
    width: 77px;
  }
  .standard-operating-procedures .sea-row > div:nth-of-type(3) {
    display: none; /* Hide Issue Date */
  }
}
