.crew-tasks .sea-row {
    justify-content: flex-start;
    align-items: stretch;
}
.crew-tasks .sea-card {
    min-width: fit-content;
    height: 64px;
}
.crew-tasks .sea-row > div { /* all except first 2 (users) */
    flex: 0 1 100px;
    text-align: center;
    min-width: 100px;
    padding-left: 4px;
    padding-right: 4px;
}
.crew-tasks .sea-row > div:nth-of-type(1) { /* img */
    flex: 0 1 56px;
    min-width: 56px;
}
.crew-tasks .sea-row > div:nth-of-type(2) { /* task name */
    flex: 0 1 200px;
    min-width: 200px;
    text-align: left;
}

.crew-tasks .slot {
    align-self: stretch;
    transition: opacity 0.1s;
}
.crew-tasks .slot:hover {
    opacity: 0.75;
}
.crew-tasks .due {
    padding-top: 6px;
    height: 44px;
    border-radius: 3px;
    font-size: 16px;
    font-weight: 500;
}
.crew-tasks .due > div {
    position: relative;
    top: -1px;
    font-size: 11px;
    font-weight: normal;
    opacity: 0.9;
}
.crew-tasks .due.od {
    background-color: #FBD6D6;
}
.crew-tasks .due.soon {
    background-color: #FFF3CD;
}
.crew-tasks .due.ok {
    background-color: #D4EDDA;
}

@media screen and (max-width: 1000px) {
    .crew-tasks.page-head {
        flex-direction: column;
    }
    .crew-tasks.page-head .actions {
        justify-content: flex-start;
    }
}

.crew-training-content .sea-card.sea-row .more {
    display: none;
}



/* Compact mode for crew training */
@media screen and (max-width: 550px) {
    .crew-training-content .sea-row.headings {
        display: none;
    }
    .crew-training-content .sea-card.sea-row {
        padding: 8px 4px 8px 4px;
        height: unset;
        flex-direction: column;
    }
    .crew-training-content .sea-card.sea-row > div {
        display: none;
    }
    .crew-training-content .sea-card.sea-row .more {
        display: block !important;
        position: absolute;
        right: 0px;
        top: 0px;
        min-width: unset;
        padding: 21px 16px;
        color: var(--ion-color-primary);
        text-transform: uppercase;
        font-size: 12px;
    }
    .crew-training-content .sea-card.sea-row .more ion-icon {
        position: relative;
        top: 1px;
        margin-left: 2px;
    }
    .crew-training-content .sea-card.sea-row > div:nth-of-type(1) { /* Image */
        display: block !important;
        position: absolute;
        left: 8px;
        width: 40px;
        height: 40px;
        top: 0px;
        overflow: visible;
    }
    .crew-training-content .sea-card.sea-row > div:nth-of-type(2) { /* Task Name */
        display: block !important;
        flex: 0 1 40px;
        min-height: 40px;
        max-width: calc(100vw - 90px);
        align-self: flex-start;
        padding: 12px 40px 16px 54px;
    }
    .crew-training-content .sea-card.sea-row > .slot {
        position: relative;
        display: block;
        padding: 0px 8px;
        margin: 4px 0px 0px;
        flex: 1 1;
        text-align: left;
        padding-left: 220px;
    }
    .crew-training-content .sea-card.sea-row.shrunk > .slot {
        display: none;
    }
    .crew-training-content .sea-card.sea-row > .slot::before {
        content: attr(data-fullname);
        position: absolute;
        top: 10px;
        left: 54px;
        width: 160px;
        overflow-x: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        padding-left: 0px;
    }
    .crew-training-content .sea-card.sea-row > .slot .pushy {
        display: block;
        height: 30px;
        padding: 6px 8px;
        text-align: center;
        max-width: 200px;
    }
    .crew-training-content .sea-card.sea-row > .slot .pushy > div {
        display: inline-block;
        padding-left: 10px;
    }


}
@media screen and (max-width: 400px) {
    .crew-training-content .sea-card.sea-row > .slot::before {
        left: 4px;
    }
    .crew-training-content .sea-card.sea-row > .slot {
        padding-left: 170px;
    }
}
@media screen and (max-width: 380px) {
    .crew-training-content .sea-card.sea-row .more span {
        display: none;
    }
    .crew-training-content .sea-card.sea-row > div:nth-of-type(2) { /* Task Name */
        max-width: calc(100vw - 54px);
    }
}
@media screen and (max-width: 350px) {
    .crew-training-content .sea-card.sea-row > .slot::before {
        width: 110px;
    }
    .crew-training-content .sea-card.sea-row > .slot {
        padding-left: 120px;
    }
}
