
.svg-graph .y-axis-value {
    font-size: 11px;
    font-weight: 400;
    opacity: 1;
}

.svg-graph .x-axis-value {
    font-size: 11px;
    font-weight: 400;
    text-anchor: middle;
    opacity: 1;
}

.svg-graph .bar-value {
    font-size: 12px;
    font-weight: 500;
    text-anchor: end;
    opacity: 1;
    pointer-events: none;
}

.svg-graph .stacked-bar-graph .bars .bar,
.svg-graph .stacked-bar-graph .bars .bar-value,
.svg-graph .bar-graph .bar,
.svg-graph .bar-graph .bar-value {
    transition: var(--graph-transition);
}
.svg-graph .stacked-bar-graph .bars .bar,
.svg-graph .bar-graph .bar {
    -webkit-svg-shadow: 0px 0px 0px rgba(0, 0, 0, 0.5);
    /* -webkit-filter: drop-shadow(0px 0px 0px rgba(0, 0, 0, 0)); */
    filter: drop-shadow(0px 0px 0px rgba(0, 0, 0, 0));
}

.svg-graph .stacked-bar-graph.active .bars .active-value .bar,
.svg-graph .stacked-bar-graph.active .bars .active-value .bar-value,
.svg-graph .bar-graph.active .bar,
.svg-graph .bar-graph.active .bar-value {
    transform: translateY(-4px);
}
.svg-graph .stacked-bar-graph.active .bars .active-value .bar,
.svg-graph .bar-graph.active .bar {
    /* -webkit-filter: drop-shadow(0px 4px 6px rgba(0, 0, 0, .5)); */
    -webkit-svg-shadow: 0px 4px 6px rgba(0, 0, 0, 0.5);
    filter: drop-shadow(0px 4px 6px rgba(0, 0, 0, .5));
}

.svg-graph .grid {
    opacity: 0;
    animation: gridIn 0.4s ease-out forwards;
    animation-delay: calc(var(--graph) * 100ms + 100ms);
}
@keyframes gridIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.svg-graph .key.bottom,
.svg-graph .x-axis-label {
    opacity: 0;
    animation: keyBottomIn 0.2s ease-out forwards;
    animation-delay: calc(var(--graph) * 100ms + 200ms);
}
@keyframes keyBottomIn {
    from {
        opacity: 0;
        transform: translateY(10px);
    }
    to {
        opacity: 1;
        transform: translateY(0px);
    }
}

.svg-graph .bars .in,
.svg-graph .bar-graph .in {
    opacity: 0;
    transform-origin: var(--x-axis) center;
    animation: barIn 0.4s ease-out forwards;
    animation-delay: calc(var(--graph) * 100ms + 100ms + var(--order) * var(--order-delay));
}
@keyframes barIn {
    from {
      opacity: 0;
      transform: scale(0 , 1);
    }
    to {
      opacity: 1;
      transform: scale(1, 1);
    }
}

.svg-graph .y-label {
    font-size: 13px;
    opacity: 0;
    animation: yLabelIn 0.2s ease-out forwards;
    animation-delay: calc(var(--graph) * 100ms + 100ms + var(--order) * var(--order-delay));
}
/* .svg-graph .y-label {
    font-style: italic;
} */
@keyframes yLabelIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
