.sea-checkbox-container {
  display: flex;
}
.sea-checkbox {
  background-color: transparent;
  /*
  border-width: 1px;
  border-style: solid;
  border-color: var(--input-border-color);
  */
  height: 40px;
  width: 40px;
  color: var(--bg-header) !important;
  transition: border-color 0.1s;
  text-align: left;
  --border-color: #aeafb5;
  --border-width: 1px;
  --border-radius: 6px;
  --checkmark-width: 3px;
  --transition: none;
}
.element.checkbox.view-only .sea-checkbox {
  --background: var(--bg-view-only);
  --background-checked: var(--bg-view-only);
  --border-color: var(--bg-view-only);
  --border-color-checked: var(--bg-view-only);
  --ion-color-contrast: #888 !important;
}
.element.checks.view-only .sea-checkbox {
  background-color: var(--bg-view-only);
  border-style: none;
}
.element.checks.view-only .sea-checkbox.on {
  background-color: var(--bg-view-only);
  color: #888 !important;
}
.sea-checkbox.has-focus {
  --border-color: var(--ion-color-primary);
  --border-color-checked: var(--bg-header);
}
.sea-checkbox.has-error {
  --border-color: var(--ion-color-danger);
  --border-color-checked: var(--ion-color-danger);
}
.sea-checkbox-container .description {
  align-self: center;
  padding: 0px 8px 0px 10px;
  font-size: 13px;
  margin-bottom: 3px;
  cursor: pointer;
}
.sea-checkbox-container .description.disabled {
  opacity: 0.5;
  cursor: default;
}

.sea-checkbox-container.beside-input {
  height: var(--input-height);
  align-items: center;

}
.sea-checkbox-container.beside-input > div {
  padding-top: 2px;
}
ion-checkbox::part(mark) {
  transform: scale(0.8) translate(3px, 3px);
  transition: none;
}
