.training-task-user-history .sea-row-history > div:nth-of-type(1) { /* Date */
    flex: 0 1 110px;    
}
.training-task-user-history .sea-row-history > div:nth-of-type(2) { /* Trainer */
    flex: 0 1 160px;
}
.training-task-user-history .sea-row-history > div:nth-of-type(3) { /* Notes */
    flex: 1 1 0;
    cursor: pointer;
}
.training-task-user-history .sea-row-history > div:nth-of-type(4) { /* Trash */
    flex: 0 1 35px;
    padding: 10px 10px;
    cursor: pointer;
}


@media screen and (max-width: 550px) {
    .training-task-user-history .sea-card.sea-row-history > div::before {
        width: 70px;
    }

    .training-task-user-history .sea-row-history > div:nth-of-type(1),
    .training-task-user-history .sea-row-history > div:nth-of-type(2) {
        padding-right: 30px;
    }

    .training-task-user-history .sea-row-history > div:nth-of-type(1)::before {
        content: 'Date';
    }
    .training-task-user-history .sea-row-history > div:nth-of-type(2)::before {
        content: 'Trainer';
    }
    .training-task-user-history .sea-row-history > div:nth-of-type(3) {
        white-space: normal;
        padding-left: 70px;
    }
    .training-task-user-history .sea-row-history > div:nth-of-type(3)::before {
        content: 'Notes';
        position: absolute;
        left: 0px;
    }

    .training-task-user-history .sea-row-history > div:nth-of-type(4) {
        height: 0px;
        padding: 0px;
    }

    .training-task-user-history .sea-row-history > div:nth-of-type(4) > div {
        position: absolute;
        top: 10px;
        right: 10px;
    }
}
