.maintenance-schedule-report .sea-row-history > div:nth-of-type(1) { /* img */
    flex: 0 1 56px;
}
.maintenance-schedule-report .sea-row-history > div:nth-of-type(2) { /* Equipment */
    flex: 1.5 1 0;
}
.maintenance-schedule-report .sea-row-history > div:nth-of-type(3) { /* next due */
    flex: 0 1 100px;
}
.maintenance-schedule-report .sea-row-history > div:nth-of-type(4) { /* Vessel */
    flex: 1 1 0;
}
.maintenance-schedule-report.hide-vessel .sea-row-history > div:nth-of-type(4) { /* Vessel */
    display: none;
}
.maintenance-schedule-report .sea-row-history > div:nth-of-type(5) { /* status */
    flex: 0 1 150px;
}
.maintenance-schedule-report .sea-row-history.headings-history > div:nth-of-type(5) {
    text-align: center;
}
.maintenance-schedule-report .sea-row-history > div:nth-of-type(6) { /* Critical */
    flex: 0 1 82px;
    text-align: center;
}

@media screen and (max-width: 800px) {
    .maintenance-schedule-report .sea-row-history > div:nth-of-type(3) { /* next check */
        display: none;
    }
}

@media screen and (max-width: 550px) {
    .maintenance-schedule-report .sea-row-history > div:nth-of-type(4)::before {
        content: 'Vessel';
    }
    .maintenance-schedule-report .sea-row-history > div:nth-of-type(6) { /* Critical */
        display: block !important;
        padding: 0px;
    }
}
