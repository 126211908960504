.sea-checks .row {
    display: flex;
}
.sea-checks .checks-label {
    width: 135px; /* (40+5) * 3*/
}
.sea-checks .sea-label {
    height: unset;
}
.sea-label.note {
    width: auto;
    text-transform: none;
    font-style: italic;
    height: unset;
    line-height: 1.3em;
}
.sea-checks .sea-label.option {
    width: 45px;
    padding-right: 5px;
    text-align: center;
    padding: 8px 6px 0px 0px;
}
.sea-checks .sea-label.option .tick {
    transform: scale(0.62);
    margin-top: -2px;
    margin-bottom: -2px;
}

.sea-checks .row > div {
    padding: 6px 5px 6px 0px;
}
.sea-checks .margin {
    padding-left: 10px !important;
}
.sea-checks .row > div.margin {
    align-self: center;
}
.sea-checks .sea-checkbox {
    color: #fff !important;
    border-color: var(--input-border-color);
    border-style: solid;
    border-width: 1px;
    border-radius: 6px;
    background-color: var(--ion-color-light);
    cursor: pointer;
    transition: background-color 0.1s;
}

.sea-checks .sea-checkbox.pass.on {
    background-color: #28a745;
}
.sea-checks .sea-checkbox.fail.on {
    background-color: #eb3232;
}
.sea-checks .sea-checkbox.na.on {
    background-color: var(--yellow-orange);
}

.sea-checks .check-icon {
    opacity: 0;
    transition: opacity 0.1s;
}
.sea-checks .sea-checkbox.on .check-icon {
    opacity: 1;
}

.checks.view-only .sea-checkbox {
    cursor: default;
}
