@media screen and (max-width: 750px) {
    .sea-modal .crew-particulars .sea-grid .w33 {
        width: 50%;
    }
}
@media screen and (max-width: 475px) {
    .sea-modal .crew-particulars .sea-grid .w33 {
        width: 100%;
    }
}
