.sea-searchbar {
  --box-shadow: none;
  padding: 4px 0px;
  --border-radius: var(--input-border-radius);
}

.sea-searchbar ion-icon {
  color: var(--bg-header) !important;
  opacity: 0.5;
}

.sea-searchbar .searchbar-input {
  color: var(--bg-header) !important;
  font-family: var(--sea-font-family);
  font-size: 12px;
  font-weight: 500;
  height: 40px;
  border-color: #babbc2;
  border-style: solid;
  border-width: 1px;
  padding-left: 42px !important;
  padding-right: 42px !important;
}
.platform-ios .sea-searchbar .searchbar-input {
  padding-right: 32px !important;
}

.sea-searchbar .searchbar-search-icon {
  left: 11px;
  top: 10px;
}

/* .platform-ios .sea-searchbar .searchbar-search-icon {
  left: 11px;
  top: 2px;
} */
