
.crew-certificate-report .sea-row-history > div:nth-of-type(1) { /* Thumbnail */
    flex: 0 1 56px;
}
.crew-certificate-report .sea-row-history > div:nth-of-type(2) { /* Title */
    flex: 1.5 1 0;
}
.crew-certificate-report .sea-row-history > div:nth-of-type(3) { /* Expiry */
    flex: 0 1 100px;
}

.crew-certificate-report .sea-row-history > div:nth-of-type(5) { /* status */
    flex: 0 1 150px;
    text-align: center;
}

@media screen and (max-width: 550px) {
    .crew-certificate-report .sea-row-history > div:nth-of-type(3)::before {
        content: 'Expiry';
        width: 80px !important;
    }
    .crew-certificate-report .sea-row-history > div:nth-of-type(4)::before {
        content: 'Held By';
        width: 80px !important;
    }
}
