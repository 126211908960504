.lex-toolbar-icon {
    display: inline-block;
    background-size: contain;
    vertical-align: text-bottom;
    position: relative;
}

.lex-toolbar-icon.start {
    margin-right: 8px;
}
