
/* Use full sidemenu by default */
.sea-side-bar-box {
  align-items: center;
  cursor: pointer;
  transition: var(--responsive-transition-time) var(--responsive-transition-time);
  justify-content: left;
  color: #bfc4ca;
  display: flex;
  width: var(--side-menu-width);
  height: 44px;
  /* border-top: 1px solid #ccc; */
}
.sea-side-bar-box-content {
  display: flex;
}
.sea-side-bar-box.tab {
  overflow: hidden;
  height: 44px;
  margin-top: 0px;
  /* border-top: none; */
}
.platform-desktop .sea-side-bar-box {
  height: 36px;
  transition: all 0.2s;
}
.platform-desktop .sea-side-bar-box.tab {
  height: 26px;
}
/* .platform-desktop .sea-side-bar-box:hover {
  background-color: rgba(255,255,255,1);
}
.platform-desktop .sea-side-bar-sub .sea-side-bar-box:hover {
  background-color: rgba(255,255,255,0.75);
} */
.platform-desktop .sea-side-bar-box.selected:hover {
  background-color: var(--ion-color-primary);
}
.platform-desktop .sea-side-bar-box.has-tabs.selected:hover {
  background-color: transparent;
}
/* .platform-desktop .sea-side-bar-sub .sea-side-bar-box.selected:hover {
  background-color: var(--ion-color-primary);
} */
.sea-side-bar-box.sub-menu .sea-side-bar-box-content {
  color: #b4b6bc;
}
.sea-side-bar-box.sub-menu.selected .sea-side-bar-box-content {
  color: var(--ion-color-primary);
}
.sea-side-bar-name {
  display: block;
  color: var(--text-on-white);
  font-size: 14px;
  font-weight: 600;
  padding: 0px 0px 0px 2px;
  overflow: hidden;
  white-space: nowrap;
  align-self: center;
  transition: color 0.2s;
}
.sea-side-bar-box.tab .sea-side-bar-name {
  font-weight: 400;
  padding: 0px 8px 0px 2px;
  margin-left: 0px;
  font-size: 14px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 216px;
}
.sea-side-bar-name .tag-new {
  color: #fff;
  background-color: #ff6014;
  display: inline-block;
  font-size: 10px;
  padding: 3px 5px 3px;
  margin-top: 0px;
  font-weight: 500;
  position: absolute;
  left: 5px;
  border-radius: 12px;
}
.sea-side-bar-box.has-tabs .sea-side-bar-name .tag-new {
  right: 8px;
  left: unset;
  transform: translateY(-2px);
}
.sea-side-bar-box.selected {
  background-color: var(--ion-color-primary);
}
.sea-side-bar-box.has-tabs.selected {
  background-color: transparent;
}
.sea-side-bar-box.selected .sea-side-bar-name {
  color: white !important;
}
.sea-side-bar-box.has-tabs.selected .sea-side-bar-name {
  color: var(--ion-color-primary) !important;
}
.sea-side-bar-box.selected .sea-side-bar-icon {
  color: var(--ion-color-light);
  opacity: 1;
}
.sea-side-bar-box.has-tabs.selected .sea-side-bar-icon {
  color: var(--ion-color-primary) !important;
  opacity: 1;
}
.sea-tool-tip {
  display: none !important;
}
.sea-side-bar-icon {
  width: 42px;
  display: flex;
  justify-content: center;
  padding-left: 5px;
  color: var(--text-on-white);
  opacity: 0.68;
}

/* Position side menu on screen by default */


/* Use thin side menu when
      not mobile and
      not large desktop view and
      menu off
*/
@media screen and (min-width: 851px) and (max-width: 1300px) {
  .sea-side-bar.hide-menu .sea-side-bar-box.sub-menu {
    padding-left: 4px;
  }
  .sea-side-bar.hide-menu .sea-side-bar-box,
  .sea-side-bar.hide-menu .sea-side-bar-box:hover {
    width: 58px;
    height: 58px;
    background-color: inherit;
  }
  .sea-side-bar.hide-menu .sea-side-bar-box.tab {
    display: none;
  }
  .sea-side-bar.hide-menu .sea-side-bar-name {
    display: none;
  }
  .sea-side-bar.hide-menu .sea-side-bar-icon {
    color: inherit;
    opacity: 1;
    width: 50px;
    padding-left: 6px;
  }
  .sea-side-bar.hide-menu .sea-side-bar-box.selected .sea-side-bar-icon {
    color: var(--ion-color-primary) !important;
    opacity: 1;
  }
}


/* Position side menu off screen when 
      mobile and
      menu off
*/
@media screen and (max-width: 850px) {
  .platform-desktop .sea-side-bar-box.tab .sea-side-bar-name {
    width: 208px;
  }
}
