.error-reports.columns {
    column-gap: 4px;
}

.error-reports input,
.error-reports ion-input,
.error-reports .sea-input.sea-select {
    height: 40px;
    max-width: 200px;
}

.error-reports .sea-label {
    color: white;
    height: 16px;
}

.error-reports .sea-searchbar {
    padding: 0px 0px;
}

.error-reports .screen-indicator {
    display: inline-block;
    width: 20px;
    height: 20px;
    border-radius: 20px;
    margin-top: 4px;
    margin-right: 8px;
    border-color: #666;
    border-width: 1px;
    border-style: solid;
    opacity: 0.6;
}


/* Column settings */
.error-reports .sea-row > div:nth-of-type(1) { /* When Reported */
    flex: 0 1 150px;
}
.error-reports .sea-row > div:nth-of-type(2) { /* Screen Color */
    flex: 0 1 36px;
}
.error-reports .sea-row > div:nth-of-type(3) { /* Type */
    flex: 0 1 150px;
}
.error-reports .sea-row > div:nth-of-type(4) { /* What Failed */
    flex: 1 1 0;
}
.error-reports .sea-row > div:nth-of-type(5) { /* Reason */
    flex: 1 1 0;
}
.error-reports .sea-row > div:nth-of-type(6) { /* Version & Build */
    flex: 0 1 100px;
}
.error-reports .sea-row > div:nth-of-type(7) { /* Platform */
    flex: 0 1 94px;
    text-align: center;
}
.error-reports .sea-row > div:nth-of-type(8) { /* OS */
    flex: 0 1 80px;
}
.error-reports .sea-row > div:nth-of-type(9) { /* OS Version */
    flex: 0 1 120px;
}
.error-reports .sea-row > div:nth-of-type(10) { /* Who */
    flex: 0 1 200px;
}
.error-reports .sea-row > div:nth-of-type(11) { /* Vessel */
    flex: 0 1 150px;
}
.error-reports .sea-row > div:nth-of-type(12) { /* Reference */
    flex: 0 1 108px;
}
