.safety-checks-history .sea-row-history > .by { /* By */
    flex: 0 1 150px;
}
.safety-checks-history .sea-row-history > .actual-time { /* Actual Time */
    flex: 0 1 120px;
}
.safety-checks-history .sea-row-history > .notes { /* Notes */
    flex: 1 1;
}
.safety-checks-history .sea-row-history > .image { /* Image */
    flex: 0 1 56px;
}

@media screen and (max-width: 550px) {
    .safety-checks-history .sea-card.sea-row-history > div::before {
        width: 85px;
    }
    
    .safety-checks-history .sea-row-history > .date,
    .safety-checks-history .sea-row-history > .by {
        padding-right: 44px;
    }
    .safety-checks-history .sea-row-history > .date::before {
        content: 'Date';
    }
    .safety-checks-history .sea-row-history > .by::before {
        content: 'By';
    }
    .safety-checks-history .sea-row-history > .actual-time::before {
        content: 'Actual Time';
    }
    .safety-checks-history .sea-row-history > .notes::before {
        content: 'Notes';
    }
    .safety-checks-history .sea-row-history > .image {
        height: 0px;
        padding: 0px;
    }
    .safety-checks-history .sea-row-history > .image > .sea-file-image {
        position: absolute;
        top: 10px;
        right: 8px;
    }
}
