.sea-time .sea-row-history > div:nth-of-type(1) { /* Date */
    flex: 0 1 90px;
}
.sea-time.hide-vessel .sea-row-history > div:nth-of-type(2) { /* Vessel */
    display: none;
}
.sea-time .sea-row-history > div:nth-of-type(6) { /* Days */
    flex: 0 1 90px;
    text-align: center;
}
.sea-time .sea-row-history > div:nth-of-type(7) { /* Hours */
    flex: 0 1 90px;
    text-align: center;
}
.sea-time .sea-row-history > div:nth-of-type(8) { /* Official Hours */
    flex: 0 1 90px;
    text-align: center;
}


.sea-time-filters.columns.filters {
    align-items: flex-start;
}

.sea-time-filters.columns.filters .right{
    justify-content: flex-end;
    padding-right: 0px;
}

.sea-time-filters .sea-stat {
    padding: 12px 12px;
    min-width: 115px;
    margin: 0px 0px 0px 8px;
}
.sea-time-filters .sea-stat:first-of-type {
    margin: 0px 0px 0px 0px;
}

.sea-time-filters .sea-stat > div {
    padding: 4px 0px 8px 0px;
}

@media screen and (max-width: 920px) {
    .sea-time-filters.columns {
        flex-wrap: wrap;
    }
    .sea-time-filters.columns.filters > div {
        flex: 1 1 0;
        padding-bottom: 8px;
    }
    .sea-time-filters.columns ion-select {
        min-width: 200px;
        width: 100% !important;
    }
    .sea-time-filters.columns .right {
        display: flex;
        padding-bottom: 0px;
    }
}

@media screen and (max-width: 770px) {
    .sea-time.show-vessel .sea-row-history > div:nth-of-type(4) { /* From */
        display: none;
    }
}
@media screen and (max-width: 680px) {
    .sea-time.show-vessel .sea-row-history > div:nth-of-type(5) { /* To */
        display: none;
    }
}

@media screen and (max-width: 550px) {
    .sea-time .sea-row-history > div:nth-of-type(1) {
        font-weight: 600;
    }
    .sea-time .sea-row-history > div:nth-of-type(2)::before {
        content: 'Vessel';
    }
    .sea-time .sea-row-history > div:nth-of-type(3)::before {
        content: 'Skipper';
    }
    .sea-time.show-vessel .sea-row-history > div:nth-of-type(4) {
        display: block;
    }
    .sea-time.show-vessel .sea-row-history > div:nth-of-type(5) {
        display: block;
    }
    .sea-time .sea-row-history > div:nth-of-type(4)::before {
        content: 'From';
    }
    .sea-time .sea-row-history > div:nth-of-type(5)::before {
        content: 'To';
    }
    .sea-time .sea-row-history > div:nth-of-type(6) {
        text-align: left;
    }
    .sea-time .sea-row-history > div:nth-of-type(6)::before {
        content: 'Days';
    }
    .sea-time .sea-row-history > div:nth-of-type(7) {
        text-align: left;
    }
    .sea-time .sea-row-history > div:nth-of-type(7)::before {
        content: 'Hours';
    }
    .sea-time .sea-row-history > div:nth-of-type(8) {
        text-align: left;
    }
    .sea-time .sea-row-history > div:nth-of-type(8)::before {
        content: 'Official';
    }
}

@media screen and (max-width: 400px) {
    .sea-time-filters .sea-stat {
        min-width: 75px;
    }
}

