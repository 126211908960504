.sea-custom-forms {
    max-width: var(--max-table-width);
}
.sea-custom-forms .sea-row > div:nth-of-type(2) { /* Last Completed */
    flex: 0.8 1 0;
}
.sea-custom-forms .sea-row > div:nth-of-type(3) { /* Linked To */
    flex: 0 0 150px;
}

@media screen and (max-width: 850px) {
    .sea-custom-forms.page-head {
        flex-direction: column;
    }
    .sea-custom-forms.page-head .actions {
        justify-content: flex-start;
    }
}

@media screen and (max-width: 750px) {
    .platform-hybrid .sea-custom-forms.page-head .actions ion-button {
        display: none;
    }
    .platform-hybrid .sea-custom-forms.page-head .actions ion-button.button-has-icon-only {
        display: inline-block;
    }
    .platform-hybrid .sea-custom-forms.page-head {
        flex-direction: row;
    }
    .platform-hybrid .sea-custom-forms.page-head .actions {
        justify-content: flex-end;
    }
}


@media screen and (max-width: 550px) {
    .sea-custom-forms .sea-row.sea-card > div::before {
        width: 100px;
    }
    .sea-custom-forms .sea-row > div:nth-of-type(2)::before {
        content: 'Completed';
    }
    .sea-custom-forms .sea-row > div:nth-of-type(3)::before {
        content: 'Linked To';
    }
}
