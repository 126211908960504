.sea-textarea {
  background-color: var(--ion-color-light) !important;
  height: var(--sea-textarea-height);
  --padding-top: 18px;
  --padding-bottom: 18px;
}
.sea-textarea textarea {
  height: var(--sea-textarea-height);
}
.sea-textarea.has-error {
  background-color: #fff5f5 !important;
}
