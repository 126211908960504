.incidents .sea-row-history > div:nth-of-type(1) { /* Date */
    flex: 0 1 100px;
}
.incidents.by-type .sea-row-history > div:nth-of-type(2) { /* Type */
    flex: 0 1 110px;
}
.incidents .sea-row-history > div:nth-of-type(3) { /* Name */
    flex: 1.5 1 0;
}
.incidents .sea-row-history > div:nth-of-type(4) { /* Vessel */
    flex: 1 1 0;
}
.incidents.hide-vessel .sea-row-history > div:nth-of-type(4) { /* Vessel */
    display: none;
}


@media screen and (max-width: 550px) {
    .incidents .sea-row-history > div:nth-of-type(1) {
        font-weight: 600;
    }
    .incidents.by-type .sea-row-history > div:nth-of-type(2)::before {
        content: 'Type';
    }
    .incidents .sea-row-history > div:nth-of-type(3)::before {
        content: 'Name';
    }
    .incidents .sea-row-history > div:nth-of-type(4)::before {
        content: 'Vessel';
    }
}
