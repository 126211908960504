.sea-side-menu-bg {
  position: fixed;
  /* background-color: rgba(255,50,100,0.5); */
  background: linear-gradient(90deg, #000008 0px, #101018 50px);
  top: calc(var(--header-bar-height) + var(--safe-inset-top));
  bottom: 0px;
  right: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: var(--responsive-transition-easing) var(--responsive-transition-time);
}
.sea-side-menu-bg {
  display: none;
}
.display-bg .sea-side-menu-bg {
  display: block;
}

/* Use full sidemenu by default */
.sea-side-bar {
  background-color: var(--ion-color-light);
  width: var(--side-menu-width);
  position: fixed;
  height: calc(100% - var(--header-bar-height) - var(--resize-side-bar-height) - var(--safe-inset-top));
  z-index: 1;
  overflow-y: auto;
  overflow-x: hidden;
  transition: var(--responsive-transition-easing) var(--responsive-transition-time);
  top: calc(var(--header-bar-height) + var(--safe-inset-top));
  scrollbar-color: var(--bg-grey) var(--bg-header);
}
.side-bar-spacer {
  height: 10px;
  transition: height var(--responsive-transition-easing) var(--responsive-transition-time);
}
.sea-side-bar .vessel-name {
  height: 28px;
  font-size: 12px;
  padding: 6px 10px 0px 14px;
  /* background-color: #dbdcdf; */
  text-align: left;
  text-transform: uppercase;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: default;
}
.sea-side-bar-sub {
  background-color: #e9eaed;
  max-height: 0px;
  overflow: hidden;
  transition: max-height var(--responsive-transition-easing) var(--responsive-transition-time);
}
.sea-side-bar-sub.menu-on {
  max-height: 1040px;
  box-shadow: inset 0px 1px 6px rgb(0 0 50 / 15%);
}
.sea-side-bar-line {
  height: 1px;
  background-color: var(--icon-grey);
  margin-bottom: 5px;
}
.side-bar-logo {
  display: block;
  height: 60px;
  background-color: #545561;
}
.side-bar-logo > img {
  width: 150px;
  margin: 15px 49px;
  opacity: 0.75;
}
.show-menu .sea-side-menu-bg {
  left: 0px;
  opacity: 0;
  transition: var(--responsive-transition-easing) var(--responsive-transition-time);
}
.show-menu.animate-in .sea-side-menu-bg {
  left: var(--side-menu-width);
  opacity: 0.5;
}
.resize-side-bar {
  display: none;
}
.sea-side-bar .account {
  display: block;
  padding: 0px 0px 10px 0px;
}
.sea-side-bar .account .divider {
  height: 1px;
  background-color: var(--icon-grey);
  margin: 10px 0px 4px;
}
.sea-side-bar .account .my-profile .sea-side-bar-icon {
  padding-left: 3px;
}
.sea-side-bar .account .log-out .sea-side-bar-icon {
  padding-left: 7px;
}

/* Position side menu on screen by default */
.sea-side-bar.animate-in {
  left: 0px;
  z-index: 1000;
}











/* Use thin side menu when
      not mobile and
      not large desktop view and
      menu off
*/
@media screen and (min-width: 851px) and (max-width: 1300px) {
  .sea-side-bar.hide-menu {
    width: var(--side-bar-width);
  }
  .sea-side-bar.hide-menu .side-bar-spacer {
    height: 0px;
  }
  .sea-side-bar.hide-menu .vessel-name {
    display: none;
  }
  .resize-side-bar {
    display: block;
    position: fixed;
    left: 0px;
    top: calc(var(--header-bar-height) + var(--safe-inset-top));
    /* background-color: #9696a1; */
    background-color: var(--icon-grey);
    height: var(--resize-side-bar-height);
    width: var(--side-menu-width);
    cursor: pointer;
    /* border: 1px solid rgba(255,255,255,0.5); */
    /* box-shadow: inset 0px 0px 8px rgba(0,0,0,0.25); */
    transition: width var(--responsive-transition-easing) var(--responsive-transition-time);
    z-index: 1;
  }
  .resize-side-bar.hide-menu {
    width: var(--side-bar-width);
  }
  .sea-side-bar {
    top: calc(var(--header-bar-height) + var(--resize-side-bar-height) + var(--safe-inset-top));
    height: calc(100% - var(--header-bar-height) - var(--resize-side-bar-height) - var(--safe-inset-top));
  }
  .resize-side-bar > img {
    position: absolute;
    top: 9px;
    right: 7px;
    width: 8px;
    height: 12px;
  }
  .platform-hybrid .resize-side-bar > img {
    width: 10px;
    height: 17px;
    top: 13px;
  }
  .resize-side-bar.show-menu > img {
    transform: scaleX(-1);
  }
}

@media screen and (min-width: 851px) {
  .show-menu .sea-side-menu-bg {
    display: none;
  }
  .side-bar-logo {
    display: none;
  }
  .sea-side-bar .account {
    display: none;
  }
}

/* Position side menu off screen when 
      mobile and
      menu off
*/
@media screen and (max-width: 850px) {
  .sea-side-bar {
    transition: var(--responsive-transition-easing) var(--responsive-transition-time);
    left: calc(-1 * var(--side-menu-width));
  }
  .sea-side-bar.show-menu.animate-in {
    left: 0px;
  }
}
