.risk-matrix.edit td {
    border-width: 3px;
    /* border-style: dotted; */
    /* border-color: #666; */
    border-color: var(--ion-color-primary);
    padding: 0px 4px;
    cursor: pointer;
}
.risk-matrix.edit td.no-border {
    cursor: default;
}
.risk-matrix.edit td.type {
    border-style: none;
    border-width: 1px;
    cursor: default;
}

.risk-matrix td.actions {
    border-style: none;
    width: 120px;
}

.risk-matrix td.actions > div {
    display: flex;
    justify-content: center;
}

.matrix-button {
    color: var(--ion-color-primary);
    /* color: var(--bg-grey); */
    height: 44px;
    width: 36px;
}
.matrix-button.disabled {
    opacity: 0.25;
    cursor: default;
    pointer-events: none;
}
.action-text {
    padding: 15px 0px 0px 4px;
    font-weight: 600;
    font-size: 12px;
}

.risk-matrix .new-item {
    color: var(--ion-color-primary);
}
.risk-matrix .new-item > div {
    display: inline-block;
    vertical-align: middle;
}
.risk-matrix .new-item > span {
    position: relative;
    top: 3px;
    padding-left: 4px;
    font-weight: 600;
}


.risk-matrix.edit.evaluations .label {
    border-color: #fff;
    border-width: 2px 2px 0px 2px;
    font-weight: 700;
    cursor: default;
}

.risk-matrix.edit.evaluations .name {
    font-weight: 600;
    border-color: #fff;
    border-width: 2px 0px 2px 2px;
    width: 200px;
    cursor: default;
}

.risk-matrix.edit.evaluations tr > td:nth-of-type(2) {
    text-align: left;
    padding: 0px 8px;
}
