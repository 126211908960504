.survey-reports {
    max-width: var(--max-table-width);
}

.survey-reports .sea-row > div:nth-of-type(1) { /* img */
    flex: 0 1 56px;
}
.survey-reports  .sea-row > div:nth-of-type(2) { /* Title */
    flex: 1.2 1;
}
.survey-reports  .sea-row > div:nth-of-type(3) { /* Date */
    flex: 0 1 85px;
}
.survey-reports  .sea-row > div:nth-of-type(7) { /* In or Out Water */
    flex: 0 1 130px;
}


@media screen and (max-width: 540px) {
    .survey-reports.page-head {
        flex-direction: column;
    }
    .survey-reports.page-head .actions {
        justify-content: flex-start;
    }
}

@media screen and (max-width: 1050px) {
    .survey-reports .sea-row > div:nth-of-type(7) { /* In/Out Water */ 
        display: none;
    }
}
@media screen and (max-width: 800px) {
    .survey-reports .sea-row > div:nth-of-type(5) { /* Present */ 
        display: none;
    }
}
@media screen and (max-width: 650px) {
    .survey-reports .sea-row > div:nth-of-type(6) { /* Location */ 
        display: none;
    }
}

@media screen and (max-width: 1230px) {
    .show-menu.desktop-view .survey-reports .sea-row > div:nth-of-type(7) { /* In/Out Water */ 
        display: none;
    }
}
@media screen and (max-width: 1050px) {
    .show-menu.desktop-view .survey-reports .sea-row > div:nth-of-type(5) { /* Present */ 
        display: none;
    }
}
@media screen and (max-width: 900px) {
    .show-menu.desktop-view .survey-reports .sea-row > div:nth-of-type(6) { /* Location */ 
        display: none;
    }
}

@media screen and (max-width: 550px) {
    .survey-reports .sea-row.sea-card > div::before {
        width: 77px;
    }
    .survey-reports .sea-row > div:nth-of-type(3)::before {
        content: 'Date';
    }
    .survey-reports .sea-row > div:nth-of-type(4)::before {
        content: 'Surveyor';
    }
    .survey-reports .sea-row > div:nth-of-type(5) {
        display: none; /* Hide Present */
    }
    .survey-reports .sea-row > div:nth-of-type(6) {
        display: none; /* Hide Location */
    }
    .survey-reports .sea-row > div:nth-of-type(7) {
        display: none; /* Hide In / Out Water */
    }
}
