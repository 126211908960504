.file-caching-settings h2 {
    font-size: 16px;
    color: var(--text-on-white);
    opacity: 1;
    margin: 24px 0px 8px;
}

.file-caching-settings .columns > div:nth-of-type(1) { /* Checkbox */
    align-self: center;
    padding-top: 6px;
    flex: 1 0 0;
    max-width: 300px;
    /* flex: 0 1 200px; */
}

.file-caching-settings .columns > div:nth-of-type(2) { /* History drop down */
    align-self: center;
    padding-top: 2px;
    flex: 1 1 0;
}

.file-caching-settings .columns ion-select.sea-input {
    height: 44px;
}
