.sop-history .sea-row-history > div:nth-of-type(1) { /* Date */
    flex: 0 1 90px;
}
.sop-history .sea-row-history > div:nth-of-type(2) { /* By */
    flex: 1 1 0;
}
.sop-history .sea-row-history > div:nth-of-type(3) { /* Notes */
    flex: 2 1 0;
}

.sop-history .sea-row-history > div:nth-of-type(4) { /* Image */
    flex: 0 1 56px;
    padding: 0px 8px;
}
.sop-history .sea-row-history > div:nth-of-type(5) { /* Trash */
    flex: 0 1 35px;
    padding: 8px 8px;
    cursor: pointer;
}


@media screen and (max-width: 550px) {
    .sop-history .sea-card.sea-row-history > div::before {
        width: 60px;
    }
    
    .sop-history .sea-row-history > div:nth-of-type(1),
    .sop-history .sea-row-history > div:nth-of-type(2) {
        padding-right: 80px;
    }
    
    .sop-history .sea-row-history > div:nth-of-type(1)::before {
        content: 'Date';
    }
    .sop-history .sea-row-history > div:nth-of-type(2)::before {
        content: 'By';
    }
    .sop-history .sea-row-history > div:nth-of-type(3) {
        padding-left: 60px;
        white-space: normal;
    }
    .sop-history .sea-row-history > div:nth-of-type(3)::before {
        content: 'Notes';
        position: absolute;
        left: 0px;
    }
    .sop-history .sea-row-history > div:nth-of-type(4) {
        height: 0px;
        padding: 0px;
    }
    .sop-history .sea-row-history > div:nth-of-type(4) > .sea-file-image {
        position: absolute;
        top: 10px;
        right: 40px;
    }
    .sop-history .sea-row-history > div:nth-of-type(5) {
        height: 0px;
        padding: 0px;
    }
    .sop-history .sea-row-history > div:nth-of-type(5) > div {
        position: absolute;
        top: 10px;
        right: 8px;
    }
}
