
.reporting-grid {
    display: grid;
    gap: 12px;
    grid-template-columns: 1fr;
}

@media screen and (min-width: 1034px) { /* Such that graph width >= 450 */
    .reporting-grid:not(.max-1-graphs) {
        max-width: 1212px; /* 600 + 12 + 600 */
        grid-template-columns: 1fr 1fr;
    }
}

@media screen and (max-width: 1240px) { /* Such that graph width >= 450 */
    .show-menu.desktop-view .reporting-grid {
        grid-template-columns: 1fr;
    }
}

@media screen and (min-width: 1704px) { /* 3 column layout */
    .reporting-grid:not(.max-1-graphs):not(.max-2-graphs) {
        max-width: 1824px; /* 600 + 12 + 600 + 12 + 600 */
        grid-template-columns: 1fr 1fr 1fr;
    }
}

@media screen and (min-width: 2166px) { /* 4 column layout */
    .reporting-grid:not(.max-1-graphs):not(.max-2-graphs):not(.max-3-graphs) {
        max-width: 2436px; /* 600 + 12 + 600 + 12 + 600 + 12 + 600*/
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }
}

@media screen and (min-width: 2628px) { /* 5 column layout */
    .reporting-grid:not(.max-1-graphs):not(.max-2-graphs):not(.max-3-graphs):not(.max-4-graphs) {
        max-width: 3048px; /* 600 + 12 + 600 + 12 + 600 + 12 + 600 + 12 + 600 */
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    }
}
