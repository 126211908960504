
.sea-select.multi.rich-text-headings {
  width: 400px;
  max-width: calc(100vw - 112px);
}

.rich-text-headings .select-text.h1 {
  font-weight: 600;
  font-size: 13px;
}

.rich-text-sections .sea-select-multi-box {
  text-align: left;
}

