.equipment-list {
    max-width: var(--max-table-width);
}

/* Column settings */
.equipment-list .sea-row > div:nth-of-type(1) { /* Thumb */
    flex: 0 1 56px;
}
.equipment-list .sea-row > div:nth-of-type(2) { /* Equipment */
    flex: 1.5 1;
}
.equipment-list .sea-row > div:nth-of-type(3) { /* Location */
    flex: 1 1;
}
.equipment-list .sea-row > div:nth-of-type(4) { /* Make */
    flex: 0.6 1;
}
.equipment-list .sea-row > div:nth-of-type(5) { /* Model */
    flex: 0.6 1;
}
.equipment-list .sea-row > div:nth-of-type(6) { /* Serial */
    flex: 0.6 1;
}
.equipment-list .sea-row > div:nth-of-type(7) { /* Critical */
    flex: 0 1 80px;
    text-align: center;
}

@media screen and (max-width: 820px) {
    .equipment-list .sea-row > div:nth-of-type(6) { /* Serial */
        display: none;
    }
}
@media screen and (max-width: 670px) {
    .equipment-list .sea-row > div:nth-of-type(5) { /* Model */
        display: none;
    }
}

@media screen and (max-width: 970px) {
    .show-menu.desktop-view .equipment-list .sea-row > div:nth-of-type(6) { /* Serial */
        display: none;
    }
}
@media screen and (max-width: 820px) {
    .show-menu.desktop-view .equipment-list .sea-row > div:nth-of-type(5) { /* Model */
        display: none;
    }
}

@media screen and (max-width: 550px) {
    .equipment-list .sea-row.sea-card > div::before {
        width: 74px;
    }
    .equipment-list .sea-row > div:nth-of-type(3)::before {
        content: 'Location';
    }
    .equipment-list .sea-row > div:nth-of-type(4)::before {
        content: 'Make';
    }
    .equipment-list .sea-row > div:nth-of-type(5) { /* Model */
        display: block;
    }
    .equipment-list .sea-row > div:nth-of-type(5)::before {
        content: 'Model';
    }
    .equipment-list .sea-row > div:nth-of-type(6) { /* Serial */
        display: block;
    }
    .equipment-list .sea-row > div:nth-of-type(6)::before {
        content: 'Serial';
    }
}
