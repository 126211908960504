.user-training .sea-row-history > div:nth-of-type(1) { /* img */
    flex: 0 1 56px;
}
.user-training .sea-row-history > div:nth-of-type(2) { /* Task */
    cursor: pointer;
}
.user-training .sea-row-history > div:nth-of-type(3) { /* When Completed */
    flex: 0 1 100px;
}
.user-training .sea-row-history > div:nth-of-type(4) { /* Trainer */
    flex: 0 1 135px;
}
.user-training  .sea-row-history > div:nth-of-type(5) { /* Notes */
    flex: 1 1 0;
    cursor: pointer;
}

@media screen and (max-width: 700px) {
    .user-training .sea-row-history > div:nth-of-type(4) { /* Trainer */
        display: none;
    }
}

@media screen and (max-width: 550px) {
    .user-training .sea-row-history > div:nth-of-type(2) {
        font-weight: 600;
    }
    .user-training .sea-row-history > div:nth-of-type(3)::before {
        content: 'Date';
    }
    .user-training .sea-row-history > div:nth-of-type(4) {
        display: block; /* Trainer */
    }
    .user-training .sea-row-history > div:nth-of-type(4)::before {
        content: 'Trainer';
    }
    .user-training .sea-row-history > div:nth-of-type(5) { /* Notes */
        display: none;
    }
}
