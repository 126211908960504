.sea-previews {
    display: flex;
    flex-wrap: wrap;
}
.sea-previews .card {
    width: 100px;
    height: 100px;
    background-color: var(--ion-color-light);
    border-color: var(--input-border-color);
    border-width: 1px;
    border-style: solid;
    border-radius: var(--input-border-radius);
    display: flex;
    justify-content: center;
    cursor: pointer;
    margin: 0px 5px 5px 0px;
}
.element.view-only .sea-previews .card {
    border-style: none;
}

.sea-previews img {
    object-fit: cover;
}
.sea-previews .card > div {
    align-self: center;
    text-align: center;
    margin-left: 8px;
    margin-right: 8px;
}
.sea-previews .doc-icon {
    opacity: 0.6;
    margin-bottom: 2px;
}

.drag-overlay {
    pointer-events: none;
    opacity: 0;
    z-index: -1;
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0);
    color: var(--label-text);
    outline: 2px dashed var(--bg-grey);
    outline-offset: -8px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    text-align: center;
    box-sizing: border-box;
    transition: background-color 0.25s ease, opacity 0.25s ease;
}

.contained .drag-overlay {
    position: absolute;
    font-size: 18px;
}

.drag-overlay.dragging {
    pointer-events: auto;
    opacity: 1;
    z-index: 10000;
    background-color: rgba(255, 255, 255, 0.8);
}

.label-with-help {
    display: flex;
    flex-direction: row;
    /* align-items: center; */
}

.fullscreen-view {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: 10000;
    background-color: rgba(0,0,0,0.8);
}
