.risk-options {
    display: flex;
}
.risk-options > div {
    padding: 6px 4px;
}
.risk-options > div:first-of-type {
    width: 125px;
    min-width: 125px;
}


.risk-popover::part(content) {
    width: 650px;
    max-width: 100%;
}

.sea-input.risk-rating {
    display: flex;
    justify-content: center;
}
.sea-input.risk-rating > div {
    text-align: center;
    align-self: center;
    font-weight: 600;
}

.text.sea-help.modal-h4 {
    padding: 16px 8px 0px 10px !important;
}

.risk-prompts {
    max-height: 0px;
    transition: all 0.3s;
    overflow: hidden;
    opacity: 0;
}
.risk-prompts.show {
    max-height: 800px;
    opacity: 1;
}

.risk-prompts > div {
    column-width: 180px;
}

.risk-prompts > .sea-label {
    margin-top: 8px;
}

.risk-prompts ul {
    padding-inline-start: 20px;
    margin-top: 0px;
}
.risk-prompts li {
    cursor: pointer;
}
.risk-prompts li:hover {
    background-color: var(--ion-color-light);
}
.risk-prompts li::marker {
    color: var(--text-on-white);
}
