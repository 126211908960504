.sea-button {
  text-transform: none;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  font-size: 13px !important;
  height: 40px;
  --box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.24);
  --transition: box-shadow 150ms cubic-bezier(0.4, 0, 0.2, 1), background-color 15ms linear, color 15ms linear;
  --padding-start: 22px;
  --padding-end: 22px;
  background-color: transparent;
  /* Todo: shadow whould change to blue-ish if primary or secondary */
}
.sea-button:active {
  --box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.24);
}
.sea-button.grey-zone {
  --border-style: solid;
  --border-color: #fff;
  --border-width: 2px;
  background-color: transparent;
}
.sea-button.blue-zone {
  --ion-color-contrast: var(--ion-color-primary) !important;
  background-color: transparent;
}
.sea-button.white-zone {
  --box-shadow: 0px 4px 10px rgba(var(--ion-color-primary-rgb), 0.35);
  background-color: transparent;
}
.sea-button.white-zone:active {
  --box-shadow: 0px 1px 4px rgba(var(--ion-color-primary-rgb), 0.35);
}
.sea-button.circle, .sea-button.square, .sea-button.square-outline {
  width: 40px;
  --padding-start: 0px;
  --padding-end: 0px;
}
.sea-button.square {
  --border-radius: 6px;
  --box-shadow: none;
}
.sea-button.square-outline {
  --ion-color-base: #fff !important;
  --ion-color-contrast: var(--ion-color-primary) !important;
  --border-style: solid;
  --border-radius: 6px;
  --box-shadow: none;
  --border-width: 2px;
  --border-color: var(--ion-color-primary);
}
.sea-button.small {
  min-width: 100px;
}
.sea-button.standard {
  min-width: 150px;
}
.sea-button.wide {
  min-width: 180px;
}
.sea-button.mini {
  transform: scale(0.85);
}
.sea-button.transparent {
  /*
  --ion-color-base: #fff !important;
  --ion-color-contrast: rgba(255,255,255,0.5) !important;
  */
  
  --border-style: none;
  --box-shadow: none !important;
  background-color: transparent;
}
