.lex-toolbar {
    position: relative;
    display: flex;
    background-color: var(--ion-color-light);
    padding: 4px;
    vertical-align: middle;
    overflow: auto;
    min-height: 45px;
    border-style: solid;
    border-color: var(--input-border-color);
    border-width: 1px 1px 1px 1px;
    flex-wrap: wrap;
    /* flex-wrap: nowrap; */
}

/* .platform-desktop .lex-toolbar {
    flex-wrap: wrap;
} */

.lex-toolbar .colour-swish {
    background-color: #666666;
    width: 22px;
    height: 8px;
    position: absolute;
    top: 24px;
    border-style: solid;
    border-width: 1px;
    border-color: var(--input-border-color);
    left: -1px;
}
