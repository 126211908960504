.sea-grid {
  display: flex;
  flex-wrap: wrap;
}
.sea-grid > div {
  min-height: 60px;
  padding-right: var(--grid-gutter);
  padding-bottom: var(--grid-row-spacing);
}
.sea-grid .w100 {
  width: 100%;
}
.sea-grid .w75 {
  width: 75%;
}
.sea-grid .w66 {
  width: 66.66%;
}
.sea-grid .w50 {
  width: 50%;
}
.sea-grid .w33 {
  width: 33.33%;
}
.sea-grid .w25 {
  width: 25%;
}
.sea-grid .capitalize {
  text-transform: capitalize;
}
.sea-grid > div.short {
  min-height: unset;
}

.end-info-line {
  background-color: var(--input-border-color);
  width: 100%;
  height: 1px;
  margin-top: 4px;
  margin-bottom: 20px;
  clear: both;
}
.end-info-line.sub {
  margin-top: -4px;
  background-color: rgba(255,255,255,0);
  border-width: 1px 0px 0px 0px;
  border-style: dashed;
  border-color: var(--input-border-color);
}

@media screen and (max-width: 430px) {
  .sea-modal .sea-grid .w66 {
    width: 50%;
  }
  .sea-modal .sea-grid .w33 {
    width: 50%;
  }
}
@media screen and (max-width: 340px) {
  .sea-modal .sea-grid .w75,
  .sea-modal .sea-grid .w66,
  .sea-modal .sea-grid .w50,
  .sea-modal .sea-grid .w33 {
      width: 100%;
  }
  .sea-modal .sea-grid .w25 {
      width: 50%;
  }
}
