.maintenance-completed-tasks-report .sea-row-history > div:nth-of-type(1) { /* When Completed */
    flex: 0 1 104px;
}
.maintenance-completed-tasks-report .sea-row-history > div:nth-of-type(2) { /* Type */
    flex: 0 1 104px;
}
.maintenance-completed-tasks-report .sea-row-history > div:nth-of-type(3) { /* Times Completed */
    flex: 0 1 104px;
}
.maintenance-completed-tasks-report .sea-row-history > div:nth-of-type(4) { /* Task */
    flex: 1.5 1 0;
}
.maintenance-completed-tasks-report .sea-row-history > div:nth-of-type(5) { /* Vessel */
    flex: 1 1 0;
    min-width: 69px;
}
.maintenance-completed-tasks-report.hide-vessel .sea-row-history > div:nth-of-type(5) { /* Vessel */
    display: none;
}
.maintenance-completed-tasks-report .sea-row-history > div:nth-of-type(6) { /* Critical */
    flex: 0 1 82px;
    text-align: center;
}

@media screen and (max-width: 650px) {
    .maintenance-completed-tasks-report .sea-row-history > div:nth-of-type(6) { /* Critical */
        flex: 0 1 40px;
    }
    .maintenance-completed-tasks-report .sea-row-history.headings-history > div:nth-of-type(6) { /* Critical Header */
        opacity: 0;
    }
}

@media screen and (max-width: 550px) {
    .maintenance-completed-tasks-report .sea-row-history > div::before { /* When Completed */
        width: 120px !important;
    }
    .maintenance-completed-tasks-report .sea-row-history > div:nth-of-type(1)::before {
        content: 'When Completed';
    }
    .maintenance-completed-tasks-report .sea-row-history > div:nth-of-type(2)::before {
        content: 'Type';
    }
    .maintenance-completed-tasks-report .sea-row-history > div:nth-of-type(3)::before {
        content: 'Times Completed';
    }
    .maintenance-completed-tasks-report .sea-row-history > div:nth-of-type(4)::before {
        content: 'Task';
    }
    .maintenance-completed-tasks-report .sea-row-history > div:nth-of-type(5)::before {
        content: 'Vessel';
    }
    .maintenance-completed-tasks-report .sea-row-history > div:nth-of-type(6) { /* Critical */
        display: block !important;
        padding: 0px;
    }
}
