.crew-certificates-archived .sea-row-history > div:nth-of-type(2) { /* Crew Member */
    flex: 0 1 150px;
}
.crew-certificates-archived .sea-row-history > div:nth-of-type(3) { /* Certificate */
    flex: 1 1 0;
}
.crew-certificates-archived .sea-row-history > div:nth-of-type(4) { /* Image */
    flex: 0 1 56px;
}
.crew-certificates-archived .sea-row-history > div:nth-of-type(5) { /* Restore */
    flex: 0 1 35px;
    padding: 8px 8px;
    cursor: pointer;
}
.crew-certificates-archived .sea-row-history > div:nth-of-type(6) { /* Trash */
    flex: 0 1 35px;
    padding: 8px 8px;
    cursor: pointer;
}


@media screen and (max-width: 550px) {
    .crew-certificates-archived .sea-card.sea-row-history > div::before {
        width: 50px;
    }

    .crew-certificates-archived .sea-row-history > div:nth-of-type(1),
    .crew-certificates-archived .sea-row-history > div:nth-of-type(2) {
        padding-right: 110px;
    }

    .crew-certificates-archived .sea-row-history > div:nth-of-type(1)::before {
        content: 'Date';
    }
    .crew-certificates-archived .sea-row-history > div:nth-of-type(2)::before {
        content: 'Crew';
    }
    .crew-certificates-archived .sea-row-history > div:nth-of-type(3)::before {
        content: 'Cert.';
    }

    .crew-certificates-archived .sea-row-history > div:nth-of-type(4),
    .crew-certificates-archived .sea-row-history > div:nth-of-type(5),
    .crew-certificates-archived .sea-row-history > div:nth-of-type(6) {
        height: 0px;
        padding: 0px;
    }

    .crew-certificates-archived .sea-row-history > div:nth-of-type(4) > .sea-file-image {
        position: absolute;
        top: 10px;
        right: 74px;
    }
    .crew-certificates-archived .sea-row-history > div:nth-of-type(5) > div {
        position: absolute;
        top: 10px;
        right: 40px;
    }
    .crew-certificates-archived .sea-row-history > div:nth-of-type(6) > div {
        position: absolute;
        top: 10px;
        right: 10px;
    }
}
