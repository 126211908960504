



/* Column settings for history table */
.training-task-history .sea-row-history > div:nth-of-type(1) { /* Date Completed */
    flex: 0 1 80px;
}
.training-task-history .sea-row-history > div:nth-of-type(2) { /* Completed By */
    flex: 1 1 0;
    cursor: pointer;
}
.training-task-history .sea-row-history > div:nth-of-type(3) { /* Trainer */
    flex: 0 1 150px;
}
.training-task-history .sea-row-history > div:nth-of-type(4) { /* Notes */
    flex: 1 1 0;
    cursor: pointer;
}


@media screen and (max-width: 590px) {
    .training-task-history .sea-row-history.headings-history {
        display: flex;
    }

    .training-task-history .sea-row-history > div:nth-of-type(3) { /* Trainer */
        display: none;
    }
}

@media screen and (max-width: 380px) {
    .training-task-history .sea-row-history > div:nth-of-type(4) { /* Notes */
        display: none;
    }
}
