.equipment-manual-documents {
    max-width: var(--max-table-width);
}

.equipment-manual-documents .sea-row > div:nth-of-type(1) { /* img */
    flex: 0 1 56px;
}
.equipment-manual-documents .sea-row > div:nth-of-type(2) { /* Document Name */
    flex: 1.5 1;
}


@media screen and (max-width: 640px) {
    .equipment-manual-documents.page-head {
        flex-direction: column;
    }
    .equipment-manual-documents.page-head .actions {
        justify-content: flex-start;
    }
}

@media screen and (max-width: 730px) {
    .equipment-manual-documents .sea-row > div:nth-of-type(4) { /* Equipment */ 
        display: none;
    }
}

@media screen and (max-width: 1000px) {
    .show-menu.desktop-view .equipment-manual-documents .sea-row > div:nth-of-type(4) { /* Equipment */ 
        display: none;
    }
}

@media screen and (max-width: 550px) {
    .equipment-manual-documents .sea-row.sea-card > div::before {
        width: 82px;
    }
    .equipment-manual-documents .sea-row > div:nth-of-type(3)::before {
        content: 'System';
    }
    .equipment-manual-documents .sea-row > div:nth-of-type(4) {
        display: block;
    }
    .equipment-manual-documents .sea-row > div:nth-of-type(4)::before {
        content: 'Equipment';
    }
}
