.sea-tags.sea-input {
  height: unset;
  min-height: var(--input-height);
  padding: 0px 8px 8px 0px;
}
.sea-tag {
  display: inline-block;
  position: relative;
  border-radius: 5px;
  background-color: var(--input-border-color);
  padding: 6px 34px 6px 8px;
  margin: 8px 0px 0px 8px;
  cursor: pointer;
}
.sea-tag .delete {
  position: absolute;
  top: -4px;
  right: -4px;
  padding: 7px 10px 3px 6px;
  opacity: 0.65;
  cursor: pointer;
}
.sea-tags .room {
  display: inline-block;
  width: 32px;
  height: 12px;
}

/* .sea-select-multi-box.tags {
  width: 250px;
  max-width: 100%;
} */
.sea-select-multi-box.tags .sea-input {
  min-height: 44px;
  /* box-shadow: rgb(0 0 0 / 50%) 0px 1px 6px 0px; */
}
.sea-select-multi-box.tags .sea-input input {
  padding-right: 8px;
}
.sea-select-multi-box.tags ion-list {
  padding-top: 0px;
}
