.vessel-certificates {
    max-width: var(--max-table-width);
}

.vessel-certificates .sea-row > div:nth-of-type(1) { /* img */
    flex: 0 1 56px;
}
.vessel-certificates .sea-row > div:nth-of-type(3) { /* cert number */
    flex: 0 1 150px;
}
.vessel-certificates .sea-row > div:nth-of-type(4) { /* issued by */
    flex: 0 1 150px;
}
.vessel-certificates .sea-row > div:nth-of-type(5) { /* expiry */
    flex: 0 1 85px;
}
.vessel-certificates .sea-row > div:nth-of-type(6) { /* status */
    flex: 0 1 150px;
}
.vessel-certificates .sea-row.headings > div:nth-of-type(6) {
    text-align: center;
}
.vessel-certificates  .sea-row > div:nth-of-type(7) { /* Email Reminder */
    flex: 0 1 95px;
}
.vessel-certificates  .sea-card.sea-row > div:nth-of-type(7) { /* Email Reminder */
    text-align: center;
}

@media screen and (max-width: 1000px) {
    .vessel-certificates.page-head {
        flex-direction: column;
    }
    .vessel-certificates.page-head .actions {
        justify-content: flex-start;
    }
}

@media screen and (max-width: 1020px) {
    .vessel-certificates .sea-row > div:nth-of-type(7) { /* Reminder */ 
        display: none;
    }
}
@media screen and (max-width: 920px) {
    .vessel-certificates .sea-row > div:nth-of-type(3) { /* Certificate # */ 
        display: none;
    }
}
@media screen and (max-width: 680px) {
    .vessel-certificates .sea-row > div:nth-of-type(4) { /* Issued By # */ 
        display: none;
    }
}

@media screen and (max-width: 1200px) {
    .show-menu.desktop-view .vessel-certificates .sea-row > div:nth-of-type(7) { /* Reminder */ 
        display: none;
    }
}
@media screen and (max-width: 1120px) {
    .show-menu.desktop-view .vessel-certificates .sea-row > div:nth-of-type(3) { /* Certificate # */ 
        display: none;
    }
}
@media screen and (max-width: 940px) {
    .show-menu.desktop-view .vessel-certificates .sea-row > div:nth-of-type(4) { /* Issued By # */ 
        display: none;
    }
}

@media screen and (max-width: 550px) {
    .vessel-certificates .sea-row.sea-card > div::before {
        width: 57px;
    }
    .vessel-certificates .sea-row > div:nth-of-type(3) {
        display: none; /* Hide Certificate # */
    }
    .vessel-certificates .sea-row > div:nth-of-type(4) {
        display: none; /* Hide Issued By # */
    }
    .vessel-certificates .sea-row > div:nth-of-type(5)::before {
        content: 'Expiry';
    }
    .vessel-certificates .sea-row > div:nth-of-type(6) {
        padding: 0px;
    }
    .vessel-certificates .sea-row > div:nth-of-type(7) {
        display: none; /* Hide Reminder # */
    }
}
