.sea-exporter-popover .popover-content {
    background: var(--ion-color-primary) !important;
}

.sea-exporter-popover .popover-arrow::after {
    background: var(--ion-color-primary) !important;
}

.sea-exporter-dropdown-item {
    background-color: var(--ion-color-primary);
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: center;
    gap: 10px;
    color: white;
    font-weight: 500;
    font-size: 14px;
    padding: 4px 20px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.sea-exporter-dropdown-item:hover {
    background-color: color-mix(in srgb, var(--ion-color-primary) 80%, transparent);
}
