.crew-particulars {
    max-width: var(--max-table-width);
}

.crew-particulars .sea-row > div:nth-of-type(1) { /* Name */
    flex: 1 1 0;
}
.crew-particulars .sea-row > div:nth-of-type(2) { /* Email */
    flex: 1.5 1 0;
}
.crew-particulars .sea-row > div:nth-of-type(3) { /* Position */
    flex: 0 1 145px;
}
.crew-particulars .sea-row > div:nth-of-type(4) { /* Inducted Date */
    flex: 0 1 135px;
}
.crew-particulars .sea-row > div:nth-of-type(5) { /* Login Access */
    flex: 0 1 120px;
}
.crew-particulars .sea-card.sea-row > div:nth-of-type(5) { /* Login Access */ 
    text-align: center;
}


.crew-archived .sea-row-history > div:nth-of-type(1) { /* Archived Modal > Date */
    flex: 0 1 135px;
}
.crew-archived .sea-row-history > div:nth-of-type(3) { /* Archived Modal > View profile */
    flex: 0 1 150px;
}


@media screen and (max-width: 540px) {
    .crew-particulars.page-head {
        flex-direction: column;
    }
    .crew-particulars.page-head .actions {
        justify-content: flex-start;
    }
}

@media screen and (max-width: 910px) {
    .crew-particulars .sea-row > div:nth-of-type(5) { /* Login Access */ 
        display: none;
    }
}
@media screen and (max-width: 760px) {
    .crew-particulars .sea-row > div:nth-of-type(4) { /* Inducted Date */
        display: none;
    }
}

@media screen and (max-width: 1160px) {
    .show-menu.desktop-view .crew-particulars .sea-row > div:nth-of-type(5) { /* Login Access */ 
        display: none;
    }
}
@media screen and (max-width: 1010px) {
    .show-menu.desktop-view .crew-particulars .sea-row > div:nth-of-type(4) { /* Inducted Date */
        display: none;
    }
}

@media screen and (max-width: 550px) {
    .crew-particulars .sea-row.sea-card > div::before {
        width: 74px;
    }
    .crew-particulars .sea-row > div:nth-of-type(2)::before {
        content: 'Email';
    }
    .crew-particulars .sea-row > div:nth-of-type(3)::before {
        content: 'Position';
    }
    .crew-particulars .sea-row > div:nth-of-type(4) {
        display: none; /* Hide Date Added */
    }
    .crew-particulars .sea-row > div:nth-of-type(5) {
        display: none; /* Hide Login Access */
    }
}
