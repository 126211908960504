.sync-spinner.big {
    width: 32px;
    height: 32px;
}
.sync-summary {
    padding-left: 8px;
    align-self: center;
}

.sync-progress-container {
    margin: 24px 0px 8px;
}
.sync-progress-container .info {
    padding: 0px 0px 10px 0px;
}
.sync-progress-container ion-progress-bar {
    height: 12px;
}
