.sea-sig-container {
    margin-top: -20px;
}
.sea-sig-canvas-box {
    position: relative;
    background-color: var(--ion-color-light);
    border-width: 1px;
    border-style: solid;
    border-color: var(--input-border-color);
    border-radius: var(--input-border-radius);
}
.sea-sig-canvas-box.has-error {
    border-color: var(--ion-color-danger);
    background-color: #fff5f5;
}
.sea-sig-canvas {
    position: relative;
}
.sea-label.sig {
    height: 41px;
    padding-top: 20px;
}
.sea-label .reset-button {
    display: inline-block;
    height: 40px;
    padding-left: 20px;
    padding-top: 19px;
    font-size: 13px;
    color: var(--ion-color-primary);
    text-decoration: underline;
}
.element.signature.view-only .sea-label .reset-button {
    display: none;
}
.element.signature.view-only .sea-sig-canvas-box {
    border-style: none;
    background-color: var(--bg-view-only);
}

@media screen and (max-width: 395px) {
    .sea-sig-canvas-box {
        position: relative;
        left: calc(50% - 176px); /* center box on page */
    }
    .sea-sig-container .reset-button {
        position: absolute;
        right: var(--grid-gutter);
        top: -20px;
    }
    .sea-sig-container .reset-button > span {
        background-color: #fff;
    }
    .custom-form-container .signature-cell {
        position: relative;
    }
    .custom-form-container .signature-cell .reset-button {
        top: 20px;
        right: 4px;
    }
}
