.user-permission-defaults .sea-link-button {
  margin: 10px !important;
  margin-left: 0px !important;
}

.offline-message {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
}
.offline-message > p {
  margin-left: 10px;
}