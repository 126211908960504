.drill-history .sea-row-history > div:nth-of-type(1) { /* Date */
    flex: 0 1 100px;
}

@media screen and (max-width: 550px) {
    .drill-history .sea-row-history > div:nth-of-type(1)::before {
        content: 'Date';
    }
    .drill-history .sea-row-history > div:nth-of-type(2)::before {
        content: 'Personnel';
        position: absolute;
        left: 10px;
    }
    .drill-history .sea-row-history > div:nth-of-type(2) {
        white-space: normal;
        padding-left: 90px;
    }
}