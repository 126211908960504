.sea-select-multi-links .sea-tabs-container.mini {
  padding: 0px 12px;
}
.sea-select-multi-links .sea-tabs-group.forms {
  margin-top: 0px;
}
.sea-select-multi-box ion-list {
  padding-top: 0px;
}

.sea-fullscreen-view.modal-box.crew-or-contact .center-box {
  width: 360px;
  max-width: 85%;
  height: 550px;
  background-color: #fff;
}

.modal-box.crew-or-contact .sea-select-multi-box {
  max-height: calc(100% - 110px);
}

.modal-box.crew-or-contact .sea-select-multi-links .sea-tabs-group.forms {
  white-space: nowrap;
}
