.sea-row-history.headings-history .interval-heading {
    flex: 0 1 100px
}
.sea-card.sea-row-history .interval {
    flex: 0 1 100px
}
.sea-row-history.headings-history .drill-heading {
    flex: 0 1 160px
}
.sea-card.sea-row-history .drill {
    flex: 0 1 160px
}