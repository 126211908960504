/* Updated styles for Ionic 6 */
.sea-modal {
    --height: 100%;
    --border-radius: 0px;
    --modal-padding-vertical: 16px;
    --modal-padding-horizontal: 32px;
    right: 0px;
}


.sea-modal::part(content) {
    background-color: #fff;
    z-index: 12;
}
.sea-modal::part(backdrop) {
    z-index: 11;
}

/* Modal widths */
.sea-modal.edit-rich-text {
    --modal-width: calc(1400px + var(--lex-toolbar-wiggle-room));
}
.sea-modal.rich-text {
    --modal-width: calc(1292px + var(--lex-toolbar-wiggle-room));
}
.sea-modal.level-2.edit-rich-text,
.sea-modal.level-3.edit-rich-text,
.sea-modal.level-4.edit-rich-text,
.sea-modal.level-5.edit-rich-text,
.sea-modal.level-2.rich-text,
.sea-modal.level-3.rich-text,
.sea-modal.level-4.rich-text,
.sea-modal.level-5.rich-text {
    --modal-width-shrink: 0px;
}
.sea-modal.extra-wide {
    --modal-width: 1040px;
}
.sea-modal.wide {
    --modal-width: 920px;
}
.sea-modal.semi-wide {
    --modal-width: 840px;
}
.sea-modal.standard {
    --modal-width: 740px;
}
.sea-modal.semi-thin {
    --modal-width: 620px;
}
.sea-modal.thin {
    --modal-width: 500px;
}

.sea-modal.level-1 {
    --modal-width-shrink: 0px;
}
.sea-modal.level-2 {
    --modal-width-shrink: 28px;
}
.sea-modal.level-3 {
    --modal-width-shrink: 56px;
}
.sea-modal.level-4 {
    --modal-width-shrink: 84px;
}
.sea-modal.level-5 {
    --modal-width-shrink: 112px;
}

.sea-modal::part(content) {
    position: absolute;
    right: 0px;
    --max-width: 100%;
    --width: calc(var(--modal-width) - var(--modal-width-shrink));
}

.sea-modal::part(backdrop) {
    --height: auto;
}

.sea-modal h2 {
    color: var(--ion-color-primary);
}
.sea-modal-x {
    display: inline-block;
    color: var(--ion-color-primary);
    width: 64px;
    height: 70px;
    padding: calc(var(--modal-padding-vertical) + 4px + var(--safe-inset-top)) 14px 0px 0px;
    cursor: pointer;
}
.sea-modal-pdf {
    display: inline-block;
    width: 70px;
    height: 70px;
    position: absolute;
    top: calc(14px + var(--safe-inset-top));
    right: 60px;
}

.sea-modal {
    --modal-title-height-fixed: calc(80px + var(--safe-inset-top));
    z-index: 13;
}
.sea-modal .title {
    padding: calc(var(--modal-padding-vertical) + var(--safe-inset-top)) 0px var(--modal-padding-vertical) var(--modal-padding-horizontal);
    flex-grow: 3;
}
.sea-modal .title.has-pdf-button {
    padding-right: 44px;
}
.sea-modal .title.fixed-title-height,
.sea-modal .title.fixed-title-height h2 {
    /* text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap; */
    overflow-y: auto;
    padding-right: 4px;
    position: relative;
}
.sea-modal .title.fixed-title-height {
    height: var(--modal-title-height-fixed);
}

.sea-modal-content {
    padding: var(--modal-padding-vertical) var(--modal-padding-horizontal) var(--modal-padding-vertical) var(--modal-padding-horizontal);
}
.sea-modal-content.no-padding {
    padding: 0px;
}
.sea-modal-content.no-padding .sea-fill-container {
    padding: 0px var(--modal-padding-horizontal) 0px var(--modal-padding-horizontal);
}
.sea-modal .reporting-data {
    padding: 12px var(--modal-padding-horizontal) 40px var(--modal-padding-horizontal);
}

.disable-sea-modal {
    background-color: rgba(255,255,255,0.75);
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 1000;
    pointer-events: none;
    opacity: 0;
    transition: opacity 1s;
}
.disable-sea-modal.active {
    opacity: 1.0;
    pointer-events: all;
}

/* other levels */
.sea-modal.level-2::part(content) {
    z-index: 22;
}
.sea-modal.level-2::part(backdrop) {
    z-index: 21;
    --backdrop-opacity: var(--ion-backdrop-opacity, 0.4);
}
.sea-modal.level-3::part(content) {
    z-index: 32;
}
.sea-modal.level-3::part(backdrop) {
    z-index: 31;
    --backdrop-opacity: var(--ion-backdrop-opacity, 0.4);
}
.sea-modal.level-4::part(content) {
    z-index: 42;
}
.sea-modal.level-4::part(backdrop) {
    z-index: 41;
    --backdrop-opacity: var(--ion-backdrop-opacity, 0.4);
}

.sea-modal-tabs-panel {
    padding-left: var(--modal-padding-horizontal);
}
.sea-modal-tabs-panel .sea-tabs-container {
    margin-bottom: 2px;
}
.sea-modal .sea-tabs-group {
    left: calc(-1 * var(--modal-padding-horizontal));
    padding-left: var(--modal-padding-horizontal);
    width: calc(100% + var(--modal-padding-horizontal));
}
.sea-modal .sea-tabs-fade.left {
    left: calc(-1 * var(--modal-padding-horizontal));
}
.sea-modal .sea-tabs-fade.right {
    right: 0px;
}
.sea-modal .end-bit > div {
    width: var(--modal-padding-horizontal);
}

.sea-modal-action-panel {
    min-height: calc(var(--modal-padding-vertical) + 70px);
}
.sea-modal-action-panel .top-line {
    background-color: var(--input-border-color);
    height: 1px;
}
.sea-modal-action-panel .actions {
    padding: var(--modal-padding-vertical) 0px 0px var(--modal-padding-horizontal);
}

.info-image {
    float: right;
    text-align: right;
    padding: 0px 0px 0px 32px;
}
.info-image img {
    max-height: 360px;
}
.sea-modal .info-image > div {
    max-width: 200px;
}

.view-only-label {
    font-style: italic;
    color: var(--label-text);
}

/* info-image within wide modals */
@media screen and (max-width: 650px) {
    .sea-modal .info-image {
        float: none;
        padding: 0px;
        text-align: left;
    }
    .sea-modal .info-image > div {
        max-width: 100%;
        text-align: left !important;
        padding-left: 0px !important;
    }
    .sea-modal .info-image .sea-file-image.tiny.other {
        margin: 0px 6px 6px 0px;
    }
}

@media screen and (max-width: 850px) {
    .sea-modal {
        --modal-padding-vertical: 8px;
        --modal-padding-horizontal: 16px;
    }
}

@media screen and (max-width: 550px) {
    .sea-modal h2 {
        font-size: 22px;
    }
}

@media screen and (max-width: 450px) {
    .sea-modal {
        --modal-padding-vertical: 6px;
        --modal-padding-horizontal: 14px;
    }
}