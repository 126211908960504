.job-report .sea-row-history > div:nth-of-type(1) { /* Priority */
    flex: 0 1 56px;
}
.job-report .sea-row-history > div:nth-of-type(2) { /* Task */
    flex: 1.5 1 0;
}
.job-report .sea-row-history > div:nth-of-type(3) { /* Vessel */
    flex: 1 1 0;
}
.job-report.hide-vessel .sea-row-history > div:nth-of-type(3) { /* Vessel */
    display: none;
}
.job-report .sea-row-history > div:nth-of-type(4) { /* next due */
    flex: 0 1 100px;
}
.job-report .sea-row-history > div:nth-of-type(5) { /* Critical */
    flex: 0 1 82px;
    text-align: center;
}

.sea-history-group-header {
    padding: 8px 4px 8px 4px;
    margin: 10px 0px 0px 0px;
    background-color: rgb(160, 151, 151);
    color: #fff;
    font-weight: bold;
}

.sea-history-group-header.top-spacer {
    margin-top: 20px;
}

@media screen and (max-width: 550px) {
    .job-report .sea-row-history > div:nth-of-type(3)::before {
        content: 'Vessel';
    }
    .job-report .sea-row-history > div:nth-of-type(4)::before {
        content: 'Due Date';
    }
    .job-report .sea-row-history > div:nth-of-type(5) { /* Critical */
        display: block !important;
        padding: 0px;
    }
}
