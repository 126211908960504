.safety-equipment-checks .sea-row-history > div:nth-of-type(1) { /* img */
    flex: 0 1 56px;
}
.safety-equipment-checks .sea-row-history > div:nth-of-type(2) { /* Name */
    flex: 1.5 1 0;
}
.safety-equipment-checks .sea-row-history > div:nth-of-type(3) { /* Interval */
    flex: 0 1 110px;
}
.safety-equipment-checks .sea-row-history > div:nth-of-type(4) { /* Next Check */
    flex: 0 1 100px;
}
.safety-equipment-checks .sea-row-history > div:nth-of-type(5) { /* Vessel */
    min-width: 72px;
    flex: 1 1 0;
}
.safety-equipment-checks.hide-vessel .sea-row-history > div:nth-of-type(5) { /* Vessel */
    display: none;
}
.safety-equipment-checks .sea-row-history > div:nth-of-type(6) { /* status */
    flex: 0 1 150px;
    text-align: center;
}
.safety-equipment-checks .sea-row-history > div:nth-of-type(7) { /* Critical */
    flex: 0 1 82px;
    text-align: center;
}

@media screen and (max-width: 800px) {
    .safety-equipment-checks .sea-row-history > div:nth-of-type(4) { /* next check */
        display: none;
    }
}

@media screen and (max-width: 550px) {
    .safety-equipment-checks .sea-row-history > div:nth-of-type(3)::before {
        content: 'Interval';
    }
    .safety-equipment-checks .sea-row-history > div:nth-of-type(5)::before {
        content: 'Vessel';
    }
    .safety-equipment-checks .sea-row-history > div:nth-of-type(7) { /* Critical */
        display: block !important;
        padding: 0px;
    }
}
