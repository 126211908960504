.sea-app-links img {
    height: 40px;
    padding: 8px 0px;
    margin-top: 14px;
    box-sizing: content-box;
}

.sea-modal-center {
    --width: 500px;
    --max-width: calc(100% - 16px);
    --height: 100px;
    --min-height: 330px;
    --border-radius: 10px;
}

.sea-modal-center .close-button {
    position: absolute;
    top: 0px;
    right: 0px;
    width: 44px;
    height: 44px;
    cursor: pointer;
    padding: 6px 5px 0px 0px;
    text-align: right;
    opacity: 0.6;
}

.sea-modal-center h2 {
    margin-bottom: 40px;
}

.sea-modal-center .ion-page {
    padding: 16px 16px;
}

.sea-modal-center ion-input {
    max-width: 300px;
    height: 80px;
    margin: auto;
    font-size: 400%;
    text-align: center;
    letter-spacing: 0.1em;
}

.sea-modal-center .submit-container {
    text-align: center;
}

.sea-modal-center .passcode-error {
    height: 60px;
    padding-top: 6px;
}
.sea-modal-center .passcode-error .sea-input-error {
    text-align: center;
}

.show-password-button {
    background: transparent;
    border: none;
    padding: 0;
    cursor: 'pointer';
    outline: 'inherit';
    height: var(--input-height);
    width: calc(var(--input-height) / 2);
    border-radius: 0px 5px 5px 0px;
    padding-right: 5px;
}

.show-password-button ion-icon {
    font-size: 20px;
    opacity: 0.8;
    color: var(--text-on-white);
}

@media screen and (max-width: 460px) {
    .sea-modal-center h2 {
        font-size: 18px;
        margin-bottom: 20px;
    }
}

@media screen and (max-width: 240px) {
    .sea-modal-center {
        --min-height: 375px;
    }
}
