:root {
    --lex-margin-x: 28px;
    --lex-margin-top: 50px;
    --lex-margin-bottom: 40px;
    --lex-color-off-page: var(--input-border-color);
    --lex-toolbar-wiggle-room: 60px;
    --sfdoc-editor-width: 940px;
    --sfdoc-margin-x: 32px;
    --sfdoc-margin-y: calc(var(--sfdoc-margin-x) * (40 / 32));
    --sfdoc-content-width: 756px;
    --sfdoc-page-width: calc(var(--sfdoc-content-width) + 64px);
}

@media screen and (max-width: 860px) {
    :root {
        --sfdoc-margin-x: 3vw;
    }
}

.company-plan .lex-page {
    --sfdoc-page-width: max(
        min(
            calc(var(--sfdoc-content-width) + var(--sfdoc-margin-x) + var(--sfdoc-margin-x)),
            calc(100vw - var(--side-width) - var(--page-padding-horizontal) - var(--page-padding-horizontal) - var(--scrollbar-width))
        ),
        340px
    );
}

.sea-modal-content .lex-page {
    /* max-width: calc(100vw - var(--modal-padding-horizontal) - var(--modal-padding-horizontal) - var(--scrollbar-width)); */
    --sfdoc-page-width: max(
        min(
            calc(var(--sfdoc-content-width) + var(--sfdoc-margin-x) + var(--sfdoc-margin-x)),
            calc(100vw - var(--modal-padding-horizontal) - var(--modal-padding-horizontal) - var(--scrollbar-width))
        ),
        340px
    );
}

.lex-editor-column .lex-page {
    min-width: var(--sfdoc-page-width);
}


.lex-page {
    padding: var(--sfdoc-margin-y) var(--sfdoc-margin-x);
    width: var(--sfdoc-page-width);
    max-width: var(--sfdoc-page-width);
    min-height: calc(var(--sfdoc-page-width) * (297 / 210));
    background-color: white;
    margin-bottom: 52px;
    border-radius: 10px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.34);
    unicode-bidi: isolate;
}

.lex-next-prev ion-icon {
    color: white;
    stroke-width: 24px;
    fill: var(--ion-color-primary);
}

.lex-shell {
    border-radius: 2px;
    position: relative;
    line-height: 1em;
}
.lex-scroller {
    min-height: 150px;
    border: 0;
    resize: none;
    cursor: text;
    display: flex;
    position: relative;
    outline: 0;
    overflow: unset;
    resize: vertical;
}
.lex-editor {
    flex: auto;
    position: relative;
}

.lex-content-editable-root {
    border: 0;
    font-size: 13px;
    font-family: var(--sea-font-family);
    display: block;
    position: relative;
    tab-size: 1;
    outline: 0;
    padding: var(--lex-margin-top) 0px var(--lex-margin-bottom);
    min-height: calc(100% - 16px);
}
.sea-modal-content .lex-content-editable-root {
    border-width: 0px;
}


.lex-editor, .lex-paragraph {
    line-height: 1.6;
}

.sfdoc-modal-content {
    background-color: var(--ion-color-light);
}


h1.lex-heading-h1 > span {
    cursor: text;
}

h1.lex-heading-h1 {
    font-size: 28px;
    font-weight: 500;
    letter-spacing: unset;
    color: #333;
}
h2.lex-heading-h2 {
    font-size: 22px;
    font-weight: 700;
    letter-spacing: unset;
    color: #555;
}
.lex-heading-h3 {
    font-size: 15px;
    font-weight: 700;
    letter-spacing: unset;
    color: #666;
    text-transform: none;
}
.lex-heading-h4 {
    font-size: 13px;
    font-weight: 700;
    letter-spacing: unset;
    color: #666;
}
.lex-heading-h5 {
    font-size: 11px;
    font-weight: 700;
    letter-spacing: unset;
    color: #666;
}
.lex-heading-h6 {
    font-size: 10px;
    font-weight: 700;
    letter-spacing: unset;
    color: #666;
}
.lex-list-ul li {
    padding: 0px 0px 4px 4px;
    margin-left: 16px;
}
.lex-list-ul li::marker {
    font-size: 15px;
}
.lex-list-ol li {
    padding: 0px 0px 4px 10px;
}

.lex-nested-listitem {
    list-style-type: none;
}
.lex-nested-listitem:before,
.lex-nested-listitem:after {
    display: none;
}

blockquote.lex-quote {
    margin-left: 16px;
    padding: 8px 0px 8px 16px;
    border-left-color: rgb(206, 208, 212);
    border-left-width: 4px;
    border-left-style: solid;

}



.lex-textBold {
    font-weight: 600;
}
.lex-textItalic {
    font-style: italic;
}
.lex-textUnderline {
    text-decoration: underline;
}
.lex-textStrikethrough {
    text-decoration: line-through;
}
.lex-textUnderlineStrikethrough {
    text-decoration: underline line-through;
}
.lex-container hr {
    background-color: #a0a0a0;
}

.lex-container .table-cell-action-button-container {
    position: absolute;
    top: 0;
    left: 6px;
    will-change: transform;
}

.lex-container .table-cell-action-button-container .table-cell-action-button {
    display: flex;
    height: 22px;
    width: 22px;
    background-color: #eee;
    justify-content: center;
    align-items: center;
    border: 0;
    position: relative;
    border-radius: 15px;
    color: #222;
    display: inline-block;
    cursor: pointer;
}

.lex-container table {
    table-layout: fixed;
    width: 100%;
    overflow-y: scroll;
    border-color: red;
}
.lex-container table.disable-selection {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.lex-container table.disable-selection span::selection {
    background-color: transparent;
}
.lex-container table.disable-selection br::selection {
    background-color: transparent;
}

.lex-container table td, .lex-container table th {
    text-align: start;
    border-style: solid;
    border-width: 1px;
    border-color: #bbb;
    min-width: 75px;
    vertical-align: top;
    padding: 6px 8px;
    position: relative;
    cursor: default;
    outline: none;
}
.lex-container table th {
    background-color: #eee;
    font-weight: 600;
}

.lex-container table .lex-paragraph {
    margin: 0;
    position: relative;
}


.lex-popover {
    position: fixed;
}
.lex-popover hr {
    background-color: #cccdd1;
}



.lex-editor span.lex-image {
    cursor: default;
    display: inline-block;
    position: relative;
    user-select: none;
}

.lex-editor .lex-image img {
    max-width: 100%;
    cursor: default;
}

.lex-editor .lex-image img.focused {
    outline: 2px solid rgb(60, 132, 244);
    user-select: none;
}

.lex-editor .lex-image img.focused.draggable {
    cursor: grab;
}

.lex-editor .lex-image img.focused.draggable:active {
    cursor: grabbing;
}

.lex-editor .lex-image .image-resizer {
    display: block;
    width: 7px;
    height: 7px;
    position: absolute;
    background-color: rgb(60, 132, 244);
    border: 1px solid #fff;
}

.lex-editor .lex-image .image-resizer.image-resizer-n {
    top: -6px;
    left: 48%;
    cursor: n-resize;
}

.lex-editor .lex-image .image-resizer.image-resizer-ne {
    top: -6px;
    right: -6px;
    cursor: ne-resize;
}

.lex-editor .lex-image .image-resizer.image-resizer-e {
    bottom: 48%;
    right: -6px;
    cursor: e-resize;
}

.lex-editor .lex-image .image-resizer.image-resizer-se {
    bottom: -2px;
    right: -6px;
    cursor: nwse-resize;
}

.lex-editor .lex-image .image-resizer.image-resizer-s {
    bottom: -2px;
    left: 48%;
    cursor: s-resize;
}

.lex-editor .lex-image .image-resizer.image-resizer-sw {
    bottom: -2px;
    left: -6px;
    cursor: sw-resize;
}

.lex-editor .lex-image .image-resizer.image-resizer-w {
    bottom: 48%;
    left: -6px;
    cursor: w-resize;
}

.lex-editor .lex-image .image-resizer.image-resizer-nw {
    top: -6px;
    left: -6px;
    cursor: nw-resize;
}





































.sfdoc-view {
    --sfdoc-sections-gutter: 18px;
    --sfdoc-sections-width: min(436px, calc(100vw - var(--side-width) - var(--sfdoc-page-width) - var(--page-padding-horizontal) - var(--page-padding-horizontal) - var(--scrollbar-width)) - var(--sfdoc-sections-gutter));
}
.sea-modal-content .sfdoc-view {
    --lex-margin-top: 0px;
    --lex-margin-x: 0px;
    --lex-color-off-page: white;
    --sfdoc-sections-width: calc(min(100vw, var(--modal-width)) - var(--sfdoc-page-width) - var(--modal-padding-horizontal) - var(--modal-padding-horizontal) - 32px);
}

.sfdoc.white-zone {
    max-width: var(--sfdoc-editor-width);
    background-color: unset;
}
.sfdoc.white-zone {
    padding-left: var(--sfdoc-sections-gutter);
}





.sfdoc-sections {
    /* padding-right: 20px; */
    max-width: var(--sfdoc-sections-width);
    width: var(--sfdoc-sections-width);
    flex: 0 1 var(--sfdoc-sections-width);
}
.sfdoc-sections h3 {
    text-transform: none;
    letter-spacing: normal;
    font-size: 13px;
    font-weight: 500;
    margin: 12px 0px;
    max-width: 316px;
}
.sfdoc-view.fixed .sfdoc-sections {
    z-index: 1;
    position: fixed;
    top: 0px;
    bottom: 0px;
    overflow-x: hidden;
    overflow-y: auto;
}
.company-plan .sfdoc-view.fixed .sfdoc-sections {
    top: calc(var(--header-bar-height) + var(--safe-inset-top));
}
.sea-modal-content .sfdoc-view.fixed .sfdoc-sections {
    top: var(--modal-title-height-fixed);
}
.sfdoc-view.fixed .document {
    margin-left: var(--sfdoc-sections-width);
}
.sfdoc-view .only-if-fixed {
    display: none;
    padding: 0px;
    max-height: 0px;
    overflow: hidden;
}
.sfdoc-view.fixed .only-if-fixed {
    display: inherit;
    padding: 8px;
    max-height: 100px;
}

.sfdoc-view {
    --lex-color-off-page: var(--bg-grey);
}
.standard-layout-content .sfdoc-view .lex-section-button {
    color: white;
    margin-right: 6px;
}
.standard-layout-content .sfdoc-view .lex-section-button.active {
    background-color: var(--ion-color-primary);
}
.standard-layout-content .sfdoc-view .lex-section-button:active {
    background-color: rgba(255,255,255,0.5);
    box-shadow: inset 0px 0px 5px #aaa;
}
.standard-layout-content .sfdoc-view .lex-section-button:hover {
    background-color: rgba(255,255,255,0.2);
    transition: background-color 0s;
}
.standard-layout-content .sfdoc-view .lex-section-button.active:hover {
    background-color: var(--ion-color-primary-tint);
    transition: background-color 0s;
}

.sfdoc-bar {
    display: none !important;
}
.company-plan .lex-next-prev {
    padding-top: 1px;
}
.sfdoc-bar .edit-buttons {
    flex: 1 1 0;
    padding: 0px 0px 0px 8px !important;
    margin: -2px 0px 0px 0px;
}
.company-plan .sfdoc-bar .edit-buttons {
    margin: 2px 0px 0px 0px;
}
.sea-modal-content .sfdoc-bar .button ion-icon {
    padding-top: 0px;
    margin-top: -3px !important;
}
.sea-modal-content .sfdoc-bar .button ion-button {
    margin-top: 6px;
}

.sfdoc-view .document {
    max-width: var(--sfdoc-content-width);
    min-width: 320px;
}
.sea-modal-content .sfdoc-view .document {
    padding-bottom: 80px;

}

.sfdoc-view .sea-select.grey-zone {
    color: var(--text-on-white) !important;
    height: 44px;
}

/* Heading Selector */
.sfdoc-view .sea-select.multi.rich-text-headings {
    height: 44px;
    margin-top: 4px;
}
.sfdoc-view .sfdoc-bar {
    height: 54px;
}
.sfdoc-view .sea-select.multi.rich-text-headings {
    max-width: calc(100vw - 112px - var(--page-padding-horizontal) - var(--page-padding-horizontal));
}
.sea-modal-content .sfdoc-view .sea-select.multi.rich-text-headings {
    margin-top: 0px;
    background-color: white;
    max-width: calc(100vw - 96px - var(--modal-padding-horizontal) - var(--modal-padding-horizontal) - var(--scrollbar-width));
}
.sea-modal-content .sfdoc-view.fixed .sea-select.multi.rich-text-headings {
    max-width: calc(100vw - 100px);
}
.sea-modal .title.fixed-title-height h2 {
    font-size: 22px;
    margin: -6px 0px 0px 0px;
}
.sea-modal .title.fixed-title-height::after {
    content: "";
    position: absolute;
    /* top: calc(var(--modal-title-height-fixed) - 20px); */
    /* top: 0px; */
    bottom: 0px;
    left: 0px;
    height: 22px;
    width: 100%;
    background: linear-gradient(
        to bottom,
        rgba(255, 255, 255, 00) 0%,
        rgba(255, 255, 255, 1) 75%,
        rgba(255, 255, 255, 1) 100%
    );
}


/* Compact mode */
/* Always compact if width < 1180px */
@media screen and (max-width: 1179px) {
    .sea-modal-content .sfdoc-view {
        --lex-margin-top: 20px;
    }
    .sfdoc-sections {
        display: none;
    }
    .sfdoc-bar {
        display: flex !important;
        transition: left var(--responsive-transition-easing) var(--responsive-transition-time);
    }
    .sfdoc-view.fixed .document {
        margin-left: 0px;
    }
    .sfdoc-view.fixed .sfdoc-bar {
        background: linear-gradient(90deg, #6d6f78 0px, var(--bg-grey) 30px);
        position: fixed;
        top: 0px;
        left: 0px;
        right: 0px;
        padding-left: var(--page-padding-horizontal);
        z-index: 1;
    }
    .standard-layout-content .sfdoc.white-zone {
        padding-left: 0px;
    }
    .company-plan .sfdoc-view.fixed .sfdoc-bar {
        top: calc(var(--header-bar-height) + var(--safe-inset-top));
        left: var(--side-width);
    }
    .sea-modal-content .sfdoc-view.fixed .sfdoc-bar {
        background: white;
        border-style: solid;
        border-color: var(--input-border-color);
        border-width: 0px 0px 1px 0px;
        top: var(--modal-title-height-fixed);
    }
    .sfdoc-view.fixed .document {
        position: relative;
        top: 54px;
    }
    .sea-modal-content .sfdoc.white-zone { 
        border-width: 0px 0px 0px 0px;
        padding-left: 0px;
    }
}

@media screen and (max-width: 1300px) {
    /* Compact if width < 1300px and side menu is just a bar */
    .show-menu .standard-layout-content .sfdoc-sections {
        display: none;
    }
    .show-menu .standard-layout-content .sfdoc.white-zone {
        padding-left: 0px;
    }
    .show-menu .standard-layout-content .sfdoc-bar {
        display: flex !important;
    }
    .show-menu .standard-layout-content .sfdoc-view.fixed .document {
        margin-left: 0px;
    }
    .show-menu .standard-layout-content .sfdoc-view.fixed .sfdoc-bar {
        background: linear-gradient(90deg, #6d6f78 0px, var(--bg-grey) 30px);
        position: fixed;
        top: 0px;
        left: 0px;
        padding-left: var(--page-padding-horizontal);
        z-index: 1;
    }
    .show-menu .standard-layout-content .sfdoc-view.fixed .document {
        position: relative;
        top: 54px;
    }
}

@media screen and (min-width: 1301px) and (max-width: 1400px) {
    /* Compact if width is >= 1301px and <= 1400px */
    .standard-layout-content .sfdoc-sections {
        display: none;
    }
    .standard-layout-content .sfdoc.white-zone {
        padding-left: 0px;
    }
    .standard-layout-content .sfdoc-bar {
        display: flex !important;
    }
    .standard-layout-content .sfdoc-view.fixed .document {
        margin-left: 0px;
    }
    .standard-layout-content .sfdoc-view.fixed .sfdoc-bar {
        background: linear-gradient(90deg, #6d6f78 0px, var(--bg-grey) 30px);
        position: fixed;
        top: calc(var(--header-bar-height) + var(--safe-inset-top));
        left: var(--side-width);
        padding-left: var(--page-padding-horizontal);
        z-index: 1;
    }
    .standard-layout-content .sfdoc-view.fixed .document {
        position: relative;
        top: 54px;
    }
}
