.sea-select-input {
  /*margin-top: 6px;*/
  max-height: 0px;
  overflow: hidden;
  transition: max-height 0.2s ease-in;
}
.sea-select-input.show {
  display: block;
  max-height: 60px;
}
