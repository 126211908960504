.sea-select::part(icon) {
    left: -10px;
    top: -2px;
    transform: scale(1.2);
    opacity: 0.4;
}

.element.view-only .sea-select::part(icon) {
    opacity: 0.1;
}

.sea-select::part(text) {
    padding-right: 6px;
}

.sea-select-popover::part(content) {
    --width: 300px;
}

.sea-select-popover.wide::part(content) {
    --width: 400px;
}

.sea-select.grey-zone {
    color: var(--ion-color-primary) !important;
    background-color: var(--ion-color-light);
    height: 40px;
    border-color: #babbc2;
    border-radius: var(--input-border-radius);
    align-self: center;
    margin: 4px 0px;
}
.sea-select.grey-zone::part(icon),
.sea-select.multi.grey-zone .select-icon {
    opacity: 0.8;
}

/* .sea-select-popover::part(container) {
    color: red !important;
    --ion-item-color: red;
} */

.sea-select-popover .sc-ion-select-popover {
    font-size: 13px;
    font-family: var(--sea-font-family);
    /*--padding-start: 0px;*/
}
/*
.sc-ion-select-popover.sc-ion-label-ios-h {
    padding-left: 16px;
}
*/
