.vessel-certificates-archived .sea-row-history > div:nth-of-type(1) { /* Date */
    flex: 0 1 85px;
}
.vessel-certificates-archived .sea-row-history > div:nth-of-type(2) { /* Name */
    flex: 1 1 0;
}
.vessel-certificates-archived .sea-row-history > div:nth-of-type(3) { /* Image */
    flex: 0 1 56px;
    padding: 0px 8px;
}
.vessel-certificates-archived .sea-row-history > div:nth-of-type(4) { /* Restore */
    flex: 0 1 35px;
    cursor: pointer;
    padding: 8px 8px;
}
.vessel-certificates-archived .sea-row-history > div:nth-of-type(5) { /* Trash */
    flex: 0 1 35px;
    cursor: pointer;
    padding: 8px 8px;
}

@media screen and (max-width: 550px) {
    .vessel-certificates-archived .sea-row-history.headings-history {
        display: flex;
    }
    .vessel-certificates-archived .sea-card.sea-row-history {
        flex-wrap: nowrap;
    }
    .vessel-certificates-archived .sea-card.sea-row-history > div {
        min-width: unset;
    }
}
