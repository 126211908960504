.sea-graph-spinner {
  min-height: 340px;
  max-width: 600px;
  transform: scale(0.75);
}
.sea-graph-spinner > IonSpinner {
  position: relative;
}
.svg-graph-container {
  opacity: 0;
  animation: graphIn 0.2s ease-out forwards;
  animation-delay: calc(var(--graph) * 100ms);
}
.svg-graph-container.dashboard {
  /* display: inline-block; */
  max-width: 100%;
}
.svg-graph-container.modal {
  padding: 0px var(--modal-padding-horizontal) 0px var(--modal-padding-horizontal);
  /* border-width: 1px 1px 1px 1px;
  border-style: solid;
  border-color: #eee; */
  /* background-color: #eee; */
}
@keyframes graphIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
  }
}

.svg-graph {
  --graph-transition: all 0.2s ease-in-out;
  font-family: var(--sea-font-family);
  color: white;
  user-select: none;
  transition: var(--graph-transition);
}

.svg-graph.light {
  color: var(--text-on-white);
}

.svg-graph .container {
  fill: var(--bg-header);
  width: 100%;
  height: 100%;
}

.svg-graph.light .container {
  /* fill: var(--ion-color-light); */
  fill: white;
  /* fill: transparent; */
}

.svg-graph .title {
  font-size: 18px;
  font-weight: 600;
}
.svg-graph .sub-title {
  font-size: 13px;
  font-weight: 400;
  opacity: 0.8;
}
.svg-graph .label text {
  font-size: 13px;
  font-weight: 400;
}
.svg-graph .pie-value {
  font-size: 14px;
  font-weight: 600;
  text-anchor: middle;
}
.svg-graph .center {
  text-anchor: middle;
}
.svg-graph .pie-value.percentage {
  font-size: 11px;
  font-weight: 400;
  opacity: 0.9;
}

.svg-graph .pie-piece {
  transition: var(--graph-transition);
}
.svg-graph .pie-piece .in {
  opacity: 0;
  animation: pieIn 0.4s ease-out forwards;
  animation-delay: calc(var(--graph) * 100ms + 100ms + var(--order) * 50ms);
}
@keyframes pieIn {
  from {
    opacity: 0;
    transform: translate(var(--hover-move-x),var(--hover-move-y));
  }
  to {
    opacity: 1;
  }
}
.svg-graph .pie-piece.active {
  transform: translate(var(--hover-move-x),var(--hover-move-y));
}
.svg-graph .pie-piece.active .fill {
  /* -webkit-appearance: none;
  -webkit-filter: drop-shadow(0px 0px 8px rgba(0, 0, 0, .5)); */
  -webkit-svg-shadow: 0px 0px 6px rgba(0, 0, 0, 0.5);
  filter: drop-shadow(0px 0px 6px rgba(0, 0, 0, .5));
  /* stroke: white;
  stroke-width: 2px; */
}
.svg-graph.light .pie-piece.active .fill {
  /* -webkit-filter: drop-shadow(0px 0px 6px rgba(0, 0, 0, .5)); */
  -webkit-svg-shadow: 0px 0px 6px rgba(0, 0, 6px, 0.5);
  filter: drop-shadow(0px 0px 6px rgba(0, 0, 0, .5));
}

.svg-graph .label {
  transition: var(--graph-transition);
}
.svg-graph .label .in {
  opacity: 0;
  animation: labelIn 0.2s ease-out forwards;
  /* animation-delay: calc(var(--graph) * 100ms + 100ms + var(--order) * 50ms); */
  animation-delay: calc(var(--graph) * 100ms + 100ms + var(--order) * 0ms);
}
.svg-graph .label.active {
  transform: translateX(8px);
}
.svg-graph .label.active rect {
  stroke: white;
  stroke-width: 2px;
}
.svg-graph.light .label.active rect {
  stroke: var(--text-on-white);
}
@keyframes labelIn {
  from {
    opacity: 0;
    transform: translateX(16px);
  }
  to {
    opacity: 1;
  }
}

.svg-graph .active-name {
  text-anchor: middle;
  font-size: 13px;
  font-weight: 500;
  color: white;
  /* text-shadow: 0px 0px 6px black; */
}
.svg-graph.light .active-name {
  color: var(--text-on-white);
  /* text-shadow: 0px 0px 6px rgba(0,0,0,0.4); */
}
.svg-graph .active-name.percentage {
  font-size: 13px;
  font-weight: 500;
}
