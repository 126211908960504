.crew-contacts {
    max-width: var(--max-table-width);
}

.crew-contacts  .sea-row > div:nth-of-type(1) { /* Name # */
    flex: 0.8 1;
}
.crew-contacts  .sea-row > div:nth-of-type(2) { /* Company # */
    flex: 0.8 1;
}
.crew-contacts  .sea-row > div:nth-of-type(3) { /* Contact # */
    flex: 0 1 125px;
}


@media screen and (max-width: 340px) {
    .crew-contacts.page-head {
        flex-direction: column;
    }
    .crew-contacts.page-head .actions {
        justify-content: flex-start;
    }
}

@media screen and (max-width: 1020px) {
    .crew-contacts .sea-row > div:nth-of-type(5) { /* Address */
        display: none;
    }
}
@media screen and (max-width: 760px) {
    .crew-contacts .sea-row > div:nth-of-type(4) { /* Email */
        display: none;
    }
}

@media screen and (max-width: 1220px) {
    .show-menu.desktop-view .crew-contacts .sea-row > div:nth-of-type(5) { /* Address */
        display: none;
    }
}
@media screen and (max-width: 1050px) {
    .show-menu.desktop-view .crew-contacts .sea-row > div:nth-of-type(4) { /* Email */
        display: none;
    }
}

@media screen and (max-width: 550px) {
    .crew-contacts .sea-row.sea-card > div::before {
        width: 75px;
    }
    .crew-contacts .sea-row > div:nth-of-type(2)::before {
        content: 'Company';
    }
    .crew-contacts .sea-row > div:nth-of-type(3) {
        display: none; /* Hide Contact # */
    }
    .crew-contacts .sea-row > div:nth-of-type(4) {
        display: none; /* Hide Email */
    }
    .crew-contacts .sea-row > div:nth-of-type(5) {
        display: none; /* Hide Address */
    }
}
