.risks-history .sea-row-history > div:nth-of-type(2) { /* By */
    flex: 0 1 150px;
}
.risks-history .sea-row-history > div:nth-of-type(3) { /* Notes */
    flex: 1 1;
}
.risks-history .sea-row-history > div:nth-of-type(4) { /* Image */
    flex: 0 1 56px;
}


@media screen and (max-width: 550px) {
    .risks-history .sea-card.sea-row-history > div::before {
        width: 60px;
    }

    .risks-history .sea-row-history > div:nth-of-type(1),
    .risks-history .sea-row-history > div:nth-of-type(2) {
        padding-right: 44px;
    }
    .risks-history .sea-row-history > div:nth-of-type(1)::before {
        content: 'Date';
    }
    .risks-history .sea-row-history > div:nth-of-type(2)::before {
        content: 'By';
    }
    .risks-history .sea-row-history > div:nth-of-type(3)::before {
        content: 'Notes';
    }
    .risks-history .sea-row-history > div:nth-of-type(4) {
        height: 0px;
        padding: 0px;
    }
    .risks-history .sea-row-history > div:nth-of-type(4) > .sea-file-image {
        position: absolute;
        top: 10px;
        right: 8px;
    }
}
