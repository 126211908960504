.maintenance-history {
    max-width: var(--max-table-width);
}

.maintenance-history .sea-row > div:nth-of-type(1) { /* date */
    flex: 0 1 85px;
}
.maintenance-history .sea-row > div:nth-of-type(2) { /* engine hours */
    flex: 0 1 80px;
    text-align: center;
}
.maintenance-history .sea-row > div:nth-of-type(6) { /* type */
    flex: 0 1 105px;
}
.maintenance-history .sea-row > div:nth-of-type(7) { /* completed by */
    flex: 0 1 150px;
}

.columns.filters {
    margin: 15px 0 0px;
    align-items: center;
}

.columns.filters > div { 
    padding-right: 10px;
}

@media screen and (max-width: 645px) {
    .maintenance.page-head {
        flex-direction: column;
    }
    .maintenance.page-head .actions {
        justify-content: flex-start;
    }
}

@media screen and (max-width: 980px) {
    .maintenance-history .sea-row > div:nth-of-type(2) { /* Engine Hours */ 
        display: none;
    }
    .maintenance-history .sea-row > div:nth-of-type(6) { /* Type */ 
        display: none;
    }
}
@media screen and (max-width: 880px) {
    .maintenance-history .sea-row > div:nth-of-type(7) { /* Completed By */ 
        display: none;
    }
}
@media screen and (max-width: 670px) {
    .maintenance-history .sea-row > div:nth-of-type(3) { /* System */ 
        display: none;
    }
}

@media screen and (max-width: 1120px) {
    .show-menu.desktop-view .maintenance-history .sea-row > div:nth-of-type(2) { /* Engine Hours */ 
        display: none;
    }
    .maintenance-history .sea-row > div:nth-of-type(6) { /* Type */ 
        display: none;
    }
}
@media screen and (max-width: 1000px) {
    .show-menu.desktop-view .maintenance-history .sea-row > div:nth-of-type(6) { /* Type */ 
        display: none;
    }
    .show-menu.desktop-view .maintenance-history .sea-row > div:nth-of-type(7) { /* Completed By */ 
        display: none;
    }
}
@media screen and (max-width: 920px) {
    .show-menu.desktop-view .maintenance-history .sea-row > div:nth-of-type(3) { /* System */ 
        display: none;
    }
}

@media screen and (max-width: 550px) {
    .maintenance-history .sea-row.sea-card > div::before {
        width: 88px;
    }
    .maintenance-history .sea-row > div:nth-of-type(1)::before {
        content: 'Date';
    }
    .maintenance-history .sea-row > div:nth-of-type(2) {
        display: none; /* Hide Engine Hours */
    }
    .maintenance-history .sea-row > div:nth-of-type(3) {
        display: none; /* Hide System */
    }
    .maintenance-history .sea-row > div:nth-of-type(4)::before {
        content: 'Equipment';
    }
    .maintenance-history .sea-row > div:nth-of-type(5)::before {
        content: 'Task';
    }
    .maintenance-history .sea-row > div:nth-of-type(7) {
        display: block; /* Completed By */
    }
    .maintenance-history .sea-row > div:nth-of-type(7)::before {
        content: 'Completed';
    }
}
