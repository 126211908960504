.sea-windy-fullscreen {
    z-index: 5000;
    position: fixed;
    right: 0px;
    bottom: 0px;
    left: 0px;
    background-color: #666;
    --modal-padding-horizontal: 0px;
}
.sea-windy-fullscreen.conceal {
    transition: all 0.2s;
    top: 32px;
}
.sea-windy-fullscreen.reveal {
    transition: all 0.2s;
    top: 0px;
}

.platform-ios .sea-windy-fullscreen #windy #logo-wrapper #logo {
    top: calc(-5px + var(--safe-inset-top)) !important;
}

.sea-windy-fullscreen .sea-modal-x {
    position: absolute;
    right: 0px;
    top: 0px;
    color: #666;
    padding: 10px 0px 8px 8px;
    height: 48px;
    width: 57px;
    z-index: 20000;
}
.sea-windy-fullscreen .sea-modal-x ion-icon {
    box-shadow: 0 0 4px 0 black;
    border-radius: 1em;
    background-color: #e5e5e5;
}
.sea-windy-fullscreen .sea-modal-x {
    top: var(--safe-inset-top);
}

.sea-windy-fullscreen .windy-layer-buttons {
    right: 52px;
    top: calc(2px + var(--safe-inset-top));
}

.sea-windy-fullscreen #windy {
    height: calc(100vh - 0px) !important;
    max-height: calc(100vh - 0px) !important;
}
