.cbl-bg {
    position: absolute;
    background: var(--bg-header);
}
.cbl-centered {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 90%;
    max-width: 400px;
    transform: translate(-50%, -50%);
    text-align: center;
}
.cbl-box.form {
    background-color: #fff;
    padding: 25px 50px;
    border-radius: 10px;
    box-shadow: 0px 30px 40px rgba(0, 0, 0, 0.15);
}
.cbl-box.message {
    color: white;
    font-family: var(--sea-font-family);
    line-height: 1.4em;
    font-size: 120%;
}
.cbl-box p {
    margin-block-start: 2em;
}
.cbl-logo {
    display: block;
    width: 300px;
    max-width: 90%;
    padding-bottom: 30px;
    margin: auto;
}

@media only screen and (max-width: 600px) {
    .cbl-box {
        padding: 15px 20px;
    }
    .cbl-logo {
        padding-bottom: 15px;
    }
}
@media only screen and (max-width: 380px) {
    .cbl-box.form {
        padding: 16px 20px;
    }
}