.hover-text {
  font-size: 15px;
}
.hover-info {
  pointer-events: none;
}
.hover-info .box {
  /* -webkit-filter: drop-shadow(0px 4px 6px rgba(0, 0, 0, .5)); */
  -webkit-svg-shadow: 0px 4px 6px rgba(0, 0, 0, 0.5);
  filter: drop-shadow(0px 4px 6px rgba(0, 0, 0, .5));
}

.hover-info .animate {
  opacity: 0;
  animation: hoverBoxIn 0.25s ease-out forwards;
}
@keyframes hoverBoxIn {
  from {
    opacity: 0;
    transform: translateY(8px);
  }
  50% {
    opacity: 1;
  }
  to {
    opacity: 1;
    transform: translateY(0px);
  }
}
