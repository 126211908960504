

.sea-form-has-errors {
  color: var(--ion-color-danger);
  font-weight: 600;
  max-height: 0;
  opacity: 0;
  transition: all 0.2s;
  position: relative;
  top: 8px;
}

.sea-form-has-errors > div {
  padding: 4px 0px 0px 4px;
}

.sea-form-has-errors.active {
  max-height: 50px;
  opacity: 1.0;
  top: 0px;
}
