.sea-alert {
    --backdrop-opacity: 0.4;
    font-size: 13px;
    line-height: 1.5em;
}
.sea-alert::part(content),
.sea-alert::part(backdrop) {
    position: absolute;
    border-radius: 10px;
    --max-width: 100%;
    --max-height: 100%;
}

.sea-alert.pdf::part(content),
.sea-alert.pdf::part(backdrop),
.sea-alert.csv::part(content),
.sea-alert.csv::part(backdrop) {
    --width: 460px;
    --height: 260px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
}

.sea-alert.links::part(content),
.sea-alert.links::part(backdrop) {
    --width: 460px;
    --height: 260px;
}

.sea-alert .alert-container {
    width: 100%;
    height: 100%;
    display: flex;
}
.sea-alert .alert-container > div {
    align-self: center;
    text-align: center;
    flex-grow: 1;
    padding: 20px;
}

.sea-alert .alert-close {
    position: absolute;
    width: 44px;
    height: 44px;
    top: 0px;
    right: 0px;
    text-align: center;
    padding: 8px;
    cursor: pointer;
    color: var(--ion-color-primary);
}