.files-admin .columns {
    column-gap: 4px;
}

.files-admin input,
.files-admin ion-input,
.files-admin .sea-input.sea-select {
    height: 40px;
    max-width: 300px;
}

.files-admin .sea-label {
    color: white;
    height: 16px;
}

.file-info .sea-card {
    min-height: 40px;
}

.file-info .sea-row > div {
    font-family: monospace;
}

.file-info .sea-row > div:nth-of-type(1) { /* id */
    flex: 0 1 170px;
}
.file-info .sea-row > div:nth-of-type(2) { /* when */
    flex: 0 1 150px;
}
.file-info .sea-row > div:nth-of-type(3) { /* name */
    flex: 1 1 0;
}
.file-info .sea-row > div:nth-of-type(4) { /* collection */
    flex: 1 1 0;
}
.file-info .sea-row > div:nth-of-type(5) { /* licensee */
    flex: 1 1 0;
}
.file-info .sea-row > div:nth-of-type(6) { /* state */
    flex: 0 1 150px;
    text-align: center;
}
.file-info .sea-row > div:nth-of-type(7) { /* ext */
    flex: 0 1 75px;
}
.file-info .sea-row > div:nth-of-type(8) { /* type */
    flex: 0 1 100px;
}
.file-info .sea-row > div:nth-of-type(9) { /* original bytes */
    flex: 0 1 100px;
    text-align: right;
}
.file-info .sea-row > div:nth-of-type(10) { /* optimised bytes */
    flex: 0 1 100px;
    text-align: right;
}
.file-info .sea-row > div:nth-of-type(11) { /* thumbnail bytes */
    flex: 0 1 100px;
    text-align: right;
}
.file-info .sea-row > div:nth-of-type(12) { /* problems */
    flex: 1.5 1 0;
}


.file-problems.columns {
    margin-top: 2px;
    max-width: 1200px;
}
.file-problems.columns > div:nth-of-type(2) {
    padding: 12px 8px 12px 8px;
    flex: 0 1 220px;
    font-weight: bold;
}
.file-problems.columns > div:nth-of-type(3) {
    padding: 12px 8px 12px 8px;
    flex: 1 1 0;
}
.file-problems.columns > div:nth-of-type(4) {
    padding: 12px 8px 12px 8px;
    flex: 1 1 0;
}
