.custom-forms .sea-card.sea-row-history.clickable {
    cursor: pointer;
}
.custom-forms .sea-row-history > div:nth-of-type(3) {
    display: none;
}
.custom-forms.has-custom-value .sea-row-history > div:nth-of-type(3) { /* custom value */
    display: block;
    flex: 1.3 1 0;
}
.custom-forms .sea-row-history > div:nth-of-type(4) { /* version (date) */
    flex: 0 1 85px;
}
.custom-forms .sea-row-history > div:nth-of-type(5) { /* Vessels (optional) */
    flex: 1 1;
}
.custom-forms .sea-row-history > div:nth-of-type(6) { /* Personnel (optional) */
    flex: 1 1;
}



@media screen and (max-width: 550px) {
    .custom-forms .sea-card.sea-row-history > div::before {
        width: 115px;
    }

    .custom-forms .sea-row-history > div:nth-of-type(1)::before {
        content: 'Date';
    }
    .custom-forms .sea-row-history > div:nth-of-type(2)::before {
        content: 'Completed By';
    }
    .custom-forms .sea-row-history > div:nth-of-type(3) {
        white-space: normal;
        padding-left: 115px;
    }
    .custom-forms .sea-row-history > div:nth-of-type(3)::before {
        content: attr(data-label);
        width: 110px;
        white-space: nowrap;
        overflow-x: hidden;
        text-overflow: ellipsis;
        position: absolute;
        left: 0px;
    }
    /* .custom-forms .sea-row-history > div:nth-of-type(4) {
        display: none;
    } */
    .custom-forms .sea-row-history > div:nth-of-type(4)::before {
        content: 'Version';
    }
    .custom-forms .sea-row-history > div:nth-of-type(5)::before {
        content: 'Vessels';
    }
    .custom-forms .sea-row-history > div:nth-of-type(6)::before {
        content: 'Personnel';
    }
}
