.custom-form-templates .sea-row-history {
    cursor: pointer;
}

.custom-form-templates .sea-card.sea-row-history > div:nth-of-type(1) {
    flex: 1 1 0;
    padding: 4px 8px;
}
.custom-form-templates .sea-card.sea-row-history > div:nth-of-type(2) { /* Arrow right */
    flex: 0 1 50px;
    padding: 4px 8px;
}
