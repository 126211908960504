.dangerous-goods-register {
    max-width: var(--max-table-width);
}

.dangerous-goods-register .sea-row > div:nth-of-type(1) { /* img */
    flex: 0 1 56px;
}
.dangerous-goods-register .sea-row > div:nth-of-type(2) { /* Chemical */
    flex: 1.3 1;
}
.dangerous-goods-register .sea-row > div:nth-of-type(3) { /* Vessel */
    flex: 0 1 120px;
}
.dangerous-goods-register.hide-vessel .sea-row > div:nth-of-type(3) { /* Vessel */
    display: none;
}
.dangerous-goods-register .sea-row > div:nth-of-type(4) { /* Quantity */
    flex: 0 1 90px;
}
.dangerous-goods-register .sea-row > div:nth-of-type(6) { /* Hazardous */
    flex: 0 1 110px;
}
.dangerous-goods-register .sea-row > div:nth-of-type(7) { /* Class */
    flex: 0 1 90px;
}
.dangerous-goods-register .sea-row > div:nth-of-type(8) { /* MSDS */
    flex: 0 1 60px;
}

.dangerous-goods-register .sea-row > div:nth-of-type(9) { /* status */
    flex: 0 1 150px;
}

.dangerous-goods-register .sea-row.headings > div:nth-of-type(9) {
    text-align: center;
}

@media screen and (max-width: 635px) {
    .dangerous-goods-register.page-head {
        flex-direction: column;
    }
    .dangerous-goods-register.page-head .actions {
        justify-content: flex-start;
    }
}

@media screen and (max-width: 900px) {
    .dangerous-goods-register .sea-row > div:nth-of-type(7) { /* DG Class */ 
        display: none;
    }
}
@media screen and (max-width: 1010px) {
    .dangerous-goods-register.show-vessel .sea-row > div:nth-of-type(7) { /* DG Class */ 
        display: none;
    }
}
@media screen and (max-width: 710px) {
    .dangerous-goods-register .sea-row > div:nth-of-type(6) { /* Hazadous */ 
        display: none;
    }
}
@media screen and (max-width: 820px) {
    .dangerous-goods-register.show-vessel .sea-row > div:nth-of-type(6) { /* Hazadous */ 
        display: none;
    }
}

@media screen and (max-width: 1060px) {
    .show-menu.desktop-view .dangerous-goods-register .sea-row > div:nth-of-type(7) { /* DG Class */ 
        display: none;
    }
}
@media screen and (max-width: 1170px) {
    .show-menu.desktop-view .dangerous-goods-register.show-vessel .sea-row > div:nth-of-type(7) { /* DG Class */ 
        display: none;
    }
}
@media screen and (max-width: 960px) {
    .show-menu.desktop-view .dangerous-goods-register .sea-row > div:nth-of-type(6) { /* Hazadous */ 
        display: none;
    }
}
@media screen and (max-width: 1070px) {
    .show-menu.desktop-view .dangerous-goods-register.show-vessel .sea-row > div:nth-of-type(6) { /* Hazadous */ 
        display: none;
    }
}

@media screen and (max-width: 550px) {
    .dangerous-goods-register .sea-row.sea-card > div::before {
        width: 74px;
    }
    .dangerous-goods-register .sea-row .has-msds-files {
        padding-right: 44px;
    }
    .dangerous-goods-register .sea-row .has-status {
        padding-right: 88px;
    }
    .dangerous-goods-register .sea-row .has-both {
        padding-right: 132px;
    }
    .dangerous-goods-register .sea-row > div:nth-of-type(3)::before {
        content: 'Vessel';
    }
    .dangerous-goods-register .sea-row > div:nth-of-type(4)::before {
        content: 'Quantity';
    }
    .dangerous-goods-register .sea-row > div:nth-of-type(5) {
        display: none; /* Hide Location */
    }
    .dangerous-goods-register .sea-row > div:nth-of-type(6) {
        display: none; /* Hide Hazadous */
    }
    .dangerous-goods-register .sea-row > div:nth-of-type(7) {
        display: none; /* Hide DG Class */
    }
    /* .dangerous-goods-register .sea-row > div:nth-of-type(7)::before {
        content: 'SDS';
    } */
    .dangerous-goods-register .sea-row > div:nth-of-type(8) {
        height: 0px;
        padding: 0px;
    }
    .dangerous-goods-register .sea-row > div:nth-of-type(8) > .sea-file-image {
        position: absolute;
        top: 10px;
        right: 8px;
    }
    .dangerous-goods-register .sea-row > div:nth-of-type(9) {
        padding: 0px;
    }
    .dangerous-goods-register .sea-row .msds-files .sea-status {
        right: 54px;
        top: 10px;
    }
}
