.sea-file-image {
    cursor: pointer;
    /* background-color: var(--ion-color-light); */
}
.sea-file-image.tiny,
.sea-file-image.tiny2 {
    display: block; /* removes descender whitespace below img */
    width: var(--tiny-thumb-size);
    height: var(--tiny-thumb-size);
    min-width: var(--tiny-thumb-size);
    min-height: var(--tiny-thumb-size);
    border-radius: 6px;
    object-fit: cover;
    overflow: hidden;
}
.element.files.view-only .sea-file-image.tiny,
.element.files.view-only .sea-file-image.tiny2 {
    width: 100px;
    height: 100px;
    min-width: 100px;
    min-height: 100px;
}
.sea-file-image.tiny.other,
.sea-file-image.tiny2.other {
    display: inline-block;
    margin: 0px 0px 6px 6px;
}
.sea-file-image.medium {
    /* min-width: 200px;
    min-height: 200px;
    width: 200px;
    height: 200px; */
    width: 100%;
    max-width: 200px;
    border-radius: 6px;
    object-fit: scale-down;
}
.sea-file-image.medium.file {
    max-width: 100px;
}
.sea-file-image.medium.vessel {
    min-width: 150px;
    min-height: 120px;
    max-width: unset !important;
    width: 150px;
    height: 120px;
    object-fit: cover;
}
.sea-file-image.fade {
    opacity: 0;
    transition: opacity 0.15s;
}