.safety-equipment-items .sea-row-history > div:nth-of-type(1) { /* img */
    flex: 0 1 56px;
}
.safety-equipment-items .sea-row-history > div:nth-of-type(2) { /* Name */
    flex: 1.5 1 0;
}
.safety-equipment-items .sea-row-history > div:nth-of-type(3) { /* Service Expiry */
    flex: 0 1 100px;
}
.safety-equipment-items .sea-row-history > div:nth-of-type(4) { /* Vessel */
    min-width: 72px;
    flex: 1 1 0;
}
.safety-equipment-items.hide-vessel .sea-row-history > div:nth-of-type(4) { /* Vessel */
    display: none;
}
.safety-equipment-items .sea-row-history > div:nth-of-type(5) { /* status */
    flex: 0 1 150px;
}
.safety-equipment-items .sea-row-history.headings-history > div:nth-of-type(5) {
    text-align: center;
}
.safety-equipment-items .sea-row-history > div:nth-of-type(6) { /* Critical */
    flex: 0 1 82px;
    text-align: center;
}

@media screen and (max-width: 550px) {
    .safety-equipment-items .sea-row-history > div:nth-of-type(3)::before {
        width: 100px !important;
        content: 'Service/Expiry';
    }
    .safety-equipment-items .sea-row-history > div:nth-of-type(4)::before {
        width: 100px !important;
        content: 'Vessel';
    }
    .safety-equipment-items .sea-row-history > div:nth-of-type(6) { /* Critical */
        display: block !important;
        padding: 0px;
    }
}
