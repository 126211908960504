
.report-settings.columns.dashboard {
    padding: 12px 0px 20px 0px;
    column-gap: 10px;
    row-gap: 16px;
}
.report-settings.modal {
    padding: 12px calc(var(--modal-padding-horizontal) - var(--grid-gutter)) 20px var(--modal-padding-horizontal);
    column-gap: 14px;
    row-gap: 20px;
}

.report-settings.columns > div {
    flex: 1 1;
    max-width: min(300px, 50%);
}

@media screen and (max-width: 650px) {
    .report-settings .sea-grid .w66 {
      width: 50%;
    }
    .report-settings .sea-grid .w33 {
      width: 50%;
    }
  }
  @media screen and (max-width: 430px) {
    .report-settings .sea-grid .w75,
    .report-settings .sea-grid .w66,
    .report-settings .sea-grid .w50,
    .report-settings .sea-grid .w33 {
        width: 100%;
    }
    .report-settings .sea-grid .w25 {
        width: 50%;
    }
  }
  

@media screen and (max-width: 500px) {
    .report-settings.columns {
        flex-direction: column;
    }
    .report-settings.columns > div {
        max-width: 100%;
    }
}
