.voyage-report .sea-row-history > div:nth-of-type(1) { /* Name */
    flex: 1 1 140px;
}
.voyage-report .sea-row-history > div:nth-of-type(2), /* Departed */
.voyage-report .sea-row-history > div:nth-of-type(3), /* Arrived */
.voyage-report .sea-row-history > div:nth-of-type(4) { /* Trip Type */
    flex: 0 1 104px;
}
.voyage-report .sea-row-history > div:nth-of-type(5) { /* Vessel */
    flex: 1 1 140px;
}

@media screen and (max-width: 550px) {
    .voyage-report .sea-row-history > div::before {
        width: 80px !important;
    }
    .voyage-report .sea-row-history > div:nth-of-type(2)::before {
        content: 'Departed';
    }
    .voyage-report .sea-row-history > div:nth-of-type(3)::before {
        content: 'Arrived';
    }
    .voyage-report .sea-row-history > div:nth-of-type(4)::before {
        content: 'Trip Type';
    }
    .voyage-report .sea-row-history > div:nth-of-type(5)::before {
        content: 'Vessel';
    }
}
