.sea-row.draft {
    font-style: italic;
}
.sea-status.incident {
    color: var(--text-on-white);
}
.sea-status.incident.forReview {
    color: white;
    background-color: var(--ion-color-danger);
}
.sea-status.incident.inReview {
    color: white;
    background-color: var(--ion-color-warning);
}
.sea-status.incident.completed {
    /* color: var(--ion-color-success); */
    color: var(--bg-completed);
    font-weight: 500;
    font-size: 11px;
}
.sea-status.incident.draft {
    font-size: 11px;
    background-color: #e5e5e5;
    background-color: var(--bg-draft);
    color: #666666;
    font-style: italic;
}

.incidents {
    max-width: var(--max-table-width);
}

.sea-stat-container {
    display: block;
}
.sea-stat {
    display: inline-block;
    padding: 12px 0px;
    background-color: var(--yellow-orange);
    border-radius: 15px;
    margin: 10px 8px 0px 0px;
    color: var(--bg-header);
    font-size: 12px;
    font-weight: 500;
    min-width: 90px;
    text-align: center;
}
.sea-stat > div {
    font-size: 24px;
    font-weight: bold;
    padding: 15px 0px;
    color: var(--ion-color-secondary);
}

@media screen and (max-width: 600px) {
    .sea-stat-container {
        display: flex;
        flex-wrap: wrap;
    }
    .sea-stat {
        display: block;
        flex: 1 1 0;
        min-width: 80px;
        padding: 8px 0px;
    }
    .sea-stat > div {
        padding: 6px 0px;
    }
}
@media screen and (max-width: 550px) {
    .sea-stat-container {
        padding-bottom: 20px;
    }
}
@media screen and (max-width: 398px) {
    .sea-stat {
        min-width: 110px;
    }
}


.incidents .sea-row > div:nth-of-type(1) { /* img */
    flex: 0 1 56px;
}
.incidents .sea-row > div:nth-of-type(3) { /* Vessel */
    flex: 0.5 1 0;
}
.incidents .sea-row > div:nth-of-type(4) { /* Report # */
    flex: 0 1 100px;
    text-align: center;
}
.incidents .sea-row > div:nth-of-type(5) { /* Date */
    flex: 0 1 120px;
}
.incidents.hide-vessel .sea-row > div:nth-of-type(3) { /* Vessel */
    display: none;
}
.incidents .sea-row > div:nth-of-type(6) { /* Type */
    flex: 0 1 100px;
}

.incidents .sea-row > div:nth-of-type(7) { /* Status */
    flex: 0 1 120px;
    text-align: center;
    padding-right: 8px;
}

@media screen and (max-width: 935px) {
    .incidents .sea-row > div:nth-of-type(4) { /* Report # */
        display: none;
    }
    .incidents.page-head {
        flex-direction: column;
    }
    .incidents.page-head .actions {
        justify-content: flex-start;
    }
}

@media screen and (max-width: 590px) {
    .incidents .sea-row > div:nth-of-type(4) { /* Report # */ 
        display: none;
    }
}
@media screen and (max-width: 700px) {
    .incidents .sea-row > div:nth-of-type(3) { /* Vessel */
        display: none;
    }
}

@media screen and (max-width: 890px) {
    .show-menu.desktop-view .incidents .sea-row > div:nth-of-type(4) { /* Report # */ 
        display: none;
    }
}
@media screen and (max-width: 1000px) {
    .show-menu.desktop-view .incidents.show-vessel .sea-row > div:nth-of-type(4) { /* Report # */ 
        display: none;
    }
}

@media screen and (max-width: 550px) {
    .incidents .sea-row > div:nth-of-type(3)::before {
        content: 'Vessel';
    }
    .incidents .sea-row > div:nth-of-type(5)::before {
        content: 'Date';
    }
    .incidents .sea-row > div:nth-of-type(6)::before {
        content: 'Type';
    }
    .incidents .sea-row > div:nth-of-type(4) {
        display: none; /* Hide Report # */
    }
}
