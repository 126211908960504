.sea-status {
  font-size: 10px;
  font-weight: 700;
  color: #fff;
  text-align: center;
  text-transform: uppercase;
  line-height: 22px;
  padding-top: 2px;
  border-radius: 12px;
  /*
  border-style: solid;
  border-width: 2px;
  border-color: #fff;
  */
  max-width: 150px;
  overflow: hidden;
}
.sea-status.ok {
  background-color: var(--ion-color-success);
}
.sea-status.warn {
  background-color: var(--ion-color-warning);
}
.sea-status.fail {
  background-color: var(--ion-color-danger);
}
.sea-status.now {
  background-color: var(--ion-color-light);
  color: var(--ion-color-secondary);
}
.sea-status.missing {
  background-color: #e493fd;
}
.sea-status.inline {
  display: inline-block;
  padding: 2px 12px 0px;
  vertical-align: middle;
  margin-top: -5px;
}

.sea-card.fail.fault .sea-status.fail,
.dashboard-table .row.fault .sea-status.fail {
  font-size: 12px;
}
