.fuel-used-report .sea-row-history > div:nth-of-type(1) { /* Name */
    flex: 1 1 124px;
}
.fuel-used-report .sea-row-history > div:nth-of-type(2) { /* Departed */
    flex: 0 1 104px;
}
.fuel-used-report .sea-row-history > div:nth-of-type(3), /* Fuel Started */
.fuel-used-report .sea-row-history > div:nth-of-type(4),  /* Fuel Ended */
.fuel-used-report .sea-row-history > div:nth-of-type(5), /* Fuel Bunkered */
.fuel-used-report .sea-row-history > div:nth-of-type(6) { /* Fuel Used */
    flex: 0 1 104px;
}
.fuel-used-report.hide-vessel .sea-row-history > div:nth-of-type(7) { /* Trip Type */
    display: none;
}
.fuel-used-report .sea-row-history > div:nth-of-type(7) { /* Trip Type */
    flex: 0 1 104px;
}
.fuel-used-report .sea-row-history > div:nth-of-type(8) { /* Vessel */
    flex: 1 1 104px;
}

@media screen and (max-width: 855px) {
    .fuel-used-report .sea-row-history > div:nth-of-type(3), /* Fuel Started */
    .fuel-used-report .sea-row-history > div:nth-of-type(4),  /* Fuel Ended */
    .fuel-used-report .sea-row-history > div:nth-of-type(5) { /* Fuel Bunkered */
        display: none
    }
}

@media screen and (max-width: 550px) {
    .fuel-used-report .sea-row-history > div::before {
        width: 80px !important;
    }
    .fuel-used-report .sea-row-history > div:nth-of-type(2)::before {
        content: 'Departed';
    }
    .fuel-used-report .sea-row-history > div:nth-of-type(6)::before {
        content: 'Fuel Used';
    }
    .fuel-used-report .sea-row-history > div:nth-of-type(7)::before {
        content: 'Trip Type';
    }
    .fuel-used-report .sea-row-history > div:nth-of-type(7)::before {
        content: 'Vessel';
    }
    .fuel-used-report .sea-row-history > div:nth-of-type(8) {
        text-align: left;
    }
}
