.lex-editor-sections {
    flex: 1 1 0;
}

.sfdoc-saving {
    opacity: 0.5;
    pointer-events: none;
}

.lex-editor-column {
    /* 28 + 760 + 28 (40px/cm) */
    max-width: calc(2 * var(--lex-margin-x) + var(--sfdoc-content-width) + 2 * var(--modal-padding-horizontal) + var(--lex-toolbar-wiggle-room));
    flex: 10 1 0;
    width: 100%;
    height: 100%;
}
.lex-content-container {
    background-color: var(--lex-color-off-page);
    display: flex;
    justify-content: center;
}
.lex-content-container > div {
    width: var(--sfdoc-page-width);
}

@media screen and (max-width: 850px) {
    .lex-content-container {
        justify-content: space-around;
    }
    .lex-editor-column .lex-page {
        margin-right: 8px;
        margin-left: 8px;
    }
}


.lex-page-control {
    display: none;
    width: 32px;
    height: 32px;
    position: absolute;
    top: -40px;
    text-align: center;
    font-weight: 400;
    color: #888;
    font-size: 38px;
    border-radius: 18px;
    cursor: pointer;
    user-select: none;
    text-decoration: none;

    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0);
    background-color: rgba(255,255,255,0);
    transition: all 0.2s;
}
.lex-page-control.plus {
    line-height: 32px;
    left: calc(50% - 25px);
}
.lex-page-control.options {
    right: 8px;
    font-size: 34px;
    line-height: 14px;
    box-shadow: none;
    background-color: rgba(255,255,255,0);
}

.lex-editor-column .lex-page-control {
    display: block;
}
.lex-editor-column .lex-page-control:hover {
    background-color: rgba(255,255,255,0.8);
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.2);
}


.lex-editor-column .sea-scrollable {
    background-color: var(--lex-color-off-page);
}

.lex-editor-column .lex-shell {
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15);
}

.lex-editor-column.lex-toolbar-container {
    background-color: var(--lex-color-off-page);
}

.lex-section-button {
    margin-top: 4px;
    margin-bottom: 2px;
    color: var(--text-on-white);
    cursor: pointer;
    font-size: 13px;
    font-weight: 400;
    padding: 8px 8px;
    display: inline-block;
    transition: all 0s;
    width: 100%;
    position: relative;
}
.lex-section-button.h1 {
    font-weight: 600;
}
.platform-mobile .lex-section-button.h1 {
    margin-top: 0px;
    margin-bottom: 0px;
    padding: 12px 12px;
}
.lex-section-button.h2 {
    padding: 4px 8px 4px 26px;
    overflow-x: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 12px;
    margin-top: -2px;
    margin-bottom: -2px;
    line-height: 20px;
}
.platform-mobile .lex-section-button.h2 {
    line-height: 30px;
}
.lex-section-button.active {
    background-color: var(--ion-color-primary);
    color: #fff;
}
.lex-section-button:active {
    background-color: #f5f5f5;
    box-shadow: inset 0px 0px 5px #aaa;
}
.lex-section-button:hover {
    background-color: #e8e8e8;
    transition: all 0s;
}
.lex-section-button.active:hover {
    background-color: var(--ion-color-primary-tint);
}

.lex-editor-doc-bar {
    display: none !important;
    background-color: #fff;
    padding: 0px calc(var(--modal-padding-horizontal) - 8px) 4px;
    /* margin-top: -4px; */
    width: unset !important;
    color: var(--text-on-white);
}
.lex-editor-doc-bar .button {
    padding-top: 0px;
    margin-top: -3px;
}
.lex-editor-doc-bar .sea-select {
    height: 44px;
}
.lex-editor-doc-bar .lex-next-prev ion-icon {
    color: #fff;
    stroke-width: 24px;
    fill: var(--text-on-white);
    opacity: 0.5;
}

/* Compact mode */
@media screen and (max-width: 1200px) {
    .lex-editor-column {
        max-width: unset;
    }
    .lex-editor-sections {
        display: none;
    }
    .lex-editor-doc-bar {
        display: flex !important;
    }
}
