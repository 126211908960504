.sea-spinner.sync {
    width: 20px;
    height: 20px;
    position: relative;
    top: unset;
    left: unset;
    margin: 0px 0px 2px 0px;
}

.sync ion-progress-bar {
    width: 60px;
    height: 10px;
    top: 18px;
    margin-left: 8px;
    opacity: 0.75;
}

.sync-modal-graph .svg-graph-container.modal {
    padding: 0px 0px 0px 0px;
}
