.safety-equipment-list {
    max-width: var(--max-table-width);
}

/* Column settings */
.safety-equipment-list .sea-row > div:nth-of-type(1) { /* img */
    flex: 0 1 56px;
}
.safety-equipment-list .sea-row > div:nth-of-type(2) { /* Safety Item */
    flex: 1.4 1;
}
.safety-equipment-list .sea-row > div:nth-of-type(3) { /* Location */
    flex: 1 1;
}
.safety-equipment-list .sea-row > div:nth-of-type(4) { /* last service */
    flex: 0 1 120px;
}

.safety-equipment-list .sea-row > div:nth-of-type(5) { /* Service/Expiry */
    flex: 0 1 95px;
}
.safety-equipment-list .sea-row > div:nth-of-type(6) { /* Critical */
    flex: 0 1 80px;
    text-align: center;
}
.safety-equipment-list .sea-row > div:nth-of-type(7) { /* Status */
    flex: 0 1 150px;
}
.safety-equipment-list .sea-row.headings > div:nth-of-type(7) {
    text-align: center;
}

.safety-equipment-list .sea-row > div:nth-of-type(8) { /* reminder */
    flex: 0 1 110px;
    text-align: center;
}
.safety-equipment-list .sea-row.headings > div:nth-of-type(8) {
    text-align: center;
}


@media screen and (max-width: 940px) {
    .safety-equipment-list.page-head {
        flex-direction: column;
    }
    .safety-equipment-list.page-head .actions {
        justify-content: flex-start;
    }
}

@media screen and (max-width: 950px) {
    .safety-equipment-list .sea-row > div:nth-of-type(8) { /* Reminder */ 
        display: none;
    }
}
@media screen and (max-width: 740px) {
    .safety-equipment-list .sea-row > div:nth-of-type(3) { /* Location */ 
        display: none;
    }
}
@media screen and (max-width: 620px) {
    .safety-equipment-list .sea-row > div:nth-of-type(4) { /* Last Service */ 
        display: none;
    }
}

@media screen and (max-width: 1150px) {
    .show-menu.desktop-view .safety-equipment-list .sea-row > div:nth-of-type(8) { /* Reminder */ 
        display: none;
    }
}
@media screen and (max-width: 1010px) {
    .show-menu.desktop-view .safety-equipment-list .sea-row > div:nth-of-type(3) { /* Location */ 
        display: none;
    }
}
@media screen and (max-width: 980px) {
    .show-menu.desktop-view .safety-equipment-list .sea-row > div:nth-of-type(4) { /* Last Service */ 
        display: none;
    }
}

@media screen and (max-width: 550px) {
    .safety-equipment-list .sea-card.sea-row > div::before {
        width: 108px;
    }
    .safety-equipment-list .sea-row > div:nth-of-type(3) {
        display: none; /* Hide Location */
    }
    .safety-equipment-list .sea-row > div:nth-of-type(4) {
        display: none; /* Hide Last service */
    }
    .safety-equipment-list .sea-row > div:nth-of-type(5)::before {
        content: 'Service/Expiry';
    }
    .safety-equipment-list .sea-row > div:nth-of-type(6) {
        padding: 0px;
    }
    .safety-equipment-list .sea-row > div:nth-of-type(7) {
        padding: 0px;
    }
    .safety-equipment-list .sea-row > div:nth-of-type(8) {
        display: none; /* Hide reminder */
    }
}
