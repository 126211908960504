
/* Column settings */
.voyage-safety-checks .sea-row-history > div:nth-of-type(1) { /* img */
    flex: 0 1 56px;
}
.voyage-safety-checks .sea-row-history > div:nth-of-type(2) { /* Safety Item */
    flex: 1.4 1;
}
.voyage-safety-checks .sea-row-history > div:nth-of-type(3) { /* Location */
    flex: 1 1;
}
.voyage-safety-checks .sea-row-history > div:nth-of-type(4) { /* Interval */
    flex: 0 1 110px;
}
.voyage-safety-checks .sea-row-history > div:nth-of-type(5) { /* next check */
    flex: 0 1 110px;
}
.voyage-safety-checks .sea-row-history > div:nth-of-type(6) { /* status */
    flex: 0 1 140px;
}
.voyage-safety-checks .sea-row-history.headings-history > div:nth-of-type(6) {
    text-align: center;
}

@media screen and (max-width: 730px) {
    .voyage-safety-checks.page-head {
        flex-direction: column;
    }
    .voyage-safety-checks.page-head .actions {
        justify-content: flex-start;
    }
}

@media screen and (max-width: 900px) {
    .voyage-safety-checks .sea-row-history > div:nth-of-type(3) { /* Location */ 
        display: none;
    }
}
@media screen and (max-width: 690px) {
    .voyage-safety-checks .sea-row-history > div:nth-of-type(4) { /* Interval */ 
        display: none;
    }
}
@media screen and (max-width: 500px) {
    .voyage-safety-checks .sea-row-history > div:nth-of-type(5) { /* Next Check */ 
        display: none;
    }
}

@media screen and (max-width: 1105px) {
    .show-menu.desktop-view .voyage-safety-checks .sea-row-history > div:nth-of-type(3) { /* Location */ 
        display: none;
    }
}
@media screen and (max-width: 970px) {
    .show-menu.desktop-view .voyage-safety-checks .sea-row-history > div:nth-of-type(4) { /* Interval */ 
        display: none;
    }
}

@media screen and (max-width: 550px) {
    .voyage-safety-checks .sea-row-history.sea-card > div::before {
        width: 72px;
    }
    .voyage-safety-checks .sea-row-history > div:nth-of-type(3) {
        display: none; /* Hide Location */
    }
    .voyage-safety-checks .sea-row-history > div:nth-of-type(4) {
        display: block; /* Interval */
    }
    .voyage-safety-checks .sea-row-history > div:nth-of-type(4)::before {
        content: 'Interval';
    }
    .voyage-safety-checks .sea-row-history > div:nth-of-type(5) {
        display: none; /* Hide Next Check */
    }
    .voyage-safety-checks .sea-row-history > div:nth-of-type(6) {
        padding: 0px;
    }
}
