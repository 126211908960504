


 ion-col.expander.reduce {
    padding-bottom: 0px !important;
}

.spare-parts-quantity .sea-row-history.headings-history {
    padding-top: 0 !important;
}

.spare-parts-quantity .sea-row-history > div:nth-of-type(1) { /* Item */
    flex: 1.5 1;
}
.spare-parts-quantity .sea-row-history > div:nth-of-type(2), /* Used */
.spare-parts-quantity .sea-row-history > div:nth-of-type(3) { /* Added */
    flex: 0 116.8px;
    align-items: center;
    text-align: center;
    justify-content: center;
    display: flex;
    flex-direction: row;
}
.spare-parts-quantity .sea-row-history > div:nth-of-type(2) > ion-button, /* Used */
.spare-parts-quantity .sea-row-history > div:nth-of-type(3) > ion-button {
    padding-left: 0px;
    padding-right: 0px;
    width: 33px;
}

.spare-parts-quantity .sea-row-history > div > ion-button:nth-of-type(1) /* Used */{
    --border-radius: 10px 0px 0px 10px;
}
.spare-parts-quantity .sea-row-history > div > ion-button:nth-of-type(2) /* Added */{
    --border-radius: 0px 10px 10px 0px;
}

.spare-parts-quantity .sea-row-history > div:nth-of-type(2) > ion-input, /* Used */
.spare-parts-quantity .sea-row-history > div:nth-of-type(3) > ion-input {
    margin-right: -2px;
    margin-left: -2px;
    border-radius: 0px;
    /* border: none; */
    height: 44.8px;
}

.spare-parts-quantity .sea-row-history > div:nth-of-type(4) { /* Quantity */
    flex: 0 1 90px !important;
    text-align: center;
}
.spare-parts-quantity .sea-row-history .sea-input { 
    height: 44.8px;
    min-width: 44.8px;
    max-width: 44.8px;
}

.spare-parts-quantity .sea-row-history .sea-input > input { 
    padding-left: 0px;
    padding-right: 0px;
    text-align: center;
}
.spare-parts-quantity .sea-row-history .sea-input-error { 
    display: none;
}

.spare-parts-quantity input[type=number]::-webkit-inner-spin-button,
.spare-parts-quantity input[type=number]::-webkit-outer-spin-button {
-webkit-appearance: none;
-moz-appearance: none;
appearance: none;
margin: 0;
}

@media screen and (max-width: 550px) {
    .spare-parts-quantity .sea-row-history.headings-history {
        display: block !important;
    }
    .spare-parts-quantity .sea-row-history.headings-history  > div:nth-of-type(1),
    .spare-parts-quantity .sea-row-history.headings-history > div:nth-of-type(4) {
        display: none !important;
    }
    .spare-parts-quantity .sea-row-history.headings-history > div:nth-of-type(2),
    .spare-parts-quantity .sea-row-history.headings-history > div:nth-of-type(3) {
        width: 60px;
        margin-top: -32px;
        margin-right: 3px;
        text-align: center;
    }
    .spare-parts-quantity .sea-row-history.headings-history > div:nth-of-type(2) {
        margin-right: 7px;

    }
    .spare-parts-quantity .sea-row-history > div:nth-of-type(1) {
        font-weight: 600;
        padding-right: 100px;
    }
    .spare-parts-quantity .sea-row-history > div:nth-of-type(2),
    .spare-parts-quantity .sea-row-history > div:nth-of-type(3) {
        justify-content: flex-end;
        flex: 0 40px;
        position: absolute;
    }
    .spare-parts-quantity .sea-row-history > div:nth-of-type(2) ion-button,
    .spare-parts-quantity .sea-row-history > div:nth-of-type(3) ion-button {
        display: none;
    }
    .spare-parts-quantity .sea-row-history > div:nth-of-type(2) ion-input,
    .spare-parts-quantity .sea-row-history > div:nth-of-type(3) ion-input {
        margin-left: 0px;
        margin-right: 0px;
    }
    .spare-parts-quantity .sea-row-history > div:nth-of-type(2)::before,
    .spare-parts-quantity .sea-row-history > div:nth-of-type(3)::before {
        content: '';
    }
    .spare-parts-quantity .sea-row-history > div:nth-of-type(2) {
        right: 58px;
    }
    .spare-parts-quantity .sea-row-history > div:nth-of-type(3) {
        right: 6px;
    }
    .spare-parts-quantity .sea-row-history > div:nth-of-type(4)::before {
        content: 'Quantity';
    }
    .spare-parts-quantity .sea-row-history > div:nth-of-type(4) {
        text-align: left;
    }
}