.user-certificates .sea-row-history > div:nth-of-type(1) { /* img */
    flex: 0 1 56px;
}
.user-certificates .sea-row-history > div:nth-of-type(3) { /* Issue by */
    flex: 0 1 150px;
}
.user-certificates .sea-row-history > div:nth-of-type(4) { /* expiry */
    flex: 0 1 85px;
}
.user-certificates  .sea-row-history > div:nth-of-type(5) { /* Status */
    flex: 0 1 140px;
    text-align: center;
}
.user-certificates  .sea-row-history > div:nth-of-type(6) { /* Email reminder */
    flex: 0 1 95px;
    text-align: center;
}

@media screen and (max-width: 985px) {
    .user-certificates.page-head {
        flex-direction: column;
    }
    .user-certificates.page-head .actions {
        justify-content: flex-start;
    }
}

@media screen and (max-width: 850px) {
    .user-certificates .sea-row-history > div:nth-of-type(6) { /* Reminder */
        display: none;
    }
}
@media screen and (max-width: 700px) {
    .user-certificates .sea-row-history > div:nth-of-type(3) { /* Issued By */
        display: none;
    }
}

@media screen and (max-width: 550px) {
    .user-certificates .sea-row-history > div:nth-of-type(2) {
        font-weight: 600;
    }
    .user-certificates .sea-row-history > div:nth-of-type(3) {
        display: none; /* Hide Issued By */
    }
    .user-certificates .sea-row-history > div:nth-of-type(4) {
        display: block; /* Expiry */
    }
    .user-certificates .sea-row-history > div:nth-of-type(4)::before {
        content: 'Expiry';
    }
    .user-certificates .sea-row-history > div:nth-of-type(5) {
        padding: 0px;
    }
    .user-certificates .sea-row-history > div:nth-of-type(6) {
        display: none; /* Hide Reminder */
    }
}
