.sea-select::part(icon) {
  left: -10px;
  transform: scale(1.2);
  opacity: 0.4;
}
.sea-select-popover::part(content) {
  --width: 300px;
}

/* .sea-select-popover::part(container) {
  color: red !important;
  --ion-item-color: red;
} */

.sea-select-popover .sc-ion-select-popover {
  font-size: 13px;
  font-family: var(--sea-font-family);
  /*--padding-start: 0px;*/
}
/*
.sc-ion-select-popover.sc-ion-label-ios-h {
  padding-left: 16px;
}
*/
