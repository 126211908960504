.sea-link-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: inline;
  text-transform: uppercase;
  text-decoration: none;
  font-weight: 700;
  margin: 0px 8px;
  padding: 8px 0px;
  color: var(--ion-color-primary);
  letter-spacing: 1px;
  font-size: 12px;
}
.sea-link-button:hover, .sea-link-button:focus {
  text-decoration: none;
}
.sea-link-button:active, .sea-link-button:focus {
  outline: 0;
}
.sea-link-button.small-link {
  color: var(--text-on-grey);
  text-decoration: underline;
  text-transform: none;
  font-size: 10px;
  font-weight: 400;
  margin: 0;
  padding: 0;
}
.sea-link-button.standard-link {
  text-decoration: underline;
  font-weight: 500;
}
.sea-link-button.simple-link {
  text-decoration: underline;
  font-weight: 500;
  text-transform: none;
  margin: 0px 0px;
  letter-spacing: 0px;
}
