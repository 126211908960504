.user-drills .sea-row-history > div:nth-of-type(1) { /* Drill */
    flex: 1 1 0;
    cursor: pointer;
}
.user-drills .sea-row-history > div:nth-of-type(2) { /* Interval */
    flex: 0 1 90px;
}
.user-drills .sea-row-history > div:nth-of-type(3) { /* Last Done */
    flex: 0 1 100px;
}
.user-drills .sea-row-history > div:nth-of-type(4) { /* Next Due */
    flex: 0 1 100px;
}
.user-drills  .sea-row-history > div:nth-of-type(5) { /* History */
    flex: 1 1 0;
    cursor: pointer;
}

@media screen and (max-width: 700px) {
    .user-drills .sea-row-history > div:nth-of-type(2) { /* Interval */
        display: none;
    }
}

@media screen and (max-width: 550px) {
    .user-drills .sea-row-history > div:nth-of-type(1) {
        font-weight: 600;
    }
    .user-drills .sea-row-history > div:nth-of-type(3)::before {
        content: 'Last Done';
    }
    .user-drills .sea-row-history > div:nth-of-type(4)::before {
        content: 'Next Due';
    }
    .user-drills .sea-row-history > div:nth-of-type(5)::before {
        content: 'History';
    }
}
