.action-log-report .sea-row-history.headings-history {
    padding-top: 0px;
    margin-top: 0px;
}

.action-log-report .sea-row-history > div:nth-of-type(1) { /* Date */
    flex: 0 1 80px;
}

.action-log-report .sea-row-history > div:nth-of-type(2) { /* Done by */
    flex: 0 1 165px;
    cursor: pointer;
    white-space: nowrap;
    overflow-x: hidden;
    text-overflow: ellipsis;
}

.action-log-report .sea-row-history > div:nth-of-type(3) { /* Icon */
    flex: 0 1 15px;
    padding: 9px 0px 5px 0px;
    opacity: 0.66;
}

.action-log-report .sea-row-history > div:nth-of-type(4) { /* Action */
    flex: 1 1 0;
    cursor: pointer;
}

.action-log-report .sea-row-history > div:nth-of-type(5) { /* Detail */
    flex: 1 1 0;
    cursor: pointer;
}

.action-log-report .sea-row-history > div:nth-of-type(6) { /* Vessels */
    flex: 0.6 1 0;
    cursor: pointer;
}
.action-log-report.hide-vessels .sea-row-history > div:nth-of-type(6) { /* Vessels */
    display: none;
}

.action-log-report .sea-row-history.headings-history > div { /* Headings */
    cursor: initial;
    white-space: wrap;
}


@media screen and (max-width: 550px) {
    .action-log-report .sea-card.sea-row-history {
        padding: 2px 10px 8px;
    }
    .action-log-report .sea-row-history > div:nth-of-type(1)::after {
        content: attr(data-fullname);
    }
    .action-log-report .sea-row-history > div:nth-of-type(2) {
        display: none
    }
    .action-log-report .sea-row-history > div:nth-of-type(3) {
        position: absolute;
        top: 20px;
        opacity: 1;
    }
    .action-log-report .sea-row-history > div:nth-of-type(4) {
        padding-left: 24px;
        font-weight: 600;
    }
    .action-log-report .sea-row-history > div:nth-of-type(7) {
        display: none;
    }
}
