.incidents.by-contributing .sea-row-history > div:nth-of-type(2) { /* Name */
    flex: 1.5 1 0;
}
.incidents.by-contributing .sea-row-history > div:nth-of-type(3) { /* Contributing Factors */
    flex: 1.5 1 0;
}

@media screen and (max-width: 550px) {
    .incidents.by-contributing .sea-row-history > div::before {
        width: 80px;
    }
    .incidents.by-contributing .sea-row-history > div:nth-of-type(2)::before {
        content: 'Name';
    }
    .incidents.by-contributing .sea-row-history > div:nth-of-type(3)::before {
        content: 'Factors';
    }
}