.lex-dropdown {
    position: fixed;
    z-index: 10000;
    background-color: var(--ion-color-light);
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.25);
    padding: 4px 4px;
    border-radius: var(--input-border-radius);
}

.lex-dropdown-icon {
    background-color: transparent;
    background-size: contain;
    display: inline-block;
    width: 16px;
    height: 16px;
    user-select: none;
    position: relative;
    top: 4px;
    margin-left: 6px;
    opacity: 0.75;
}

.lex-dropdown .lex-button {
    display: block;
    width: 100%;
    text-align: left;
}

.lex-button.lex-drop-down-button {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}
