.safety-equipment-checks {
    max-width: var(--max-table-width);
}

/* Column settings */
.safety-equipment-checks .sea-row > div:nth-of-type(1) { /* img */
    flex: 0 1 56px;
}

.safety-equipment-checks .sea-row > div:nth-of-type(2) { /* Safety Item */
    flex: 1.4 1;
}

.safety-equipment-checks .sea-row > div:nth-of-type(3) { /* Location */
    flex: 1 1;
}

.safety-equipment-checks .sea-row > div:nth-of-type(4) { /* Interval */
    flex: 0 1 120px;
}

.safety-equipment-checks .sea-row > div:nth-of-type(5) { /* Next Check */
    flex: 0 1 110px;
}

.safety-equipment-checks .sea-row > div:nth-of-type(6) { /* Assigned To */
    flex: 0 1 150px;
}

.safety-equipment-checks .sea-row #status, #status-heading { /* Status */
    flex: 0 1 150px;
}

.safety-equipment-checks .sea-row.headings #status-heading { /* Status */
    text-align: center;
}

.safety-equipment-checks .sea-row #is-critical, #critical-heading { /* Critical */
    flex: 0 1 80px;
    text-align: center;
}

@media screen and (max-width: 730px) {
    .safety-equipment-checks.page-head {
        flex-direction: column;
    }

    .safety-equipment-checks.page-head .actions {
        justify-content: flex-start;
    }
}

@media screen and (max-width: 1170px) {
    .safety-equipment-checks .sea-row > div:nth-of-type(6) { /* Assigned To */
        display: none;
    }
}

@media screen and (max-width: 900px) {
    .safety-equipment-checks .sea-row > div:nth-of-type(3) { /* Location */
        display: none;
    }
    .safety-equipment-checks .sea-row .estimated-time {
        display: none;
    }
}

@media screen and (max-width: 690px) {
    .safety-equipment-checks .sea-row > div:nth-of-type(4) { /* Interval */
        display: none;
    }
}

@media screen and (max-width: 500px) {
    .safety-equipment-checks .sea-row > div:nth-of-type(5) { /* Next Check */
        display: none;
    }
}

@media screen and (max-width: 1320px) {
    .show-menu.desktop-view .safety-equipment-checks .sea-row > div:nth-of-type(6) { /* Assigned To */
        display: none;
    }
}

@media screen and (max-width: 1105px) {
    .show-menu.desktop-view .safety-equipment-checks .sea-row > div:nth-of-type(3) { /* Location */
        display: none;
    }
}

@media screen and (max-width: 970px) {
    .show-menu.desktop-view .safety-equipment-checks .sea-row > div:nth-of-type(4) { /* Interval */
        display: none;
    }

    .show-menu.desktop-view .safety-equipment-checks .sea-row .estimated-time {
        display: none;
    }
}

@media screen and (max-width: 550px) {
    .safety-equipment-checks .sea-row.sea-card > div::before {
        width: 72px;
    }

    .safety-equipment-checks .sea-row > div:nth-of-type(3) { /* Location */
        display: none; /* Hide Location */
    }

    .safety-equipment-checks .sea-row > div:nth-of-type(4) { /* Interval */
        display: block; /* Interval */
    }

    .safety-equipment-checks .sea-row > div:nth-of-type(4)::before {
        content: 'Interval';
    }

    .safety-equipment-checks .sea-row > div:nth-of-type(5) { /* Next Check */
        display: none; /* Hide Next Check */
    }

    .safety-equipment-checks .sea-row > div:nth-of-type(7) { /* Status */
        padding: 0px;
    }

    .safety-equipment-checks .sea-row > div:nth-of-type(8) { /* Critical */
        padding: 0px;
    }
}
