
.voyage-forms-docs.columns {
    padding: 4px 0px;
}
.voyage-forms-docs.columns > div {
    padding-top: 0px;
}

.voyage-forms-docs.columns > div:nth-of-type(2) {
    padding-bottom: 4px;
    padding-left: 4px;
    align-self: center;
}

.voyage-forms-docs.columns .sea-file-image.tiny {
    margin-bottom: 0px !important;
    margin-right: 4px !important;
}






.voyage-trip-stops {
    margin-bottom: 4px;
}
.voyage-trip-stops.sea-label {
    display: none;
}
.voyage-trip-stops .sea-card.sea-row-history {
    margin-top: 6px;
    min-height: 35px;
    padding: 4px 4px;
}
.voyage-trip-stops .sea-row-history.headings-history > div {
    padding: 0px;
}

.voyage-trip-stops .sea-row-history.headings-history {
    padding-top: 4px;
    padding-left: 0px;
    margin-top: 0px;
}
.voyage-trip-stops .sea-row-history.headings-history > div {
    font-weight: 500;
    color: var(--label-text);
    font-size: 11px;
    letter-spacing: 0px;
}


.voyage-trip-stops .sea-row-history > div:nth-of-type(1) { /* Stop */
    flex: 1 1 0;
}
.voyage-trip-stops .sea-row-history > div:nth-of-type(2) { /* Arrival Time */
    flex: 0 1 150px;
    text-align: center;
}
.voyage-trip-stops .sea-row-history > div:nth-of-type(3) { /* PAX Off */
    flex: 0 1 90px;
    text-align: center;
}
.voyage-trip-stops .sea-row-history > div:nth-of-type(4) { /* PAX On */
    flex: 0 1 90px;
    text-align: center;
}
.voyage-trip-stops  .sea-row-history > div:nth-of-type(5) { /* Departure Time */
    flex: 0 1 150px;
    text-align: center;
}
.voyage-trip-stops .sea-row-history > div > span {
    min-width: 16px;
    display: inline-block;
}

@media screen and (max-width: 680px) {
    .voyage-trip-stops .sea-row-history > div:nth-of-type(2) { /* Arrival Time */
        flex: 0 1 120px;
    }
    .voyage-trip-stops .sea-row-history > div:nth-of-type(3) { /* PAX Off */
        flex: 0 1 70px;
    }
    .voyage-trip-stops .sea-row-history > div:nth-of-type(4) { /* PAX On */
        flex: 0 1 70px;
    }
    .voyage-trip-stops  .sea-row-history > div:nth-of-type(5) { /* Departure Time */
        flex: 0 1 120px;
    }
}
@media screen and (max-width: 600px) {
    .voyage-trip-stops .sea-row-history > div:nth-of-type(2) { /* Arrival Time */
        flex: 0 1 110px;
    }
    .voyage-trip-stops .sea-row-history > div:nth-of-type(3) { /* PAX Off */
        flex: 0 1 60px;
    }
    .voyage-trip-stops .sea-row-history > div:nth-of-type(4) { /* PAX On */
        flex: 0 1 60px;
    }
    .voyage-trip-stops  .sea-row-history > div:nth-of-type(5) { /* Departure Time */
        flex: 0 1 110px;
    }
}

@media screen and (max-width: 550px) {
    .voyage-trip-stops.sea-label {
        display: block;
    }
    .voyage-trip-stops .sea-card.sea-row-history {
        padding: 0px 8px 8px;
    }
    .voyage-trip-stops .sea-row-history > div {
        text-align: left !important;
    }
    .voyage-trip-stops .sea-card.sea-row-history > div::before {
        width: 120px;
    }
    .voyage-trip-stops .sea-row-history > div:nth-of-type(1) {
        font-weight: 500;
    }
    .voyage-trip-stops .sea-row-history > div:nth-of-type(2)::before {
        content: 'Arrival Time';
    }
    .voyage-trip-stops .sea-row-history > div:nth-of-type(3)::before {
        content: 'PAX Off';
    }
    .voyage-trip-stops .sea-row-history > div:nth-of-type(4)::before {
        content: 'PAX On';
    }
    .voyage-trip-stops .sea-row-history > div:nth-of-type(5)::before {
        content: 'Departure Time';
    }
}





@media screen and (max-width: 660px) {
    .sea-modal.wide .voyage-info .sea-grid .w33 {
        width: 50%;
    }
}
@media screen and (max-width: 550px) {
    .sea-modal.wide .voyage-info .sea-grid .w50 {
        width: 100%;
    }
}
@media screen and (max-width: 430px) {
    .sea-modal.wide .voyage-info .sea-grid .w33 {
        width: 100%;
    }
}

