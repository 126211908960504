.sea-draggables {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
}

.sea-draggables .list:not(.custom-elements) {
    width: calc(100% + 20px);
    margin-left: -20px;
}

.sea-draggables > * {
    box-sizing: border-box;
    margin: 0;
}

.sea-draggables.row {
    flex-direction: row;
}

.sea-draggables.column {
    flex-direction: column;
}

.draggable-item {
    position: relative;
    display: flex;
    width: 100%;
}

.draggable-item.draggable {
    cursor: grab !important;
}

.draggable-item.dragging-active,
.draggable-item.dragging {
    cursor: grabbing !important;
    background-color: #fff;
}

.draggable-item.dragging-over,
.draggable-item.dragging {
    opacity: 0.5;
}

.draggable-item.dragging-over {
    outline: 2px dashed var(--ion-color-secondary);
    outline-offset: -2px;
    border-radius: 3px;
}

.dragging.has-outline {
    outline: 2px solid var(--ion-color-secondary);
    outline-offset: -2px;
    border-radius: 3px;
}

.non-draggable-item {
    display: flex;
    align-items: center;
}

.draggable-list {
    padding: 2px 0px 2px 2px;
}

.drag-handle {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px !important;
    height: 100%;
}

.drag-handle img {
    width: 16px !important;
    height: 16px !important;
    opacity: 0.5;
}

/* .drag-handle.list {
    display: flex;
    position: absolute;
    top: 0;
    bottom: 0;
    left: -20px;
    z-index: 1000;
} */

.drag-handle.list {
    position: relative;
    display: flex;
    padding: 4px 0px 4px 1px;
    height: 100%;
    align-items: center;
}

.drag-handle.customForms {
    position: absolute;
    top: 0;
    right: 0;
    display: none;
    align-items: center;
    touch-action: none;
    -webkit-user-select: none;
    user-select: none;
    z-index: 1000;
    cursor: grab;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0px;
    padding: 6px 4px 0px 0px;
    height: 24px;
}

.draggable-item:hover .drag-handle,
.draggable-item:focus-within .drag-handle {
    display: flex;
}

.drag-handle:hover {
    cursor: grab;
}

.drag-handle:active {
    cursor: grabbing;
}

.draggable-item > *:not(.drag-handle) {
    flex: 1;
    /* min-width: 0; */
}

.draggable-tiles .drag-handle {
    position: absolute;
    top: 0;
    right: 0;
}

