.drills .sea-row {
    justify-content: flex-start;
    align-items: stretch;
}

.drills .sea-card {
    min-width: fit-content;
    height: 64px;
}

.drills .sea-row > div {
    /* all except first 2 (users) */
    flex: 0 1 100px;
    text-align: center;
    min-width: 110px;
    padding-left: 4px;
    padding-right: 4px;
}

.drills .sea-row > div:nth-of-type(1) {
    /* drill name */
    flex: 0 1 220px;
    min-width: 220px;
    text-align: left;
    padding-left: 8px;
}

.drills-content .sea-card.sea-row > .slot .drill-name-mobile {
    display: none;
}

.drills .headings {
    cursor: pointer;
}

.drills .slot {
    align-self: stretch;
    transition: opacity 0.1s;
}

.drills .slot:hover {
    opacity: 0.75;
}

.drills .due {
    padding-top: 6px;
    height: 44px;
    border-radius: 3px;
    font-size: 16px;
    font-weight: 500;
}

.drills .due > div {
    position: relative;
    top: -1px;
    font-size: 11px;
    font-weight: normal;
    opacity: 0.9;
}

.drills .due.od {
    background-color: #fbd6d6;
}

.drills .due.soon {
    background-color: #fff3cd;
}

.drills .due.unassigned {
    color: #cccccc;
    background-color: #eeeeee;
}

.drills .due .drill-na {
    font-size: 16px;
    font-weight: 500;
}

.pushy.due.missing .missing-text {
    margin-top: 10px;
    color: #b9a7be;
}
.pushy.due.unassigned .unassigned-text {
    margin-top: 10px;
}

.drills .due.missing {
    background-color: #eee3f2;
}

.drills .due.ok {
    background-color: #d4edda;
}

@media screen and (max-width: 1000px) {
    .drills.page-head {
        flex-direction: column;
    }

    .drills.page-head .actions {
        justify-content: flex-start;
    }
}

.drills-content .sea-card.sea-row .more {
    display: none;
}

/* Compact mode for crew training */
@media screen and (max-width: 550px) {
    .drills-content .sea-row.headings {
        display: none;
    }

    .pushy.due.missing .missing-text {
        margin-top: 0px;
    }
    .pushy.due.unassigned .unassigned-text {
        margin-top: 0px;
    }

    .drills-content .sea-card.sea-row {
        padding: 8px 4px 8px 4px;
        height: unset;
        flex-direction: column;
    }

    .drills-content .sea-card.sea-row > div {
        display: none;
    }

    .drills-content .sea-card.sea-row .more {
        display: block !important;
        position: absolute;
        right: 0px;
        top: 0px;
        min-width: unset;
        padding: 21px 16px;
        color: var(--ion-color-primary);
        text-transform: uppercase;
        font-size: 12px;
    }

    .drills-content .sea-card.sea-row .more ion-icon {
        position: relative;
        top: 1px;
        margin-left: 2px;
    }

    .drills-content .sea-card.sea-row > div:nth-of-type(1) {
        /* Drill Name */
        display: block !important;
        flex: 0 1 40px;
        max-width: calc(100vw - 90px);
        align-self: flex-start;
        padding: 12px 40px 16px 8px;
    }

    .drills-content .sea-card.sea-row > .slot {
        position: relative;
        display: block;
        padding: 0px 8px;
        margin: 4px 0px 0px;
        flex: 1 1;
        text-align: left;
    }

    .drills-content .sea-card.sea-row.shrunk > .slot {
        display: none;
    }

    .drills-content .sea-card.sea-row > .slot::before {
        content: attr(data-fullname);
        position: absolute;
        top: 10px;
        left: 8px;
        width: 160px;
        overflow-x: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        padding-left: 0px;
    }

    .drills-content .sea-card.sea-row > .slot {
        display: flex;
        justify-content: space-between;
    }

    .drills-content .sea-card.sea-row > .slot .drill-name-mobile {
        max-width: 160px;
        overflow-x: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        padding-left: 0px;
        text-transform: uppercase;
        display: inherit;
    }

    .drills-content .sea-card.sea-row > .slot .pushy {
        display: block;
        height: 30px;
        padding: 6px 8px;
        text-align: center;
        min-width: 160px;
        max-width: 200px;
    }

    .drills-content .sea-card.sea-row > .slot .pushy > div {
        display: inline-block;
        padding-left: 10px;
    }
}

@media screen and (max-width: 380px) {
    .drills-content .sea-card.sea-row .more span {
        display: none;
    }

    .drills-content .sea-card.sea-row > div:nth-of-type(1) {
        /* Drill Name */
        max-width: calc(100vw - 8px);
    }

    .drills-content .sea-card.sea-row > .slot .pushy {
        min-width: 120px;
    }
}

@media screen and (max-width: 350px) {
    .drills-content .sea-card.sea-row > .slot .drill-name-mobile {
        overflow-x: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
}
