.super-admin-layout {
    --max-width: 100%;
    --side-bar-width: 0px;
    position: absolute;
    left: 0px;
    right: 0px;
    bottom: 0px;
    top: calc(var(--header-bar-height) + var(--safe-inset-top));
}
.super-admin-bg {
    background: var(--bg-grey);
}
.super-admin-content-container {
    padding-left: 0px;
    padding-bottom: var(--footer-height);
    position: relative;
    min-height: calc(100vh - var(--header-bar-height) - var(--safe-inset-top));
}
.super-admin-content {
    padding: 0px 16px 16px 16px;
}
