
.sea-help {
    display: inline-block;
    position: relative;
    left: 2px;
    top: -7px;
    cursor: pointer;
    vertical-align: top;
    /* color: #9fa1a9; */
    color: #ccc;
    transition: opacity 0.1s;
}
.editor-panel .sea-help {
    color: #ebebeb;
}

.text.sea-help {
    display: inline-block;
    top: 0px;
    left: -7px;
    vertical-align: middle;
    /* background-color: rgba(255,100,220,0.25); */
    padding: 0px 12px 2px 10px !important;
}

.check.text.sea-help {
    top: 0px;
    padding: 8px 12px 8px 10px !important;
}

.sea-help:hover {
    opacity: 0.8;
}

.sea-help-popover {
    --max-width: 90%;
    --width: 520px;
    /* --background: #e2e9ff; */
}
.sea-help-popover.small {
    --width: 250px;
}
.sea-help-popover-content {
    color: var(--text-on-white);
    padding: 8px 10px;
    line-height: 1.6em;
}

.sea-help-popover-content .img-container {
    max-width: 100%;
}

.sea-help-popover-content .img-container,
.sea-help-popover-content .sea-help-img {
    width: 500px;
    height: 375px;
    background-color: #eee;
}
.sea-help-popover-content .img-container-tiny {
    min-height: 60px;
    margin-top: 2px;
}
.sea-help-popover-content .img-container-tiny,
.sea-help-popover-content .sea-help-img-tiny {
    height: 60px;
    max-width: 500px;
    background-color: #eee;
    text-align: center;
}
.sea-help-popover-content .sea-help-img-tiny {
    height: 60px;
}
  
.sea-help-popover-content img {
    object-fit: contain;
}
