.standard-layout {
    background-color: var(--bg-margins);
    --page-padding-vertical: 25px;
    --page-padding-horizontal: 32px;
    position: absolute;
    right: 0px;
    bottom: 0px;
    top: calc(var(--header-bar-height) + var(--safe-inset-top));
    transition: left var(--responsive-transition-easing) var(--responsive-transition-time);
}
.standard-content-bg {
    background: var(--bg-grey);
    /*background: linear-gradient(90deg, #6d6f78 0px, #6d6f78 var(--side-bar-width), var(--bg-grey) calc(var(--side-bar-width) + 30px));*/
    background: linear-gradient(90deg, #6d6f78 0px, var(--bg-grey) 30px);
}
.standard-layout-content-container {
    /* padding-left: var(--side-bar-width); */
    color: var(--ion-color-light);
    --ion-text-color: var(--ion-color-light);
    padding-bottom: var(--footer-height);
    position: relative;
    min-height: calc(100vh - var(--header-bar-height) - var(--safe-inset-top));
}
.standard-layout-content {
    padding: var(--page-padding-vertical) var(--page-padding-horizontal);
    transition: padding var(--responsive-transition-easing) var(--responsive-transition-time);
}



/* Content should be against left edge of screen by default */
.standard-layout {
    left: 0px;
    transition: left var(--responsive-transition-easing) var(--responsive-transition-time);
}

/* 
   Content should be against thin side menu when
      not mobile and
      not large desktop view and
      menu off
*/
@media screen and (min-width: 851px) and (max-width: 1300px) {
    /* .standard-layout {
        left: var(--side-bar-width);
    } */
    .hide-menu .standard-layout {
        left: var(--side-bar-width);
        --side-width: var(--side-bar-width);
    }
    .show-menu .standard-layout {
        left: var(--side-menu-width);
        --side-width: var(--side-menu-width);
    }
}
@media screen and (min-width: 1301px) {
    .standard-layout {
        left: var(--side-menu-width);
        --side-width: var(--side-menu-width);
    }
}

/* Mobile style */
@media screen and (max-width: 850px) {
    .standard-layout-content {
        --page-padding-vertical: 14px;
        --page-padding-horizontal: 16px; /* If you change this value, you will also need to adjust SeaTabsGroup.scrollToSelectedTab */
    }
    .standard-layout {
        --side-width: 0px;
    }
}
@media screen and (max-width: 450px) {
    .standard-layout-content {
        --page-padding-vertical: 10px;
        --page-padding-horizontal: 14px; /* If you change this value, you will also need to adjust SeaTabsGroup.scrollToSelectedTab */
    }
}
