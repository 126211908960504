.incidents .sea-row-history > div:nth-of-type(2) { /* Name */
    flex: 1.5 1 0;
}
.incidents.by-status .sea-row-history > div:nth-of-type(3) { /* Vessel */
    flex: 1 1 0;
}
.incidents.by-status.hide-vessel .sea-row-history > div:nth-of-type(3) { /* Vessel */
    display: none !important;
}

.incidents.by-status .sea-row-history > div:nth-of-type(4) { /* Status */
    flex: 0 1 120px;
    text-align: center;
    display: block !important;
}

@media screen and (max-width: 550px) {
    /* .incidents.by-category .sea-row-history > div::before {
        width: 80px;
    } */
    .incidents.by-status .sea-row-history > div:nth-of-type(2)::before {
        content: 'Name';
    }
    .incidents.by-status .sea-row-history > div:nth-of-type(3)::before {
        content: 'Vessel' !important;
    }
    .incidents.by-status.hide-vessel .sea-row-history > div:nth-of-type(3),
    .incidents.by-status.hide-vessel .sea-row-history > div:nth-of-type(3)::before {
        display: none !important;
    }
    .incidents.by-status .sea-row-history > div:nth-of-type(4)::before {
        display: none !important;
    }
}
