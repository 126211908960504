.sea-status.icon {
    line-height: inherit;
    
}

.sea-status.icon.critical {
    color: var(--ion-color-danger);
}

@media screen and (max-width: 800px) {
    .sea-status.icon.sea-status.icon.critical > ion-icon {
        font-size: 18px !important;
    }
}