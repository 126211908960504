.custom-form-container.complete-form {
    padding: 0px calc(var(--modal-padding-horizontal) - var(--cf-element-padding-horizontal)) var(--modal-padding-vertical) calc(var(--modal-padding-horizontal) - var(--cf-element-padding-horizontal));
}

@media screen and (max-width: 415px) {
    .custom-form-container .view-modal-buttons {
        flex-wrap: wrap;
    }
    .custom-form-container .view-modal-buttons ion-button {
        min-width: 120px;
    }
}
