.drill-history-history .sea-row-history > div:nth-of-type(4) { /* Image */
    flex: 0 1 56px;
}

@media screen and (max-width: 550px) {
    .drill-history-history .sea-row-history > div:nth-of-type(1),
    .drill-history-history .sea-row-history > div:nth-of-type(2) {
        padding-right: 44px;
    }
    .drill-history-history .sea-row-history > div:nth-of-type(1)::before {
        content: 'Date';
    }

    .drill-history-history .sea-row-history > div:nth-of-type(2)::before {
        content: 'Personnel';
        position: absolute;
        left: 0px;
    }
    .drill-history-history .sea-row-history > div:nth-of-type(2) {
        white-space: normal;
        padding-left: 90px;
    }
    
    .drill-history-history .sea-row-history > div:nth-of-type(3)::before {
        content: 'Drills';
        position: absolute;
        left: 0px;
    }
    .drill-history-history .sea-row-history > div:nth-of-type(3) {
        white-space: normal;
        padding-left: 90px;
    }

    .drill-history-history .sea-row-history > div:nth-of-type(4) {
        height: 0px;
        padding: 0px;
    }
    .drill-history-history .sea-row-history > div:nth-of-type(4) > .sea-file-image {
        position: absolute;
        top: 10px;
        right: 8px;
    }
}
