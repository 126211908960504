.job-list {
    max-width: var(--max-table-width);
}

/* Column settings */
.job-list .sea-row > div:nth-of-type(1) { /* img */
    flex: 0 1 56px;
}

.job-list .sea-row > div:nth-of-type(2) { /* Task */
    flex: 1 1 0;
}

.job-list .sea-row > div:nth-of-type(3) { /* Job num */
    flex: 0 1 80px;
    text-align: center;
}

.job-list .sea-row > div:nth-of-type(4) { /* Assigned To */
    flex: 0.4 1 0;
}

.job-list .sea-row > div:nth-of-type(5) { /* Tags */
    flex: 0.3 1 0;
}

.job-list .sea-row > div:nth-of-type(6) { /* Date Added */
    flex: 0 1 90px;
}

.job-list .sea-row > div:nth-of-type(7) { /* Due Date */
    flex: 0 1 90px;
}

.job-list .sea-row > div:nth-of-type(8) { /* Added by */
    flex: 0.4 1 0;
}

.job-list .sea-row .estimated-time { /* Estimated time */
    flex: 0 1 120px;
}

.job-list .sea-row .critical { /* Critical */
    flex: 0 1 80px;
    text-align: center;
}


@media screen and (max-width: 400px) {
    .job-list.page-head {
        flex-direction: column;
    }

    .job-list.page-head .actions {
        justify-content: flex-start;
    }
}


@media screen and (max-width: 1080px) {
    .job-list .sea-row > div:nth-of-type(4) { /* Assigned To */
        flex: 0.6 1 0;
    }

    .job-list .sea-row > div:nth-of-type(5) { /* Tags */
        flex: 0.5 1 0;
    }

    .job-list .sea-row > div:nth-of-type(6) { /* Date Added */
        display: none;
    }

    .job-list .sea-row > div:nth-of-type(8) { /* Added by */
        display: none;
    }
}

@media screen and (max-width: 820px) {
    .job-list .sea-row > div:nth-of-type(5) { /* Tags */
        display: none;
    }

    .job-list .sea-row .estimated-time { /* Estimated time */
        display: none;
    }
}

@media screen and (max-width: 1200px) {
    .show-menu.desktop-view .job-list .sea-row > div:nth-of-type(4) { /* Assigned To */
        flex: 0.6 1 0;
    }

    .show-menu.desktop-view .job-list .sea-row > div:nth-of-type(5) { /* Tags */
        flex: 0.5 1 0;
    }

    .show-menu.desktop-view .job-list .sea-row > div:nth-of-type(6) { /* Date Added */
        display: none;
    }

    .job-list .sea-row > div:nth-of-type(8) { /* Added by */
        display: none;
    }
}

@media screen and (max-width: 1040px) {
    .show-menu.desktop-view .job-list .sea-row > div:nth-of-type(5) { /* Tags */
        display: none;
    }
    .show-menu.desktop-view .job-list .sea-row .estimated-time { /* Estimated time */
        display: none;
    }
}


@media screen and (max-width: 550px) {
    .job-list .sea-row.sea-card > div::before {
        width: 93px;
    }

    .job-list .sea-row > div:nth-of-type(3) {
        text-align: left;
    }

    .job-list .sea-row > div:nth-of-type(3)::before {
        content: 'Job #';
    }

    .job-list .sea-row > div:nth-of-type(4)::before {
        content: 'Assigned';
    }

    .job-list .sea-row > div:nth-of-type(5)::before {
        content: 'Job Tags';
    }

    .job-list .sea-row > div:nth-of-type(7)::before {
        content: 'Due Date';
    }
}
