
.swatches-container {
  display: flex;
  flex-wrap: wrap;
  /* justify-content: space-between; */
  column-gap: 4px;
  row-gap: 4px;
}

.sea-colour.swatch {
  display: inline-block;
  width: 48px;
  height: 50px;
  cursor: pointer;
  /* margin: 0px 4px 4px 0px; */
}

.hue-picker .hue-horizontal {
  border-width: 1px !important;
  border-style: solid !important;
  border-color: var(--input-border-color) !important;
  border-radius: var(--input-border-radius) !important;
}

.hue-picker .hue-horizontal > div > div {
  height: 51px !important;
  border-radius: 10px !important;
  background: none !important;
  border-style: solid;
  border-color: var(--bg-grey);
  border-width: 2px;
  box-shadow: rgb(0 0 0 / 50%) 0px 1px 6px 0px !important;
}
