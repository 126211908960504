
/* Column settings */
.voyage-maintenance .sea-row-history > div:nth-of-type(1) { /* Equipment */
    flex: 1 1;
}
.voyage-maintenance .sea-row-history > div:nth-of-type(2) { /* Maintenance Task */
    flex: 1 1;
}
.voyage-maintenance .sea-row-history > div:nth-of-type(3) { /* Interval */
    flex: 0 1 100px;
}
.voyage-maintenance .sea-row-history > div:nth-of-type(4) { /* Next Due */
    flex: 0 90px;
}
.voyage-maintenance .sea-row-history > div:nth-of-type(5) { /* Status */
    flex: 0 1 150px;
}
.voyage-maintenance .sea-row-history.headings-history > div:nth-of-type(5) {
    text-align: center;
}

@media screen and (max-width: 900px) {
    .voyage-maintenance .sea-row-history > div:nth-of-type(3) { /* Interval */ 
        display: none;
    }
}
@media screen and (max-width: 820px) {
    .voyage-maintenance .sea-row-history > div:nth-of-type(4) { /* Next Due */ 
        display: none;
    }
}

@media screen and (max-width: 1080px) {
    .show-menu.desktop-view .voyage-maintenance .sea-row-history > div:nth-of-type(3) { /* Interval */ 
        display: none;
    }
}
@media screen and (max-width: 910px) {
    .show-menu.desktop-view .voyage-maintenance .sea-row-history > div:nth-of-type(4) { /* Next Due */ 
        display: none;
    }
}

@media screen and (max-width: 550px) {
    .voyage-maintenance .sea-row-history.sea-card > div::before {
        width: 45px;
    }
    .voyage-maintenance .sea-row-history > div:nth-of-type(3) {
        display: none; /* Hide Interval */
    }
    .voyage-maintenance .sea-row-history > div:nth-of-type(4) {
        display: none; /* Hide Next Due */
    }
    .voyage-maintenance .sea-row-history > div:nth-of-type(5) {
        padding: 0px;
    }
}
