.csv-generator-container .spinner {
    color: var(--ion-color-primary);
    width: 64px;
    height: 64px;
}

.csv-generator-container .download-link-container {
    margin-top: 16px; /* Add margin to the download link container */
}

.csv-generator-container .download-link {
    font-weight: 500;
    font-size: 18px;
    color: var(--ion-color-primary);
    text-decoration: none;
    cursor: pointer;
}