.sea-select-category-heading {
    overflow: visible;
    min-height: 0px;
    height: 24px;
    padding-top: 0px;
    margin-top: 0px;
}
.sea-select-category-heading > ion-label:first-of-type {
    background-color: var(--text-on-grey);
    margin-top: -20px;
    margin-right: -48px;
    margin-left: -24px;
    text-align: left;
    text-transform: uppercase;
    font-size: 10px;
    font-weight: bold;
    letter-spacing: 2px;
    padding: 6px 12px 6px 24px;
    color: var(--text-on-white);
}

.sea-select-category-heading > ion-label:first-child {
    opacity: 1 !important;
}
