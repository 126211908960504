.risk-matrix {
    text-align: center;
    font-weight: 500;
    font-size: 13px;
    color: var(--text-on-white);
}
table.risk-matrix {
    width: 100%;
}
.risk-matrix td {
    padding: 8px 8px;
    height: 50px;
    border-width: 2px;
    border-style: solid;
    border-color: #fff;
}
.risk-matrix td.selected {
    box-shadow: inset 0px 0px 0px 2px var(--bg-header);
}
.risk-matrix td.label.selected {
    box-shadow: inset 0px 0px 0px 2px var(--bg-header);
}
.risk-matrix .type, .risk-matrix.simple td.type {
    background-color: #ddd;
    font-weight: 700;
}
.risk-matrix .label {
    background-color: #eee;
    cursor: pointer;
}
.risk-matrix .no-border {
    border-style: none !important;
}

.risk-matrix.simple td {
    cursor: default;
    background-color: #eee;
}
.risk-matrix.simple .description {
    text-align: left;
    /* vertical-align: top; */
}

.matrix-table-heading {
    font-size: 16px;
    margin-top: 40px;
}


@media screen and (max-width: 600px) {
    .risk-matrix {
        font-size: 12px;
    }
}
