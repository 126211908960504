.hazard-register {
    max-width: var(--max-table-width);
}

.hazard-register .sea-row > div:nth-of-type(1) { /* img */
    flex: 0 1 56px;
}
.hazard-register .sea-row > div:nth-of-type(2) { /* Risk */
    flex: 0 1 220px;
}
.hazard-register.hide-vessels .sea-row > div:nth-of-type(3) { /* Vessels */
    display: none;
}
.hazard-register .sea-row > div:nth-of-type(5) { /* Date Added */
    flex: 0 1 120px;
}
.hazard-register .sea-row > div:nth-of-type(6) { /* Risk Rating */
    flex: 0 1 120px;
}
.hazard-register .sea-row.headings > div:nth-of-type(5) {
    text-align: center;
}


@media screen and (max-width: 620px) {
    .hazard-register.page-head {
        flex-direction: column;
    }
    .hazard-register.page-head .actions {
        justify-content: flex-start;
    }
}

@media screen and (max-width: 880px) {
    .hazard-register .sea-row > div:nth-of-type(5) { /* Date Added */ 
        display: none;
    }
}
@media screen and (max-width: 990px) {
    .hazard-register.show-vessels .sea-row > div:nth-of-type(5) { /* Date Added */ 
        display: none;
    }
}
@media screen and (max-width: 670px) {
    .hazard-register .sea-row > div:nth-of-type(2) { /* Risk */
        flex: 1 1;
    }
    .hazard-register .sea-row > div:nth-of-type(4) { /* Risk Notes */
        flex: 1 1;
    }
}
@media screen and (max-width: 780px) {
    .hazard-register.show-vessels .sea-row > div:nth-of-type(2) { /* Risk */
        flex: 1 1;
    }
    .hazard-register.show-vessels .sea-row > div:nth-of-type(4) { /* Risk Notes */
        flex: 1 1;
    }
}

@media screen and (max-width: 1080px) {
    .show-menu.desktop-view .hazard-register .sea-row > div:nth-of-type(5) { /* Date Added */ 
        display: none;
    }
}
@media screen and (max-width: 1190px) {
    .show-menu.desktop-view .hazard-register.show-vessels .sea-row > div:nth-of-type(5) { /* Date Added */ 
        display: none;
    }
}
@media screen and (max-width: 980px) {
    .show-menu.desktop-view .hazard-register .sea-row > div:nth-of-type(2) { /* Risk */
        flex: 1 1;
    }
    .show-menu.desktop-view .hazard-register .sea-row > div:nth-of-type(4) { /* Risk Notes */
        flex: 1 1;
    }
}
@media screen and (max-width: 1090px) {
    .show-menu.desktop-view .hazard-register.show-vessels .sea-row > div:nth-of-type(2) { /* Risk */
        flex: 1 1;
    }
    .show-menu.desktop-view .hazard-register.show-vessels .sea-row > div:nth-of-type(4) { /* Risk Notes */
        flex: 1 1;
    }
}

@media screen and (max-width: 550px) {
    .hazard-register .sea-row.sea-card > div::before {
        width: 64px;
    }
    .hazard-register .sea-row > div:nth-of-type(3)::before {
        content: 'Vessels';
    }
    .hazard-register .sea-row > div:nth-of-type(4)::before {
        content: 'Risk';
    }
    .hazard-register .sea-row > div:nth-of-type(5) {
        display: none; /* Hide Date Added */
    }
    .hazard-register .sea-row > div:nth-of-type(6) {
        padding: 0px;
        position: static;
    }
}
