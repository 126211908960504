.action-log .sea-row-history.headings-history {
    padding-top: 0px;
    margin-top: 0px;
}

.log-day {
    font-size: 13px;
    padding: 18px 18px 0px 18px;
    margin: 6px 0px 0px 0px;
}

.action-log .sea-card.sea-row-history {
    min-height: unset;
    padding: 2px 2px;
    margin-top: 5px;
}
.action-log .sea-row-history > div {
    padding: 3px 8px;
}

.action-log .sea-row-history > div:nth-of-type(1) { /* Date */
    flex: 0 1 80px;
}

.action-log .sea-row-history > div:nth-of-type(2) { /* Icon */
    flex: 0 1 15px;
    padding: 9px 0px 5px 0px;
    opacity: 0.66;
}

.action-log .sea-row-history > div:nth-of-type(3) { /* Action */
    flex: 1 1 0;
    cursor: pointer;
    white-space: nowrap;
    overflow-x: hidden;
    text-overflow: ellipsis;
}

.action-log .sea-row-history > div:nth-of-type(4) { /* Detail */
    flex: 1 1 0;
    cursor: pointer;
    white-space: nowrap;
    overflow-x: hidden;
    text-overflow: ellipsis;
}

.action-log .sea-row-history > div:nth-of-type(5) { /* Vessels */
    flex: 0.6 1 0;
    cursor: pointer;
    white-space: nowrap;
    overflow-x: hidden;
    text-overflow: ellipsis;
}
.action-log.hide-vessels .sea-row-history > div:nth-of-type(5) { /* Vessels */
    display: none;
}

.action-log .sea-row-history > div:nth-of-type(6) { /* Done by */
    flex: 0 1 165px;
    cursor: pointer;
    white-space: nowrap;
    overflow-x: hidden;
    text-overflow: ellipsis;
}

.action-log .sea-row-history.headings-history > div { /* Headings */
    cursor: initial;
    white-space: wrap;
}


@media screen and (max-width: 550px) {
    .action-log .sea-card.sea-row-history {
        padding: 2px 10px 8px;
    }
    .action-log .sea-row-history > div:nth-of-type(1)::after {
        content: attr(data-fullname);
    }
    .action-log .sea-row-history > div:nth-of-type(2) {
        position: absolute;
        top: 20px;
        opacity: 1;
    }
    .action-log .sea-row-history > div:nth-of-type(3) {
        padding-left: 24px;
        font-weight: 600;
    }
    .action-log .sea-row-history > div:nth-of-type(6) {
        display: none;
    }
}
