.sea-select-multi-box {
  width: 100%;
  height: fit-content;
  background-color: #fff;
  min-width: 200px;
  max-width: 500px;
  padding: 0px 12px;
  overflow: auto;
  max-height: calc(80vh - 144px);
}
.sea-select-multi-box .category-heading {
  background-color: var(--text-on-grey);
  margin-top: 12px;
  margin-right: -12px;
  margin-left: -12px;
  text-align: left;
  color: var(--text-on-white);
}
.sea-select-multi-box .option {
  height: 44px;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.sea-select-multi-box .sea-checkbox {
  min-width: 30px;
  width: 30px;
  height: 30px;
  --checkmark-width: 5px;
}
.sea-select-multi-box ion-checkbox::part(mark) {
  transform: scale(0.6) translate(8px, 7px);
}
.sea-select-multi-box .option > div {
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-size: 13px;
  padding-left: 8px;
}
.sea-select-multi-links {
  background-color: #fff;
  text-align: left;
  display: flex;
  justify-content: space-between;
}
.sea-select-multi-links > div {
  display: inline-block;
  padding: 12px 12px 12px 12px;
  font-size: 12px;
  font-weight: 500;
  color: var(--ion-color-primary);
  cursor: pointer;
  /* text-decoration: underline; */
}
.sea-select-multi-links > div.disabled {
  cursor: default;
  color: #ddd;
}
.sea-select-multi-title {
  max-width: 500px;
  background-color: var(--ion-color-light);
  height: 44px;
  min-height: 44px;
  text-align: left;
  padding: 13px 12px 24px 12px;
  font-size: 15px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-weight: 500;
  /* color: var(--ion-color-primary); */
}
.sea-select-multi-subtitle {
  text-align: left;
  padding: 6px 0px 0px 0px;
  font-size: 15px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-weight: 500;
  /* color: var(--ion-color-primary); */
}
.sea-select-multi-actions {
  background-color: var(--ion-color-light);
  padding: 4px 4px 6px 3px;
  text-align: right;
}


.sea-select.multi {
  display: flex;
  padding: 10px 20px 10px 20px;
  cursor: pointer;
}
.sea-select.multi.disabled {
  cursor: default;
  opacity: 0.5;
}
.sea-select.multi .select-text {
  flex: 1 1 0%;
  min-width: 16px;
  padding-right: 6px;
  font-size: inherit;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  align-self: center;
}

.sea-select.multi .select-icon {
  position: relative;
  top: -2px;
  left: -13px;
  transform: scale(1.2);
  opacity: 0.4;
}
.sea-select.multi .select-icon-inner {
  left: 5px;
  top: 50%;
  margin-top: -2px;
  position: absolute;
  width: 0px;
  height: 0px;
  border-top: 5px solid;
  border-right: 5px solid transparent;
  border-left: 5px solid transparent;
  color: currentcolor;
  pointer-events: none;
}


.sea-input.sea-multi-option {
  height: 30px;
  background-color: #fff;
  font-size: 12px;
  line-height: 30px;
  text-align: center;
  display: inline-block;
  border-radius: 15px;
  padding: 0px 20px;
  margin: 4px 10px 6px 0px;
  cursor: pointer;
  min-width: 100px;
  transition: none;
  /* overflow: hidden; */
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 100%;
}
.sea-input.sea-multi-option.selected {
  border-color: var(--ion-color-primary);
  background-color: var(--ion-color-primary);
  color: #fff !important;
}
.sea-input.sea-multi-option.warn {
  /* border-color: var(--ion-color-danger); */
  background-color: #FCF3F3;
}
.label-error > div {
  color: var(--ion-color-danger);
}

@media screen and (max-width: 550px) {
  .sea-input.sea-multi-option {
    padding: 0px 10px;
    margin: 2px 6px 4px 0px;
  }
}
