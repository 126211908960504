.sea-input-error {
  font-weight: 500;
  color: var(--ion-color-danger);
  font-size: 12px;
  padding: 2px 4px 0px 0px;
  text-align: right;
  /* line-height: 1em; */
  max-height: 0px;
  transition: max-height 0.3s ease-in;
  overflow: hidden;
}
.sea-input-error.left {
  text-align: left;
}
.sea-input-error.show-error {
  max-height: 175px;
  overflow-y: auto;
}
