.forms-and-files .sea-row-history > div:nth-of-type(1) { /* Image/File */
    flex: 0 1 56px;
}
.forms-and-files .sea-row-history > div:nth-of-type(2) { /* Title */
    flex: 1.5 1;
}
.forms-and-files .sea-row-history > div:nth-of-type(3) { /* Vessels */
    flex: 1 1;
}
.forms-and-files .sea-row-history > div:nth-of-type(4) { /* Added By */
    flex: 0 1 150px;
}
.forms-and-files .sea-row-history > div:nth-of-type(5) { /* Date Added */
    flex: 0 1 100px;
}

@media screen and (max-width: 660px) {
    .forms-and-files .sea-row-history > div:nth-of-type(3) { /* Vessels */
        display: none;
    }
}

@media screen and (max-width: 550px) {
    .forms-and-files .sea-row-history > div:nth-of-type(2) {
        font-weight: 600;
    }
    .forms-and-files .sea-row-history > div:nth-of-type(4)::before {
        content: 'Added By'; /* Added by */
    }
    .forms-and-files .sea-row-history > div:nth-of-type(5)::before {
        content: 'Date Added'; /* Date Added */
    }
}
