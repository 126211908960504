


.forecast-table {
    --sub-color: var(--label-text);
    font-size: 13px;
}

.forecast-table td {
    min-width: 64px;
    width: 64px;
    max-width: 64px;
    text-align: center;
    padding-top: 4px;
    padding-bottom: 4px;
    transition: all 0.2s;
}

.forecast-table thead td {
    white-space: nowrap;
    overflow-x: visible;
    text-align: left;
    padding-left: 8px;
    padding-top: 4px !important;
    padding-bottom: 6px;
    font-weight: 600;
    cursor: default;
}

.forecast-table tbody td {
    line-height: 1.55em;
    border-style: solid;
    border-width: 2px 0px 0px 0px;
    border-color: #fff;
    color: #fff;
}
.forecast-table.interact-with-map tbody td {
    cursor: pointer;
}

.forecast-table td:first-of-type {
    vertical-align: top;
    text-align: left;
    padding-right: 12px;
    padding-top: 11px !important;
    width: unset;
    min-width: unset;
    width: unset;
    max-width: unset;
    background-color: #fff !important;
    color: var(--text-on-white) !important;
    cursor: default;
}

.forecast-table tr.times td {
    padding-top: 8px;
    padding-bottom: 6px;
    line-height: 1em;
    color: var(--ion-text-color);
}

.forecast-table tr.times td.selected-time {
    background-color: var(--input-border-color);
    border-color: var(--ion-color-primary);
    cursor: default;
}

.forecast-table tr.times td span {
    font-size: 10px;
    color: var(--sub-color);
}

.forecast-table tr.temp td {
    padding-top: 8px;
    font-weight: 500;
}

.forecast-table tr.wind td {
    position: relative;
    padding-top: 26px;
    padding-bottom: 6px;
}
.forecast-table tr.wind td span {
    font-size: 11px;
    font-weight: 500;
}
.forecast-table tr.wind td div {
    position: absolute;
    width: 100%;
    top: 2px;
    padding-top: 5px;
    margin-bottom: 8px;
}

.forecast-table tr.waves td {
    position: relative;
    padding-top: 26px;
    padding-bottom: 4px;
    background-color: #216595; /*var(--ion-color-primary);*/
}
.forecast-table tr.waves td div {
    position: absolute;
    width: 100%;
    top: 3px;
    padding-top: 6px;
    margin-bottom: 8px;
}

.forecast-table tr.waves td span {
    font-size: 10px;
}

