.crew-certificates {
    max-width: var(--max-table-width);
}

.crew-certificates .sea-row > div:nth-of-type(1) { /* img */
    flex: 0 1 56px;
}
.crew-certificates .sea-row > div:nth-of-type(3) { /* Held by (all=Issued by) */
    flex: 0 1 150px;
}
.crew-certificates .sea-row > div:nth-of-type(4) { /* Issue by */
    flex: 0 1 150px;
}
.crew-certificates.all .sea-row > div:nth-of-type(4) { /* Issue Date (all mode) */
    flex: 0 1 85px;
}
.crew-certificates .sea-row > div:nth-of-type(5) { /* Expiry */
    flex: 0 1 85px;
}
.crew-certificates  .sea-row > div:nth-of-type(6) { /* Status */
    flex: 0 1 150px;
}
.crew-certificates  .sea-row.headings > div:nth-of-type(6) {
    text-align: center;
}
.crew-certificates  .sea-row > div:nth-of-type(7) { /* Email reminder */
    flex: 0 1 95px;
}
.crew-certificates  .sea-card.sea-row > div:nth-of-type(7) { /* Email reminder */
    text-align: center;
}

@media screen and (max-width: 985px) {
    .crew-certificates.page-head {
        flex-direction: column;
    }
    .crew-certificates.page-head .actions {
        justify-content: flex-start;
    }
}

@media screen and (max-width: 980px) {
    .crew-certificates .sea-row > div:nth-of-type(7) { /* Reminder */
        display: none;
    }
}
@media screen and (max-width: 880px) {
    .crew-certificates .sea-row > div:nth-of-type(4) { /* Issued By */
        display: none;
    }
}
@media screen and (max-width: 640px) {
    .crew-certificates .sea-row > div:nth-of-type(5) { /* Expiry */
        display: none;
    }
}

@media screen and (max-width: 1180px) {
    .show-menu.desktop-view .crew-certificates .sea-row > div:nth-of-type(7) { /* Reminder */
        display: none;
    }
}
@media screen and (max-width: 1080px) {
    .show-menu.desktop-view .crew-certificates .sea-row > div:nth-of-type(4) { /* Issued By */
        display: none;
    }
}
@media screen and (max-width: 900px) {
    .show-menu.desktop-view .crew-certificates .sea-row > div:nth-of-type(5) { /* Expiry */
        display: none;
    }
}

@media screen and (max-width: 550px) {
    .crew-certificates .sea-row.sea-card > div::before {
        width: 65px;
    }
    .crew-certificates.all .sea-row.sea-card > div::before {
        width: 70px;
    }
    .crew-certificates .sea-row > div:nth-of-type(3)::before {
        content: 'Held By';
    }
    .crew-certificates.all .sea-row > div:nth-of-type(3)::before {
        content: 'Issued By';
    }
    .crew-certificates .sea-row > div:nth-of-type(4) {
        display: none; /* Hide Issued By */
    }
    .crew-certificates .sea-row > div:nth-of-type(5) {
        display: block; /* Expiry */
    }
    .crew-certificates .sea-row > div:nth-of-type(5)::before {
        content: 'Expiry';
    }
    .crew-certificates .sea-row > div:nth-of-type(6) {
        padding: 0px;
    }
    .crew-certificates .sea-row > div:nth-of-type(7) {
        display: none; /* Hide Reminder */
    }
}
