.sea-user-permissions .row {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: flex-start;
}

.sea-user-permissions .sea-label {
    height: 23px;
    line-height: 100%;
    overflow: hidden;
    height: unset;
}

.sea-user-permissions .help-icon {
    align-items: center;
    cursor: pointer;
}

.help-icon .sea-help {
    top: 0px;
    left: 0px;
    padding: 4px 0px 0px 4px;
}

.sea-user-permissions .row > div {
    flex: 1 1;
    overflow-x: hidden;
}

.sea-user-permissions .row > .sea-label:not(:first-child) {
    text-transform: capitalize;
    font-size: 12px;
    text-align: center;
}

.sea-user-permissions .row > div:not(:first-child) {
    padding-top: 4px;
    text-align: center;
    flex: 0 1 80px;
}

.sea-user-permissions .radio-group {
    position: relative;
}

/* .sea-user-permissions .radio-group.has-error { */
/*     background-color: rgba(255, 229, 229, 0.3); /* More subtle red hue */
/*     margin-left: -12px; */
/*     padding-left: 12px; */
/* } */

.sea-user-permissions .radio-group ion-radio {
    /* padding: 10px 0; */
    padding: 20px 30px;
}

.sea-user-permissions .radio-group > div:first-child {
    color: var(--bg-header);
    letter-spacing: 0.02em;
    font-weight: 500;
    align-self: center;
}

.sea-user-permissions ion-radio::part(container) {
    background-color:rgba(169, 168, 175, 0.2);
    border: 0.5px solid #808FA3;
    border-radius: 11px;
    padding: 3px;
    width: 20px;
    height: 20px;
    position: absolute;
    left: 20px;
    bottom: 10px;
}


.sea-user-permissions .warning {
    position: absolute;
    width: 350px;
    right: 0px;
    top: 37px;
    font-weight: 500;
    color: var(--ion-color-danger);
    font-size: 12px;
}

.sea-user-permissions .warning-space {
    padding-bottom: 30px;
}

.sea-user-permissions .drop-down {
    display: none;
}

.sea-user-permissions-radio.has-error {
    --ion-color-base: var(--ion-color-danger, #f1453d) !important;
    --ion-color-base-rgb: 241, 69, 61 !important;
    --ion-color-contrast: #ffffff !important;
    --ion-color-contrast-rgb: 255, 255, 255 !important;
    --ion-color-shade: #d33936 !important;
    --ion-color-tint: #f5634a !important;
}

.sea-user-permissions-radio.has-error::part(container) {
    background-color: #ffffff;
    border-color: var(--ion-color-danger, #f1453d) !important;;
}


.sea-radio-hidden {
    visibility: hidden;
}

.sea-option-hidden {
    display: none;
}

@media screen and (max-width: 645px) {
    .sea-user-permissions .row > div {
        display: none;
    }
    .sea-user-permissions .row > div:first-of-type {
        display: block;
    }
    .sea-user-permissions .drop-down {
        display: block !important;
        flex: 1.1 1 !important;
        padding-left: 4px;
    }
    .sea-user-permissions .sea-input {
        background-color: #fff;
    }
    .sea-user-permissions .radio-group {
        padding-top: 4px;
    }
}
