.incidents.by-notified .sea-row-history > div:nth-of-type(2) {
    flex: 1 1 0;
}
.incidents.by-notified .sea-row-history > div:nth-of-type(3) {
    flex: 1.5 1 0;
}

@media screen and (max-width: 550px) {
    .incidents.by-notified .sea-row-history > div:nth-of-type(2)::before {
        content: 'Notified';
    }
}
