.sea-no-data {
  margin-top: 30px;
  font-size: 15px;
  font-style: italic;
}
.sea-no-data > div {
  align-self: center;
  opacity: 0.8;
}
.sea-no-data ion-icon {
  margin-right: 4px;
}
.sea-modal .sea-no-data {
  color: #888;
}
.sea-modal .sea-no-data > div {
  opacity: 0.6;
}