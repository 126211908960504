.incidents.by-category .sea-row-history > div:nth-of-type(2) { /* Category */
    flex: 1 1 0;
}

@media screen and (max-width: 550px) {
    .incidents.by-category .sea-row-history > div::before {
        width: 80px;
    }
    .incidents.by-category .sea-row-history > div:nth-of-type(2)::before {
        content: 'Category';
    }
}
