.sea-footer {
  position: absolute;
  bottom: 0px;
  left: 0px;
  height: var(--footer-height);
  color: var(--text-on-grey);
  padding: 0px 24px 12px 24px;
  align-content: flex-end;
  font-size: 10px;
  width: 100%;
  text-align: right;
  display: flex;
  flex-wrap: wrap;
}
.sea-footer a {
  color: var(--text-on-grey);
}
.sea-footer button {
  color: var(--text-on-grey) !important;
  padding: 10px 8px !important;
  letter-spacing: 0.01px;
}
.sea-footer > div:first-of-type {
  flex: 1 1 0;
  text-align: left;
}
.sea-footer > div > div {
  padding: 8px;
  white-space: nowrap;
}


@media screen and (max-width: 850px) {
  .sea-footer {
    padding: 0px 12px 8px 12px;
  }
}
@media screen and (max-width: 460px) {
  .sea-footer > div > div {
    padding: 0px 0px 8px 0px;
  }
  .sea-footer button {
    padding: 0px 8px 8px !important;
  }
}
