.sea-status.risk-rating {
    color: var(--text-on-white);
}

.risk-register {
    max-width: var(--max-table-width);
}

.risk-register .sea-row > div:nth-of-type(1) { /* img */
    flex: 0 1 56px;
}
.risk-register .sea-row > div:nth-of-type(2) { /* Hazard */
    flex: 1 1 0;
}
.risk-register .sea-row > div:nth-of-type(3) { /* Risks */
    flex: 1 1 0;
}
.risk-register .sea-row > div:nth-of-type(4) { /* Pre risk rating */
    flex: 0 1 120px;
}
.risk-register .sea-row > div:nth-of-type(5) { /* Controls */
    flex: 1 1 0;
}
.risk-register .sea-row > div:nth-of-type(6) { /* Post risk rating */
    flex: 0 1 120px;
}
.risk-register.hide-vessels .sea-row > div:nth-of-type(7) { /* Vessels */
    display: none;
}
.risk-register .sea-row > div:nth-of-type(8) { /* Review Date */
    max-width: 180px !important;
    text-align: center;
}
.review-date { /* Review Date */
    color: var(--ion-color-success);
    font-weight: 500;
    text-align: center;
    justify-content: center;
    display: inline-flex !important;
}
.review-date.fail {
    color: var(--ion-color-danger);
    font-weight: 600;
}
.review-date.warn {
    color: var(--ion-color-warning);
}
.risk-register .sea-row.headings > div:nth-of-type(8) {
    color: #fff;
}
.risk-register .sea-status {
    max-width: 120px;
    width: 100%;
}





@media screen and (max-width: 1080px) {
    .risk-register .sea-row > div:nth-of-type(7) { /* Vessels */ 
        display: none;
    }
}
@media screen and (max-width: 960px) {
    .risk-register .sea-row > div:nth-of-type(8) { /* Review Date */
        display: none;
    }
}
@media screen and (max-width: 725px) {
    .risk-register .sea-row > div:nth-of-type(6) { /* Post risk rating */
        display: none;
    }
}
@media screen and (max-width: 600px) {
    .risk-register .sea-row > div:nth-of-type(5) { /* Controls */
        display: none;
    }
}


@media screen and (max-width: 1220px) {
    .show-menu.desktop-view .risk-register .sea-row > div:nth-of-type(7) { /* Vessels */ 
        display: none;
    }
}
@media screen and (max-width: 1120px) {
    .show-menu.desktop-view .risk-register .sea-row > div:nth-of-type(8) { /* Review Date */
        display: none;
    }
}
@media screen and (max-width: 1000px) {
    .show-menu.desktop-view .risk-register .sea-row > div:nth-of-type(6) { /* Post risk rating */
        display: none;
    }
}


@media screen and (max-width: 550px) {
    .risk-register .sea-row.sea-card > div::before {
        width: 75px;
    }
    .risk-register .sea-status.risk-rating {
        text-align: center;
        font-weight: 600 !important;
    }

    .risk-register .sea-row > div:nth-of-type(3)::before {
        content: 'Risks';
    }
    .risk-register .sea-row > div:nth-of-type(5) {
        display: block;
    }
    .risk-register .sea-row > div:nth-of-type(5)::before {
        content: 'Controls';
    }
}
