.sea-input {
  background-color: var(--ion-color-light);
  border-width: 1px;
  border-style: solid;
  border-color: var(--input-border-color);
  border-radius: var(--input-border-radius);
  height: var(--input-height);
  --padding-start: 18px;
  color: var(--bg-header) !important;
  font-family: var(--sea-font-family);
  font-size: 12px;
  font-weight: 500;
  transition: border-color 0.1s, background-color 0.1s;
  text-align: left;
}
.sea-input.disabled {
  opacity: 0.4;
}
.sea-input.has-focus {
  border-color: var(--ion-color-base);
}
.sea-input.has-error {
  /* border-color: var(--ion-color-base); */
  border-color: var(--ion-color-danger);
  background-color: #fff5f5;
}
.sea-input.has-prefix input {
  padding-inline-start: calc(var(--padding-start) + 2px);
}
.sea-input span.prefix {
  position: relative;
  left: var(--padding-start);
  opacity: 0.8;
}
.sea-input.has-suffix {
  width: calc(100% - var(--input-height) / 2);
  border-right: none;
  border-radius: 5px 0px 0px 5px;
  /* margin-right: -12px; */
}
.sea-input-wrapper {
  display: flex;
  width: 100%;
  flex-direction: row;
}
.sea-input-wrapper .suffix {
  background-color: var(--ion-color-light);
  border-width: 1px;
  border-style: solid;
  border-color: var(--input-border-color);
  border-radius: 0px 5px 5px 0px;
  border-left: none;
  margin-left: -1px;
  transition: border-color 0.1s, background-color 0.1s;
  height: var(--input-height);
  width: calc(var(--input-height) / 2 + 5px);
  align-items: center;
  z-index: 100;
}
.sea-input-wrapper .suffix.has-focus {
  border-color: var(--ion-color-primary, #3880ff);
}
.sea-input-wrapper .suffix.disabled {
  opacity: 0.4;
}
.sea-input-wrapper .suffix.has-error {
  border-color: var(--ion-color-danger);
  background-color: #fff5f5;
}
.element.view-only .sea-input {
  border-style: none;
  background-color: var(--bg-view-only);
}

.element.view-only .sea-input.light {
  background-color: var(--ion-color-light);
  color: rgba(55,57,70,0.7) !important;
}

/* Stop annoying auto complete colouring */
.sea-input > input:-webkit-autofill,
.sea-input > input:-webkit-autofill:hover, 
.sea-input > input:-webkit-autofill:focus, 
.sea-input > input:-webkit-autofill:active
{
  -webkit-text-fill-color: var(--text-on-white) !important;
  -webkit-box-shadow: 0 0 0 30px var(--ion-color-light) inset !important;
  transition: background-color 5000s ease-in-out 0s;
}
.sea-input.has-error > input:-webkit-autofill,
.sea-input.has-error > input:-webkit-autofill:hover, 
.sea-input.has-error > input:-webkit-autofill:focus, 
.sea-input.has-error > input:-webkit-autofill:active
{
  -webkit-box-shadow: 0 0 0 30px #fff5f5 inset !important;
}
