.cbl-bg.please-wait.container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.cbl-bg.please-wait.container > div {
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

