.vessel-card {
    padding: 0px;
    border-radius: 10px;
    margin-bottom: 15px;
    box-shadow: 0px 30px 40px rgba(0, 0, 0, 0.15);
    width: 900px;
    max-width: 100%;
    overflow: hidden;
    cursor: pointer;
    position: relative;
}
.basic-details {
    display: flex;
    flex-direction: row;
}
.vessel-card .vessel-info h2 {
    margin: 4px 0px 0px 0px;
}
.vessel-card .vessel-image-div {
    padding: 0px;
    width: 240px;
    height: 180px;
    border-radius: 0px;
    background-color: #e9eaed;
}
.vessel-card .sea-file-image.medium.vessel {
    width: 100%;
    height: 100%;
    border-radius: 0px;
}
.vessel-card .vessel-info {
    flex-grow: 1;
    padding: 10px 16px 12px 16px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.vessel-card .overdue-tasks {
    max-width: 330px;
    display: flex;
    flex-wrap: wrap;
    /* justify-content: space-between; */
}
.vessel-card .mini-card {
    background-color: #fff;
    border-radius: 8px;
    border: 1px solid var(--text-on-grey);
    height: 64px;
    text-align: center;
    align-self: center;
    font-size: 12px;
    font-weight: 500;
    width: 100px;
    margin-right: 10px;
}
.vessel-card .overdue {
    text-transform: uppercase;
    padding: 5px 0px 8px 0px;
    opacity: 0.9;
    font-weight: 500;
    letter-spacing: 1px;
}
.vessel-card .num {
    color: var(--ion-color-primary);
    font-size: 24px;
    font-weight: 700;
    padding: 8px 0px 2px;
}

@media screen and (min-width: 851px) and (max-width: 950px) {
    .show-menu .vessel-card .mini-card {
        width: 74px;
        margin-right: 4px;
        font-size: 85%;
    }
    .show-menu .vessel-card .vessel-info {
        padding-right: 6px;
    }
}

@media screen and (max-width: 650px) {
    .basic-details {
        flex-direction: column;
    }
    .vessel-card .vessel-image-div {
        width: 100%;
        height: 190px;
    }
    .vessel-card .vessel-info {
        padding: 0px 10px 4px 16px;
    }
    .vessel-card .mini-card {
        margin-right: 6px;
        margin-bottom: 8px;
    }
    .vessel-card .overdue {
        padding: 5px 0px 5px 0px;
        opacity: 0.75;
    }
}

@media screen and (max-width: 400px) {
    .vessel-card .mini-card {
        width: 87px;
    }
}
@media screen and (max-width: 353px) {
    .vessel-card {
        font-size: 90%;
    }
    .vessel-card .mini-card {
        width: 75px;
        max-width: 75px;
        margin-right: 4px;
        white-space: nowrap;
        overflow-x: hidden;
        text-overflow: ellipsis;
        font-size: 93%;
    }
}








/*
.main {
    flex-grow: 1;
    padding: 0px 16px 0px 30px;
}
.main h2 {
    margin: -3px 0px 0px 0px;
}
.action {
    text-align: right;
    align-self: flex-start;
}
.action button {
    margin: 4px 8px 0px;
}
.overdue {
    text-transform: uppercase;
    padding: 5px 0px 11px 0px;
    font-weight: 500;
    letter-spacing: 1px;
}
.mini-card {
    background-color: #fff;
    border-radius: 8px;
    border: 1px solid var(--text-on-grey);
    height: 64px;
    text-align: center;
    align-self: center;
    font-size: 12px;
    font-weight: 500;
}
.num {
    color: var(--ion-color-primary);
    font-size: 24px;
    font-weight: 700;
    padding: 8px 0px 2px;
}


.more-details {
    font-size: 13px;
    font-weight: 500;
    max-height: 0px;
    transition: max-height 0.3s;
}
.more-grid {
    --grid-gutter: 4px;
    --grid-row-spacing: 0px;
}
.more-details.shown {
    max-height: 200px;
}
.more-details h3 {
    color: var(--ion-color-primary);
    font-size: 10px;
    text-transform: uppercase;
    margin-top: 16px;
    margin-bottom: 5px;
}
.edit-vessel {
    text-align: right;
    position: relative;
    margin-top: 14px;
}
*/