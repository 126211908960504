.sea-voyages {
    max-width: var(--max-table-width);
}

/* Column settings */
.sea-voyages .sea-row > div:nth-of-type(1) { /* Name / Master */
    flex: 1.6 1 0;
}

.sea-voyages .sea-row > div:nth-of-type(2) { /* departure time */
    flex: 0 1 105px;
}

.sea-voyages .sea-row > div:nth-of-type(4) { /* arrival time */
    flex: 0 1 105px;
}

@media screen and (max-width: 750px) {
    .logbook.page-head {
        flex-direction: column;
    }
    .logbook.page-head .actions {
        justify-content: flex-start;
    }
}

@media screen and (max-width: 550px) {
    .sea-voyages .sea-row.sea-card > div::before {
        width: 85px;
    }
    .sea-voyages .sea-row > div:nth-of-type(2)::before {
        content: 'Departure';
    }
    .sea-voyages .sea-row > div:nth-of-type(3)::before {
        content: '';
    }
    .sea-voyages .sea-row > div:nth-of-type(4)::before {
        content: 'Arrival';
    }
    .sea-voyages .sea-row > div:nth-of-type(5)::before {
        content: '';
    }
}
