
.input-action-icon {
    position: absolute;
    width: 44px;
    height: 44px;
    top: 3px;
    right: 3px;
    border-radius: 60px;
    text-align: center;
    background-color: var(--ion-color-light);
    padding-top: 8px;
    padding-bottom: 38px;
    color: #a8aab0;
    cursor: pointer;
    opacity: 0.5;
    z-index: 10;
}

.input-action-icon.pushy:active {
    opacity: 1;
    transform: scale(0.8);
}

.sea-input-container {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: row;
    flex: 1;
    height: var(--input-height);
}

.time-container {
    position: absolute;
    display: flex;
    border-radius: 5px;
    border-width: 1px;
    background-color: none;
    margin: 0 10px;
    height: var(--input-height);
    width: calc(100% - 10px);
}

.time-container.has-date {
    left: 88px;
}

.time-container .native-input {
    padding: 0 0px;
}


.time-container .sea-input {
    border: none;
    margin-top: 0px;
    background-color: transparent;
    flex: 1;
    justify-content: center;
    padding: 0 0px !important;
    width: -moz-fit-content;
    width: fit-content;
}

.time-container .hours {
    text-align: center;
    width: 20px;
    max-width: 20px;
}

.time-container .minutes {
    text-align: center;
    width: 20px;
    max-width: 20px;
}

.time-container input {
   height: 90%;
   margin-top: -1px;
}

.time-container .separator {
    padding: 0 1px;
    margin-top: -1px;
    align-self: center;
    z-index: 100;
}

.calendar-icon-overlay {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    width: 40px;
    cursor: pointer;
    z-index: 100;
}

@supports (-moz-appearance:none) {
    .time-container.has-date {
        left: 98px;
    }
    .time-container input {
        margin-top: 0px;
     }
}