.vessel-documents {
    max-width: var(--max-table-width);
}

.vessel-documents .sea-row > div:nth-of-type(1) { /* img */
    flex: 0 1 56px;
}
.vessel-documents .sea-row > div:nth-of-type(3) { /* expiry */
    flex: 0 1 85px;
}
.vessel-documents .sea-row > div:nth-of-type(4) { /* interval */
    flex: 0 1 100px;
}
.vessel-documents .sea-row > div:nth-of-type(5) { /* status */
    flex: 0 1 150px;
}
.vessel-documents .sea-row.headings > div:nth-of-type(5) {
    text-align: center;
}
.vessel-documents  .sea-row > div:nth-of-type(6) { /* Email Reminder */
    flex: 0 1 95px;
}
.vessel-documents  .sea-card.sea-row > div:nth-of-type(6) { /* Email Reminder */
    text-align: center;
}

@media screen and (max-width: 850px) {
    .vessel-documents.page-head {
        flex-direction: column;
    }
    .vessel-documents.page-head .actions {
        justify-content: flex-start;
    }
}

@media screen and (max-width: 760px) {
    .vessel-documents .sea-row > div:nth-of-type(6) { /* Reminder */ 
        display: none;
    }
}
@media screen and (max-width: 660px) {
    .vessel-documents .sea-row > div:nth-of-type(4) { /* Interval */ 
        display: none;
    }
}

@media screen and (max-width: 1030px) {
    .show-menu.desktop-view .vessel-documents .sea-row > div:nth-of-type(6) { /* Reminder */ 
        display: none;
    }
}
@media screen and (max-width: 895px) {
    .show-menu.desktop-view .vessel-documents .sea-row > div:nth-of-type(4) { /* Interval */ 
        display: none;
    }
}

@media screen and (max-width: 550px) {
    .has-status.has-thumbs .sea-card.sea-row.non-expiring > div:nth-of-type(2) {
        padding-right: 0px;
    }
    .vessel-documents .sea-row.sea-card > div::before {
        width: 57px;
    }
    .vessel-documents .sea-row > div:nth-of-type(3)::before {
        content: 'Expiry';
    }
    .vessel-documents .sea-row > div:nth-of-type(4) {
        display: none; /* Hide Interval */
    }
    .vessel-documents .sea-row > div:nth-of-type(5) {
        padding: 0px;
    }
    .vessel-documents .sea-row > div:nth-of-type(6) {
        display: none; /* Hide Reminder */
    }
}
