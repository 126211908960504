.input-list-header,
.input-list-row {
    display: flex;
    justify-content: space-between;
}

.input-list-column {
    flex: 1;
    display: flex;
    flex-direction: row;
}

.input-list-column.label.checkbox {
    flex: 0;
    display: flex;
    flex-direction: row;
    margin-right: 40px;
    align-items: center;
    justify-content: center;
    position: relative;
}

/* .input-list-column.label.checkbox::after {
    content: '';
    position: absolute;
    right: -44px;
    width: 44px;
    height: 100%;
} */

.input-list-column.custom-data {
    padding-left: 12px;
    flex: 0;
    display: flex;
}
.input-list-column.multiselect {
    max-width: 50%;
    flex: 1;
}

.input-list-column.custom-data.checkbox {
    flex: 0;
}

.input-list-row {
    margin-bottom: 10px;
    display: flex;
}

.input-list-row > div {
    align-self: center;
}

.input-list-row .trash {
    width: 44px;
    height: 44px;
    color: var(--ion-color-primary);
    cursor: pointer;
    text-align: center;
    padding-top: 12px;
    /* transform: scale(1.2); */
}

.sea-add-new-button {
    margin-top: -2px;
    display: flex;
}

.sea-add-new-button .text {
    color: var(--ion-color-primary);
    padding: 8px 8px 9px 8px;
    align-self: center;
    cursor: pointer;
    font-size: 13px;
    font-weight: 500;
}

.sea-add-new-button .text.disabled {
    color: var(--ion-color-primary-shade);
    cursor: default;
    opacity: 0.4;
}

.input-link {
    text-align: right;
}

.input-link > div {
    display: inline-block;
    color: var(--ion-color-primary);
    letter-spacing: 0.2px;
    padding: 4px 12px 20px 12px;
    margin: -2px -12px -20px -12px;
    cursor: pointer;
}

.input-list-row .sea-checkbox-container {
    width: 52px;
    justify-content: center;
    position: relative;
}

.input-list-row .column-header {
    position: absolute;
    top: -23px;
}

.sea-select-container.beside-input.multiselect,
.sea-select-container.beside-input.checkbox {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    flex: 1;
    width: 100%;
    max-width: calc(100% - 44px);
}

@media (max-width: 704px) {
    .sea-select-container.beside-input.multiselect,
    .sea-select-container.beside-input.checkbox {
        display: flex;
        flex-direction: column;
        width: calc(100% - 52.8px);
    }
    .input-list-column {
        flex: 1;
    }
    .input-list-column.multiselect {
        max-width: 60%;
    }
    .input-list-column.custom-data {
        display: inline-flex;
        max-width: 40%;
    }
    .input-list-column .sea-label {
        height: auto;
        min-height: 21px;
    }
}
