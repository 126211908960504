.debug-files select {
    padding: 4px;
    color: #fff;
}
.debug-files select option {
    padding: 4px;
}
.debug-files .sea-row > div {
    font-size: 12px;
}
.debug-files .sea-row > div:nth-of-type(1) { /* File Id */
    flex: 0 1 240px;
}
.debug-files .sea-row > div:nth-of-type(2) { /* Collection */
    flex: 0 1 240px;
    /* flex: 1 1; */
}
.debug-files .sea-row > div:nth-of-type(3) { /* State */
    flex: 0 1 60px;
    text-align: center;
}
.debug-files .sea-row > div:nth-of-type(4) { /* When */
    flex: 0 1 90px;
    text-align: right;
}
.debug-files .sea-row > div:nth-of-type(5) { /* Ext */
    flex: 0 1 60px;
    text-align: right;
    font-family: monospace;
}
.debug-files .sea-row > div:nth-of-type(6) { /* Original File Size */
    flex: 0 1 150px;
    text-align: right;
    font-family: monospace;
}
.debug-files .sea-row > div:nth-of-type(7) { /* Optimised File Size */
    flex: 0 1 150px;
    text-align: right;
    font-family: monospace;
}
.debug-files .sea-row > div:nth-of-type(8) { /* Thumbnail File Size */
    flex: 0 1 150px;
    text-align: right;
    font-family: monospace;
}
.debug-files .sea-row > div:nth-of-type(9) { /* Total Bytes */
    flex: 0 1 180px;
    text-align: right;
    font-family: monospace;
}
.debug-files .sea-row > div:nth-of-type(10) { /* Fails */
    flex: 0 1 60px;
    text-align: right;
    font-family: monospace;
}
