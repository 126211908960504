.perf-table tr:first-of-type td {
    padding: 2px 12px 2px 0px;
    font-weight: bold;
}
.perf-table td {
    padding: 2px 12px 2px 0px;
    text-align: right;
}
.perf-table td:first-of-type {
    text-align: left;
}
.perf-table {
    font-variant-numeric: tabular-nums;
}
