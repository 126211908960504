.trip-stops.form-grid {
    margin: 8px 0px -16px 0px;
}

.trip-stops.sea-plus-button {
    margin: 4px 0px 0px 4px;
    position: relative;
    z-index: 10;
}

/* .trip-stop-block {
    padding-left: 20px;
    border-style: solid;
    border-color: var(--input-border-color);
    border-width: 0px 0px 0px 4px;
} */

.trip-stop-block {
    width: 4px;
    background-color: var(--input-border-color);
    height: calc(100% - 10px);
    margin-right: 14px;
    margin-top: 6px;
}
